<template lang="">
         <!-- categories  -->
        <div class="tab-pane fade show active categories2" id="categories" role="tabpanel" aria-labelledby="categories-tab">
                <div class="row">
                    <div class="col-md-12 bg-dark mx-1 text-white">
                        <div class="row quote-price"> 
                            <div class="col-lg-2 col-md-2 text-center">
                                <h5>Item Cost: &#163;{{quote.calc_items_cost}} </h5>
                            </div> 
                            <div class="col-lg-2 col-md-2 text-center">
                                <h5>Overhead: &#163;{{quote.calc_overhead}} </h5>
                            </div> 
                            <div class="col-lg-2 col-md-2 text-center">
                                <h5>Profit: &#163;{{quote.calc_profit}} </h5>
                            </div> 
                            <div class="col-lg-2 col-md-2 text-center">
                                <h5> Total Cost: &#163;{{quote.tamount}} </h5>
                            </div>
                            <div class="col-lg-2 col-md-2 text-center">
                                <h5> Gross Cost: &pound;{{quote.gross_amount}} </h5>
                            </div>
                        </div> 
                       </div>
                    <div class="col-md-2">
                      <div class="sub_category">
                      <ul class="nav nav-pills flex-row d-block pl-0" id="myTab" role="tablist">
                            <li class="nav-item" v-for="category in categories" @click="setSelectedCategory(category.id)">
                                <a class="nav-link" id="`cate${category.id}-tab`" data-toggle="tab" href="`#cate${category.id}`" role="tab" aria-controls="cate1" aria-selected="true" :class="{active:selectedCategory.id===category.id?true:false}">{{ category.name }}
                                     <h5 class="badge badge-dark">{{ IsCategoryEnabled (category.id)}}<span>   {{GetCategoryCost(category.id)}}</span></h5>
                                </a>
                                
                            </li>
                        </ul>
                    </div> 
                     </div> 
                    <!-- /.col-md-2 -->
                    <div class="col-md-2">
                        <div class="tab-content" id="myTabContent">
                          <transition name="slide">
                            <div class="tab-pane fade show active" id="`cate${category.id}`" role="tabpanel" aria-labelledby="`cate${category.id}-tab`" v-show="showItemList">
                            <div class="sub_category sub_category1">
                                <div class="">
                                    <h6>{{selectedCategory.name}}</h6>
                                    <b-form-checkbox
                                    id="checkbox-selected-category"
                                    v-model="selectedCategory.enabled"
                                    name="checkbox-selected-category"
                                    :value="1" 
                                    :unchecked-value="0" 
                                    @change.native="quoteCategoryProcess()">Enabled
                                </b-form-checkbox>
                                </div>
                                <ul class="pl-0 subcategory" v-if="selectedCategory.enabled===1">
                                    <li v-for="item in categoryItems" :class="{active:selectedItem && selectedItem.id===item.id?true:false}" @click="setSelectedItem(item.id)">
                                        <h5 class="name">{{ `${item.name}` }}</h5>
                                        <h5 class="badge badge-dark">{{IsCategoryItemEnabled(selectedCategory.id,item.id)}}<span> {{GetCategoryItemCost(selectedCategory.id,item.id)}}</span></h5>
                                    </li>
                                   
                                </ul>
                            </div>
                            </div>
                            </transition>
                        </div>
                    </div> <!-- /.col-md-2 -->
                    
                   <div class="col-md-1"></div>
                   <transition name="slide">
                    <div class="col-md-7 category_configuration" v-if="selectedItem.id!==undefined">
                        <v-row>
                            <div class="col-12 item_configure">
                                <h5>{{selectedItem.name}}</h5>
                            </div>
                        </v-row>
                        <v-row>
                            <div class="col-md-3">
                                <b-form-checkbox
                                    v-model="selectedItem.enabled"
                                    class="mb-3"
                                    :value="1" 
                                    :unchecked-value="0"
                                    @change.native="quoteItemProcess()"  
                                    >
                                    Enabled
                                    </b-form-checkbox>
                            </div>
                            <div class="col-md-3">
                                <b-form-checkbox
                                    v-model="selectedItem.excluded"                                
                                    class="mb-3"
                                    >
                                    Excluded
                                    </b-form-checkbox>
                            </div>
                            <div class="col-md-5 d-flex">
                                <label for="">Completion status</label>
                                <multiselect  
                                v-model="selectedItem.completion_status" 
                                :searchable="false" 
                                :options="completion" 
                                placeholder="Search"  
                                :multiple="false" />
                            </div>
                            </v-row>
                            <v-row>
                              <div class="col-md-6">
                                    <label for="">Labour</label>
                                    <input type="number" class="form-control w-100"  v-model="selectedItem.labour" :disabled="selectedItem.enabled==0">
                                </div>
                                <div class="col-md-6">
                                    <label for="">Material</label>
                                    <input type="number" class="form-control w-100"  v-model="selectedItem.material" :disabled="selectedItem.enabled==0">
                                </div>
                                </v-row>
                            <v-row>
                                <div class="col-md-2">
                                    <label for="">Quantity(Fee)</label>
                                    <input type="number" class="form-control w-100"  v-model="selectedItem.quantity" :disabled="selectedItem.enabled==0">
                                </div>
                                <div class="col-md-5">
                                    <label for="">&#163;/Unit</label>
                                    <input type="number" class="form-control w-100" v-model="selectedItem.price" :disabled="selectedItem.enabled==0">
                                </div>
                                <div class="col-md-5">
                                    <label for="">Amount</label>
                                    <h6>{{selectedItem.amount}}</h6>
                                </div>
                            </v-row>
                            
                            <v-row>
                              <div class="col-md-4">
                                <b-form-checkbox
                                    class="mb-3"
                                    v-model="selectedItem.send_quote"
                                    disabled-field="notEnabled">
                                    Send for quoute
                                    </b-form-checkbox>
                            </div>
                                <div class="col-md-4">
                                        <b-form-checkbox
                                        id="checkbox-item-allowance"
                                            v-model="selectedItem.allowance"                        class="mb-3"
                                            
                                    >Allowance
                                        </b-form-checkbox>
                                </div>
                            </v-row>
                             <v-row class="quantity_estimate my-0">
                                <div class="col-md-6 my-auto">
                                    <b-button class="btn btn-success">Estimate  Qty on Plan</b-button>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Quantity Estimated</label>
                                    <div class="input-group w-75">
                                        <input type="text" class="form-control" placeholder="" readonly value="0.00">
                                        <div class="input-group-append">
                                            <b-button class="btn btn-light input-group-text">Copy over to Quantity!</b-button>
                                        </div>
                                    </div>
                                </div>
                            </v-row>
                        
                        <v-row>
                            <div class="col-md-8">
                                <label class="form-control-label">Comment</label>
                                    <b-form-textarea
                                    id="textarea"
                                    v-model="comment"
                                    placeholder=""
                                    rows="5"></b-form-textarea>
                            </div>
                        </v-row>
                        <v-row>
                            <div class="col-md-8">
                                <label class="form-control-label">Builder Comment</label>
                                    <b-form-textarea
                                    id="builder_comment"
                                    v-model="builder_comment"
                                    placeholder=""
                                    rows="5"
                                    ></b-form-textarea>
                            </div>
                        </v-row>
                    </div>
                    </transition>
                </div>
        </div><!-- end categories  -->
</template>
<script>
/*@Rupinder Singh */
import Multiselect from "vue-multiselect";
import MoneyFormat from "vue-money-format";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "quotecategories",
  data() {
    return {
      selectedCategory: {},
      selectedItem: {},
      showItemList:false,
      showItemForm:false,
      images: [],
      selected: "",
      comment: "",
      builder_comment: "",
      completion: ["Automatic", "Complete", "Incomplete"],
      enable: [{ item: "enable", name: "Enabled" }],
      options: [
        { item: "A", name: "Enabled" },
        { item: "B", name: "Excluded" },
      ],
      allowance: [
        { item: "A", name: "Send for quote" },
        { item: "B", name: "Allowance" },
      ],
    };
  },
  components: {
    Multiselect,
    MoneyFormat,
  },
  computed: {
    ...mapGetters([
      "categories",
      "quote",
      "categoryItems",
      "categoryItem",
      "quoteCategoriesCost",
    ]),
  },
  methods: {
    ...mapActions(["fetchCategoryItems"]),
    ...mapMutations([
      "SET_CATEGORY_ITEM",
      "SYNC_QUOTE",
      "SET_QUOTE",
      "SET_QUOTE_CATEGORIES_COST",
    ]),
    /*method to call on click of category*/
    setSelectedCategory(catId) {
      /*fetch category Items method in store items.js*/
      this.showItemList=false;
      this.fetchCategoryItems(catId).then((response)=>{
        this.showItemList=true;
      });
      this.categories.map((category) => {
        if (category.id == catId) {
          this.selectedCategory = Object.assign({ enabled: 0 }, category);
          console.log("selected category");
          this.selectedCategory.enabled =
           typeof this.quote.quote_exercises[catId] !== 'undefined' ? 1 : 0;
          this.selectedItem = {};
          console.log(this.selectedCategory);
        }
      });
    },
    setSelectedItem(itemId) {
      this.selectedItem = {};
        this.categoryItems.map((item) => {
        if (item.id == itemId) {
         /*  setTimeout(()=>{ */
          this.selectedItem =
          typeof  this.quote.quote_exercises[this.selectedCategory.id][item.id] !==
            'undefined'
              ? this.quote.quote_exercises[this.selectedCategory.id][item.id]
              : Object.assign(item, {
                  enabled: 0,
                  price: this.quoteItemCostPerPiece(item),
                  excluded: 0,
                 });
         /* },500) */

        }
      });
    },
    quoteCategoryProcess() {
      console.log("categoryProcess");
      /* Create copy of  state quote vuex store module quotes.js */
      let quote = Object.assign({}, this.quote);

      /* Check Category Enabled or Disabled 1 is enabled and 0 disabled*/
      if (this.selectedCategory.enabled == 1) {
        /*if Enabled check if category is there in quote.inactive*/
        if (
          typeof quote.inactive !== "undefined" &&
         typeof quote.inactive[this.selectedCategory.id] !== 'undefined'
        ) {
          /*if there copy it from there to quote_exercises*/
          quote.quote_exercises[`${this.selectedCategory.id}`] =
            quote.inactive[this.selectedCategory.id];
          /* remove from quote.inactive */
          /* quote.inactive.splice(this.selectedCategory.id, 1); */
          delete quote.inactive[this.selectedCategory.id];
          /*call Cost Calculation function*/
        } else {
          /*if category not in quote.inactive then add category in quote.exercises with empty object value*/
          quote.quote_exercises[`${this.selectedCategory.id}`] = {};
          this.SET_QUOTE(quote);
          return;
        }
      } else {
        console.log(`${this.selectedCategory.id}`);
        /*Check if quote.inactive is initialized or not*/
        if (typeof quote.inactive === "undefined") quote.inactive = {};
        /*move category and its items data to quote inactive*/
        quote.inactive[`${this.selectedCategory.id}`] = Object.assign(
          {},
          quote.quote_exercises[`${this.selectedCategory.id}`]
        );
        /* Remove category from quote_exercises  */
        //quote.quote_exercises.splice(`${this.selectedCategory.id}`,1); Not giving expected results
        /*Replace Category object from quote_exercises with empty or null or undefine*/
        /* let varUndefine;
        quote.quote_exercises.splice(
          `${this.selectedCategory.id}`,
          1,
          varUndefine
        ); */
        delete quote.quote_exercises[`${this.selectedCategory.id}`];
      }
      this.SET_QUOTE(quote);
      this.quoteCostCalculate();
      /*Call Calculation Function */
      console.log(quote);
    },
    quoteItemProcess() {
      console.log("quoteItemProcess Called");
      /* Create copy of  state quote vuex store module quotes.js */
      let quote = Object.assign({}, this.quote);

      quote.quote_exercises[this.selectedCategory.id][this.selectedItem.id] = Object.assign(this.selectedItem,{item_id:this.selectedItem.id});
      this.SET_QUOTE(quote);
      /*call cost calculation function*/
      this.quoteCostCalculate();
    },
    quoteItemCostPerPiece(item) {
      //item.price = item.labour + item.material;
      return (item.price / item.quantity).toFixed(2);
    },
    quoteCostCalculate() {
      console.log("quoteCostCalculate");
      /* Create copy of  state quote vuex store module quotes.js */
      let quote = Object.assign({}, this.quote);
      /*get overhead,profit and tax %*/
      let overhead = 0;
      let profit = 0;
      let tax = 0;
      /*Initialize Category Cost object */
      let categoriesCost = [];
      /*Initialize Total Sum Cost */
      let sumCost = 0;
      console.log(quote.quote_exercises);
      /*Check if any quotation exercises added */
      //if(Array.isArray(quote.quote_exercises)){*****
      if (Object.keys(quote.quote_exercises).length > 0) {
        /*loop through quote exercises and calculate cost */
        let quoteCats = Object.keys(quote.quote_exercises);
        for (let j = 0; j < quoteCats.length; j++) {
          let i = quoteCats[j];
          let tempCost = 0;
          /* if object is empty continue*/
          if (
            typeof quote.quote_exercises[i] === "undefined" ||
            typeof quote.quote_exercises[i] === "null" ||
            Object.keys(quote.quote_exercises[i]).length == 0
          ) {
            continue;
          }
          let catObj = quote.quote_exercises[i];
          console.log(catObj);
          /*Loop Through Category items */
          for (const itemIndex in catObj) {
            let item = quote.quote_exercises[i][itemIndex];
            if(item.enabled==0)
            continue;
            item.price = Number(Number(item.labour) + Number(item.material)).toFixed(2);
            item.amount = Number((item.quantity * item.price)).toFixed(2);
            tempCost += Number(item.amount);
            quote.quote_exercises[i][itemIndex].amount = item.amount;
          }

          categoriesCost[i] = tempCost;
          sumCost += Number(tempCost);
        } //End of Catgory Array
      } //End of IF quotation exercises added

      console.log(categoriesCost);
      console.log(sumCost);

      quote.calc_items_cost = Number(sumCost);
      quote.calc_overhead = Number((quote.overhead * sumCost) / 100).toFixed(2);
      quote.calc_profit = Number((quote.profit * sumCost) / 100).toFixed(2);
      quote.tamount = (
        Number(sumCost) +
        Number(quote.calc_overhead) +
        Number(quote.calc_profit)
      ).toFixed(2);
      quote.calc_tax = Number((quote.tax * quote.tamount) / 100).toFixed(2);
      quote.gross_amount = (Number(quote.tamount) + Number(quote.calc_tax)).toFixed(2);
      this.SET_QUOTE_CATEGORIES_COST(categoriesCost);
      this.SET_QUOTE(quote);
    },
    IsCategoryEnabled(categoryId){
 if(typeof this.quote.quote_exercises[categoryId]==='undefined')
  return 'Disabled';
  return 'Enabled';    
  },
GetCategoryCost(categoryId){
return typeof this.quoteCategoriesCost[categoryId]!=='undefined'?this.quoteCategoriesCost[categoryId]:''
},
IsCategoryItemEnabled(categoryId,itemId){
  if(typeof this.quote.quote_exercises[categoryId]=='undefined')
  return 'Disabled';
return typeof this.quote.quote_exercises[categoryId][itemId]!=='undefined' && this.quote.quote_exercises[categoryId][itemId].enabled ?'Enabled':'Disabled'
},
GetCategoryItemCost(categoryId,itemId){
  if(typeof this.quote.quote_exercises[categoryId]=='undefined')
  return '';
  return typeof this.quote.quote_exercises[categoryId][itemId]!=='undefined' && this.quote.quote_exercises[categoryId][itemId].enabled?Number((this.quote.quote_exercises[categoryId][itemId].amount)).toFixed(2) :'';
}
  },
  created() {
    console.log("Categories Length" + this.categories.length);
    console.log("Quote Length" + this.$store.getters.isQuoteEmpty);
    console.log(this.quote);
    console.log(this.$store.getters.quote.quote_exercises);
        console.log(this.$store.getters.quote.quote_exercises);
    if (this.$store.getters.isQuoteEmpty) {
      console.log("Line Number 418");
      this.$store
        .dispatch("fetchQuoteExercise", this.$route.params.id)
        .then((response) => {
          console.log("Line Number 422");
          console.log(this.quote);
          this.quoteCostCalculate();
        });
    }
    else if(typeof this.$store.getters.quote.quote_exercises === 'undefined'){
      console.log("Line Number 427");
      this.$store.commit('SYNC_QUOTE',{quote_exercises:{}});
    }
    console.log(this.$store.getters.quote.quote_exercises);
    if (this.categories.length === 0) {
      this.$store.dispatch("initCategories").then((response) => {
        this.setSelectedCategory(response[0].id);
      });
    } else {
      this.setSelectedCategory(this.categories[0].id);
    }

  },
  watch: {
    $route(to, from) {
      /** WATCH THE EXERCISE FUNCTIONS */
      if (to.params.id) {
        this.$store.dispatch("fetchQuoteExercise", to.params.id);
      }
    },
    selectedItem: {
      deep: true,
      handler: function (to, from) {
        if (to.item_id !== from.item_id) return;
        if (to.enabled == 1 && from.enabled == 1) {
          this.quoteCostCalculate();
        }
      },
    },
  },
};
</script>
<style scoped>
.quantity_estimate {
  border-top: 1px solid rgb(177, 179, 181);
  border-bottom: 1px solid rgb(177, 179, 181);
  margin: 30px 0px;
}
label {
  color: rgb(36, 48, 56);
  font-weight: 600;
}
.badge-info {
  font-size: 13px;
  font-weight: 400;
}
.badge-dark {
  font-size: 13px;
  font-weight: 400;
}
.categories2 ul li {
  border-width: 1px;
  border-color: #d9d9d9 #d9d9d9 transparent #d9d9d9;
  border-style: solid;
}
.categories2 ul li:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.categories2 ul li a.active {
  color: #fff;
  background-color: #337ab7 !important;
  border-radius: 0px !important;
}
/* category css */
.categories ul li a{
    color: #343434;
    font-weight: 500;
    border-width: 0 0 1px 0;
    border-color: #ebecf2;
    padding: 6px;
    letter-spacing: 0.5px;
    font-size: 13px;
    text-align: left;
    vertical-align: baseline;
    border-width: 1px 1px 1px 1px !important;
    border-radius: 0px;
}
.categories ul li a.active{
    color: #fff;
    background-color:#243038!important;
    border-radius: 0px!important;
}
.categories ul.subcategory li{
    border-width: 1px;
    border-color: #d9d9d9 #d9d9d9 transparent #d9d9d9;
    border-style: solid;
    background-color: #f4feff;
}
.categories ul li:last-child{
    border-bottom: 1px solid #d9d9d9;
}
.sub_category ul li{
    padding:5px;
    cursor: pointer;
}
.sub_category ul li .name{
    font-size: 13px;
    color: #243038;
    margin: 0px;
    letter-spacing: 0.5px;
}
.sub_category ul li .unit{
    font-size:12px;
    color:#343434;
    font-style: italic;
    margin: 0px;
    letter-spacing: 0.5px;
}
.sub_category ul li.active{
    background-color:#243038;
}
.sub_category ul li.active .name{
    color:#fff;
}
.sub_category ul li.active .unit{
    color:#fff;
}
.sub_category div{
   padding:5px 18px;
    background-color:#f0f0f0;
}
.sub_category1 ul li.active {
  background-color: #337ab7 !important;
}
/* .category_list ul li {
    max-height: 440px;
    overflow: auto;
    overflow-y: scroll;
    border-bottom: 1px solid #d9d9d9;
  } */
.no-border {
  border: 0;
  box-shadow: none;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-leave-active {
  transition: opacity .5s;
  opacity: 0;
}
    .slide-enter {
        opacity: 0;
        /*transform: translateY(20px);*/
    }

    .slide-enter-active {
        animation: slide-in 1s ease-out forwards;
        transition: opacity .5s;
    }

    .slide-leave-active {
        animation: slide-out 1s ease-out forwards;
        transition: opacity 1s;
        opacity: 0;
       }

        @keyframes slide-in {
        from {
            transform: translateY(20px);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes slide-out {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(20px);
        }
    }

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
} */

.quote-price h5{
    font-size: 1.05rem;
}
.sub_category h5{
    font-size: 0.9rem;
}
</style>
