<!--================================
=            LOCKSCREEN            =
=================================-->
<template>
  <v-app light>
    <section id="welcome" class="hide-overflow">
      <v-layout>
        <v-flex hidden-sm-and-down md6>
          <v-parallax style="margin-top:0" color="primary" height="600">
            <v-layout column align-center justify-center>
              <img width="100%" src="../../assets/img/bg-login.png" alt="Vuetify.js " height="150" />
            </v-layout>
          </v-parallax>
        </v-flex>

        <v-flex
          xs12
          md6
          align-content-space-between
          layout
          :pa-5="$vuetify.breakpoint.smAndDown"
          wrap
        >
          <v-layout column align-center justify-center>
            <v-flex xs10 md6 mx-auto justify-center>
              <v-row style="margin-top:50%">
                <div class="account-body accountbg">
                  <!-- Log In page -->
                  <div class="row vh-100">
                    <div class="col-12 align-self-center">
                      <div class="auth-page">
                        <div class="card auth-card shadow-lg">
                          <div class="card-body">
                            <div class="px-3">
                              <!--end auth-logo-box-->
                              <div class="text-center auth-logo-text">
                                <h4 class="mt-0 mb-3 mt-5">LockScreen</h4>
                                <p class="text-muted mb-0"></p>
                              </div>
                              <!--end auth-logo-text-->
                              <v-card-text>
                                <v-form>
                                  <v-text-field
                                    label="Login"
                                    name="login"
                                    prepend-icon="person"
                                    type="text"
                                  />
                                </v-form>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer />
                                <v-btn @click="login()" color="primary">Lockscreen</v-btn>
                              </v-card-actions>
                              <!--end form-->
                            </div>
                            <!--end /div-->
                            <div class="m-3 text-center text-muted">
                              <p class>
                                Not you ? return
                                <a class="text-primary ml-2">Sign In</a>
                              </p>
                            </div>
                          </div>
                          <!--end card-body-->
                        </div>
                        <!--end card-->
                      </div>
                      <!--end auth-page-->
                    </div>
                    <!--end col-->
                  </div>
                </div>
              </v-row>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </section>
  </v-app>
</template>
<script>
export default {
  name: "lock-screen",
  props: {
    source: String
  },
  data: () => ({
    loading: false,
    drawer: null,
    model: {
      username: "admin@admin.com",
      password: "password"
    }
  }),

  methods: {
    login() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push("/dashboard/dashboard1");
      }, 1000);
    }
  }
};
</script>


<!--====  End of LOCKSCREEN  ====-->