<!--=========================
=            APP            =
==========================-->
<template>
  <v-app>
    <app-layout></app-layout>
    <!-- <app-layout-branding></app-layout-branding> -->
  </v-app>
</template>
<script>
import Vue from 'vue';
import AppLayout from "./pages/layout/AppLayout.vue";
import AppLayoutBranding from "./pages/layout/AppLayoutBranding.vue";
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);
export default {
  name: "App",

  components: {
    AppLayout,
    AppLayoutBranding,
  },

  data: () => ({
    isMobile: false
  }),
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {
        passive: true
      });
    }
  },
created(){
//this.$store.dispatch('initCategories');
},

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {
      passive: true
    });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    }
  }
};
</script>
<style>
  .input.invalid label {
    color: red;
  }

  .input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
  }
</style>
<!--====  End of APP  ====-->