import axios from "axios";
const state = {
    suppliers: [],
    supplier: {},
};
const getters = {
    suppliers: (state) => {
        //console.log(state.suppliers);
        return state.suppliers;
    },
};

const actions = {
    initSuppliers: async({ commit }, pageNumber = false) => {
        return axios
            .get(`suppliers?page=${pageNumber}`)
            .then((response) => {
               // console.log(response.data);
                commit("SET_SUPPLIERS", response.data.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    initSuppliers2: async({ commit }, payload) => { 
        let pageNumber = '';
        let srch = '';
        if(payload && payload.pageNumber) pageNumber = payload.pageNumber
        if(payload && payload.srch) srch = payload.srch
        return axios
            .get(`suppliers?page=${pageNumber}&q=${srch}`)
            .then((response) => {
               // console.log(response.data);
                commit("SET_SUPPLIERS", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    initSuppliersSearch: async({ commit }, searchVal ,) => {
        return axios
            .get(`suppliers?q=${searchVal}`)
            .then((response) => {
               // console.log(response.data);
                commit("SET_SUPPLIERS", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    addSupplier: async({ commit }, formData) => {
        return await axios
            .post(`supplier/create`, formData)
            .then((response) => {
                commit("ADD_SUPPLIER", response.data);
                // console.log(response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            })
    },
    deleteSupplier: async({ commit }, id) => {
        return await axios
            .delete(`supplier/${id}/destroy`)
            .then((response) => {
                // commit("REMOVE_SUPPLIER", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    fetchsSuppliers: async({ commit }) => {
        await axios
            .get(`suppliers`)
            .then((response) => {
                commit("SET_SUPPLIERS", response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    editSupplier: async({ commit }, id) => {
        console.log("adadfsdfda");
        console.log(id);
        return axios
            .get(`supplier/${id}/edit`)
            .then((response) => {
                // commit("EDIT_SUPPLIER", response.data.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateSupplier: async({ commit }, formData) => {
        return await axios
            .post(`supplier/${formData.id}/update`, formData)
            .then((response) => {
                console.log(response.data);
                return response;
                // commit("UPDATE_SUBCONTRACTOR", formData.id);
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
};
const mutations = {
    SET_SUPPLIERS(state, payload) {
        state.suppliers = payload;
    },
    ADD_SUPPLIER: (state, supplier) => {
        state.suppliers = supplier;
    },
    EDIT_SUPPLIER: (state, supplier) => {
        state.supplier = supplier;
    },
    setCurrentPage: (state, data) => {
        state.suppliers.current_page = data; // set current page on pagination
    },
    // REMOVE_SUPPLIER: (state, supplier) => (
    //     state.suppliers.filter((supplier) => supplier.id !== id),
    //     state.suppliers.splice((supplier) => supplier.id, 1)
    // ),
};

export default {
    state,
    mutations,
    actions,
    getters,
};