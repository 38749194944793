<template lang="">
<v-row>
  <div class="col-md-12">
  <div class="home_box1">
    <div class="col-md-12" v-if="set">
      <h3>Work Space List</h3>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="WorkSpaceHeaders"
          :items="WorkSpaceData"
          :loading = "loading"
          loading-text="Loading... Please wait"
          :search="search">
          <template v-slot:item.actions="{ item }">
            <v-btn 
              rounded 
              v-b-tooltip.hover
              title="User List" 
              @click="usersList(item.slug)"
              color="primary"> Users List</v-btn>
            </template>
          </v-data-table>
      </v-card>
    </div>

  <div class="col-md-12" v-else>
  <v-btn rounded v-if="workspaceSet" @click="workSpaceList()" class="mb-3" color="primary"><i class="fas fa-angle-double-left"></i> View Workspace List</v-btn>
    <div class="home_box1">
      <h3><v-btn class="mb-3" color="primary">Workspace - User List: {{ getPage.workSpaceName }}</v-btn></h3>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search"  append-icon="mdi-magnify"  label="Search"  single-line  hide-details ></v-text-field>
        </v-card-title>
        <v-data-table :headers="UserListHeaders" :items="itemsWithSno" :search="search" :items-per-page="15" hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-btn class="mr-1" v-b-tooltip.hover title="Edit Email" v-b-modal.modal-email small @click="editEmail(item.id)" color="primary">Edit Email</v-btn>
            <v-btn class="mr-1" v-b-tooltip.hover title="Edit Password" v-b-modal.modal-password small @click="editPassword(item.user_id)" color="primary">
              Edit Password
            </v-btn>
            <v-btn class="mr-1" v-b-tooltip.hover title="Delete User" small @click="userDelete(item.id)" color="primary"><i class="far fa-trash-alt"></i></v-btn>
          </template>
          </v-data-table>
      </v-card>
          <v-pagination v-model="currentPage" :length="lastPage" total-visible="10">
          </v-pagination>
    </div>
    </div>
     <b-modal v-model="is_open" hide-footer="true" id="modal-email"  centered title="Edit Email">
      <v-form>
        <v-text-field 
          id="userEmail"
          v-model="userData.email" 
          label="Email" 
          prepend-icon="person"
          type="User Email"
          hide-details="auto"></v-text-field>
          <v-btn rounded small @click="updateEmail()" class="mt-4" color="primary">Update <i v-if="loader.email" class="fas fa-spinner fa-pulse"></i></v-btn>
      </v-form>
    </b-modal>

    <b-modal v-model="pass_open" id="modal-password" hide-footer="true" centered title="Edit Password">
      <v-form>
        <v-text-field 
          id="userPassword"
          v-model="userData.password" 
          label="Password" 
          prepend-icon="lock"
          type="User Password"
          hide-details="auto">
        </v-text-field>
        <div v-if="submitted && $v.userData.password.$error" class="invalid-feedback">
            <span v-if="!$v.userData.password.required">Password is required</span>
            <span v-if="!$v.userData.password.minLength">Password must be at least 8 characters</span>
        </div>

        <v-text-field 
          id="userPassword"
          v-model="userData.confirm_password" 
          label="Confirm Password" 
          prepend-icon="lock"
          type="User Password"
          hide-details="auto">
        </v-text-field>
        <div v-if="submitted && $v.userData.confirm_password.$error" class="invalid-feedback">
            <span v-if="!$v.userData.confirm_password.required">Confirm Password is required</span>
            <span v-else-if="!$v.userData.confirm_password.sameAsPassword">Passwords must match</span>
        </div>
        <v-btn rounded small @click="updatePassword()" class="mt-4" color="primary">Update Password <i v-if="loader.password" class="fas fa-spinner fa-pulse"></i></v-btn>
      </v-form>
    </b-modal>
  </div>
  </div>
  </v-row>
</template>
<script>
import Vue from "vue";
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators';
// import "vue-good-table/dist/vue-good-table.css";
// import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
// Vue.use(VueGoodTable);
export default {
name:"user-list",
  components: {
    // VueGoodTable,
  },
  data() {
    return {
      loader:{
        email:false,
        password:false,
      },
      userData:{
          id:"",
          email:"",
          password:"",
          confirm_password:"",
        },
      loading:false,
      submitted: false,
      is_open:false,
      pass_open:false,
      role:"",
      userId:"",
      userEmail:"",
      workspaceSet:false,
      set: true,
      getPage:{
        workSpaceName:"",
        pageNumber:""
      },
      // Workspace table
      search: '',
      WorkSpaceData: [],
      WorkSpaceHeaders: [
        { text: 'Serial', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Action', value: 'actions' },
      ],
      // User List table
      UserListData: [],
      UserListHeaders: [
        { text: 'Serial', value: 'sno' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Edit & Delete', value: 'actions' },
      ],
    };
  },
  validations: {
     userData: {
        password: { required, minLength: minLength(8) },
        confirm_password: { required, sameAsPassword: sameAs('password') },
      }
    },
  watch:{
    currentPage( newValu, oldVal){
        this.paginatePage(newValu);
    }
  },
   computed:{
    itemsWithSno() {
      return this.UserListData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        console.log(this.$store.getters.getWorkSpaceUserList);
          return this.$store.getters.getWorkSpaceUserList.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
      },
    },
    lastPage:{
        get(){
            return this.$store.getters.getWorkSpaceUserList.last_page; // get last page
        }
    }
  },
  methods: {
    paginatePage(pageNumber){
      this.getPage.pageNumber = pageNumber;
      this.getPage['pageNumber'] = pageNumber;
      // console.log(this.getPage);
      this.loading = true;
        this.$store.dispatch("usersListPaginate", this.getPage).then((succes) => {
            this.UserListData = succes.data;
            if(succes){
              this.loading = false;
            }
        });
    },
    editPassword(id){
      this.userData.id = id;
    },
    updatePassword(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.loader.password = true;
      console.log(this.userData);
      this.$store.dispatch("updatePassword",this.userData).then((success) => { 
        console.log(success);
        if(success.status == true){
          this.$fire({
            title: "Password Updated Successfully!",
                type: "success",
                timer: 4000
            });
        this.pass_open = false;
        this.loader.password = false;
        }
        else if(success.status == false){
          this.$fire({
                title: success.message,
                type: "warning",
                timer: 4000
            });
        this.loader.password = false;
        }
      });
    },
    updateEmail(){
      this.loader.email = true;
      this.$store.dispatch("UserUpdateEmail",this.userData).then((success) => { 
        console.log(success);
        if(success.status == true){
          this.$fire({
            title: "Email Updated Successfully!",
                type: "success",
                timer: 4000
            });
          this.usersList(this.getPage.workSpaceName);
          this.is_open = false;
          this.loader.email = false;
        }
        else if(success.status == false){
          this.$fire({
                title: success.message,
                type: "warning",
                timer: 4000
            });
          this.loader.email = false;
        }
      });
    },
    editEmail(userId){
      this.$store.dispatch("UserEditEmail",userId).then((success) => { 
        this.userData = success;
        console.log(success);
      });
    },
    workSpaceList(){
      this.set = true;
    },
    usersList(slug) {
      this.$store.dispatch("usersList",slug).then((success) => { 
        this.UserListData = success.data;
        this.set = false;
        this.workspaceSet=true;
        this.getPage.workSpaceName = slug;
        console.log(success);
      });
    },
  }, 

  created() {
    this.loading = true;
    this.$store.dispatch("getUserWorkSpaceList").then((success) => { 
      this.WorkSpaceData = success.workspaces;
      console.log(success.workspaces);
      if(success){
        this.loading = false;
      }
    });

    this.$store.dispatch("usersList").then((response) => { 
      console.log(response);
      this.UserListData = response.data;
    });
    
    this.role = this.$store.getters.auth.user.workspace_role;
    console.log(this.role);
    if(this.role == 'Workspace Owner'){
      this.set = false;
      this.workspaceSet = false;
    }
    else if(this.role == 'Workspace Admin'){
      this.set = false;
      this.workspaceSet = false;
    }
    
  }, 
};
</script>
<style>
  .primary {
    background-color: #672178 !important;
    border-color: #672178 !important;
}
</style>