<template>

  <div class="col-md-12 item_configuration">
    <v-row>
      <div class="col-md-12">
        <div>
          <tabs>
            
              <tab name="Add Item">
              <item-form></item-form>
              </tab>            
            <tab name="Edit Item">
              <v-row>
                <div class="col-md-4">
                  <vue-search
                    placeholder="Search category"
                    v-model="item.item_id"
                    :img-photo="'path-img'"
                    @newitem="newitem()"
                    @itemselected="categorySelected($event)"
                    :source-field="'name'"
                    :show-new-botton="true"
                    :search-by-field="false"
                    :api-source="'/categories'"
                    :query-key="'q'"
                  >
                  </vue-search>
                </div>
                <div class="col-md-4">
                  <multiselect
                    v-model="item"
                    label="name"
                    :options="items"
                    placeholder="Select item"
                    :multiple="false"
                    :taggable="true"
                    @input="itemSelected"                
                  ></multiselect>
                </div>
                <div class="col-md-4">
                  <v-btn @click="editItemCategory"  class="btn btn-success">Edit</v-btn
                  >
                </div>
              </v-row>
              <item-form :_item="item" title="Update" edit=true v-if="isEdit"></item-form>
            </tab>
          </tabs>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueSearch from "../../utils/VueSearch";
import Tabs from "vue-tabs-component";
import ItemForm from './ItemForm.vue';
import Multiselect from "vue-multiselect";
import {API_BASE_URL} from "../../../config.js"
Vue.use(Tabs);


export default {
  name: "item",
  components: {
    Multiselect,
    "vue-search": VueSearch,
    ItemForm
  },
  data() {
    return {
      isEdit: false,
      item: {},
      items: [],
      API_BASE_URL : API_BASE_URL
    };
  },
  methods: { 
    categorySelected(e) {
       if (e.id != null && e.id !== "undefined") {
        this.item.item_id = e.id;
        this.$store.dispatch("fetchCategoryItems", e.id).then((data) => {
          this.items = data;
        });
      } 
    },
    itemSelected(v,id){
     
this.item=v;
    },
    editItemCategory() { 
      this.isEdit=false;
      if (Object.keys(this.item).length > 0) {
        let vm =this;

        setTimeout(()=>{ vm.isEdit=true; },1000);

      }
    },
  },
/*     mounted() {
    $(document).on("click", ".sub_category ul li", function() {
      $(this)
        .next("li")
        .removeClass("active");
      $(this)
        .prev("li")
        .removeClass("active");
      $(this).addClass("active");
    });
    $(document).on("click", ".category_select", function() {
      $(".category_configuration").css("display", "block");
      $(".item_configuration").css("display", "none");
    });
    $(document).on("click", ".item_select", function() {
      $(".item_configuration").css("display", "block");
      $(".category_configuration").css("display", "none");
    });
    $(document).on("click", ".remove_img", function() {
      $(this)
        .prev("img")
        .remove();
      $(this).remove();
    });
  }, */
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
