/*RS utility function to return filtered Object*/

function objectFilter(rawObj, allowed) {
    const filtered = Object.keys(rawObj)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = rawObj[key];
            return obj;
        }, {});
    return filtered;
}

function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function isObjectPropertyExist(obj, property) {
    return obj.hasOwnProperty(property);
}
export {
    objectFilter,
    isObjectEmpty,
    isObjectPropertyExist
}