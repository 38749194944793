<template lang="">
        <div class="col-md-12">
            <div class="tiles" id="tiles-1">
                <!-- Tab panes -->
                <div class="tab-content categories">
                    <component :is="selectedComponent">
                        <p>Default Content</p>
                    </component>
                </div>
            </div>
        </div>
</template>

<script>
import category from '../../components/settings/CategoryComponent.vue';
import item from '../../components/settings/Items/Item.vue';
import unit from '../../components/settings/Unit.vue';
import status from '../../components/settings/Status.vue';
import quoteSetting from '../../components/settings/Quotes/QuoteSettings.vue';
export default {
    name: 'settings',
    components: {
        category,
        item,
        unit,
        status,
        quoteSetting
    },
    data() {
        return {
            category: {
                name: ''
            },
            /* selectedComponent: 'category' */
        }
    },
    props:['selectedComponent'],
    methods: {
        addCategory() {
            console.log('Add Category Form Submitted');
        },
        addItem() {
            console.log('Add Item Form Submitted');
        }
    },
    created(){
        console.log(this.selectedComponent);
    }

}
</script>

<style>
.tiles {
    width: 100%;
}

#tiles-1 .nav-tabs {
    position: relative;
    border: none !important;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

#tiles-1 .nav-tabs li {
    margin: 0px !important;
}

#tiles-1 .nav-tabs li a {
    position: relative;
    margin-right: 0px !important;
    padding: 10px 40px !important;
    font-size: 16px;
    border: none !important;
    color: #000;
    font-weight: 600;
    display: flex;
    justify-content: center;
}

#tiles-1 .nav-tabs .active {
    background-color: transparent !important;
    border-bottom: 5px solid #672178 !important;
    color: #672178 !important;
}

/* category css */
.categories ul li a {
    color: #343434;
    font-weight: 500;
    border-color: #ebecf2;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size: 13px;
    text-align: left;
    vertical-align: baseline;

    font-size: 14px !important;
    border: 0px solid #ccc;
    border-width: 1px 1px 3px 1px !important;
}

.categories ul li a.active {
    /* color: #fff;
    background-color: #243038 !important; */
    border-radius: 0px !important;
    font-size: 14px !important;
    border: 0px solid rgb(103, 33, 120);
    color: rgb(103, 33, 120);
}

.categories ul.subcategory li {
    border-width: 1px;
    border-color: #d9d9d9 #d9d9d9 transparent #d9d9d9;
    border-style: solid;
}

/* .categories ul li:last-child{
    border-bottom: 1px solid #d9d9d9;
} */
.sub_category ul{
    max-height: 82vh;
    overflow: auto;
    border-bottom: 1px solid #d9d9d9;
}
.sub_category div {
    padding: 5px 8px;
    background-color: #f0f0f0;
}

.sub_category div h6 {
    font-size: 15px;
    color: #243038;
}

.sub_category div a {
    font-size: 15px;
}

.sub_category ul li {
    padding: 10px;
    cursor: pointer;
}

.sub_category ul li .name {
    font-size: 13px;
    color: #243038;
    margin: 0px;
    letter-spacing: 0.5px;
}

.sub_category ul li .unit {
    font-size: 13px;
    color: #000;
    font-weight: 700;
    font-style: italic;
    margin: 0px;
    letter-spacing: 0.5px;
}

.sub_category ul li.active {
    background-color: #ffb3f6;
}

.sub_category ul li.active .name {
    color: #000;
}

.sub_category ul li.active .unit {
    color: #000;
}

.category_item {
    background-color: #f7f7f7;
    padding: 20px;
}

.category_item h4 {
    font-size: 18px;
    font-weight: 600;
    color: #343434;
}

.category_item ul li {
    list-style-type: disc;
    border-width: 0px;
    border-color: transparent;
    border-style: solid;
    background-color: transparent;
}

.category_item ul li:last-child {
    border-bottom: 1px solid transparent;
}

.category_item ul>li {
    line-height: 1.6;
    padding-bottom: 4px;
    margin: 10px 0 10px 20px;
    color: #555;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.configuration_footer {
    border-top: 1px solid rgb(221, 221, 221);
}

.configuration_footer button {
    padding: 7px 16px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 3px;
}

.configuration_footer input:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
    border-bottom: 2px solid rgb(0, 176, 190);
}

.configuration_footer input {
    border-color: transparent;
    border-bottom: 2px solid rgb(0, 176, 190);
}

.multiple_img {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.multiple_img img {
    width: 100%;
    height: auto;
    position: relative;
}

.remove_img {
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: red;
    padding: 5px;
    border-radius: 50px;
    width: 30px;
    height: 30px;
}

.remove_img i {
    color: #fff;
}

.modal-card,
.modal-content {
    overflow: unset;
}

[type=number] {
    width: 100% !important;
}

.tabs-css ul li:not(:first-child) {
    margin-left: 10px;
}

.tabs-css ul li a {
    color: #343434;
    font-weight: 500;
    border-color: #ebecf2;
    padding: 8px 20px;
    letter-spacing: 0.5px;
    font-size: 13px;
    text-align: left;
    vertical-align: baseline;
    font-size: 14px !important;
    border: 0px solid #ccc;
    border-width: 1px 1px 5px 1px !important;
}

.tabs-css ul li a.active {
    border: 0px solid rgb(103, 33, 120);
    color: rgb(103, 33, 120);
}
</style>
