// @ts-nocheck
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'vue-material/dist/vue-material.min.css'

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMaterial from 'vue-material';
import BootstrapVue from "bootstrap-vue";
import vuetify from "./plugins/vuetify";
import VueLodash from 'vue-lodash'

import VueSweetalert2 from "vue-sweetalert2";
import VueSimpleAlert from "vue-simple-alert";
import VueAnimated from '@codekraft-studio/vue-animated'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VuePageTransition from 'vue-page-transition'
import {API_BASE_URL} from "./config.js"
import store from './store'
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
const options = {
        name: 'lodash'
    } // customize the way you want to call it
axios.defaults.baseURL = API_BASE_URL;
Vue.use(VueLodash, options);
Vue.use(VueMaterial);
Vue.use(VueAnimated);
Vue.use(VueSweetalert2);
Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue);
Vue.use(VuePageTransition);
Vue.use(Vuelidate);
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
const appConfig = {
    drawers: ['Default (no property)', 'Permanent', 'Temporary'],
    model: null,
    type: 'default (no property)',
    clipped: false,
    floating: false,
    mini: false,
    sidebarbasic: true,
    expandOnHover: false,
    drawerRight: false,
    footer: {
        inset: true,
    },
    swatches: [
        ['#672178', '#000080'],
        ['#0a58c3', '#373854'],
        ['#003366', '#493267'],
    ],

    color: "#672178",

    hideCanvas: true,
    hideInputs: true,
    hideModeSwitch: true,
    mode: "hexa",
    modes: ["rgba", "hsla", "hexa"],
    showSwatches: true,
    right: false,
    layout: {
        default: true,
        admin: false,
        horizontal: false
    }


}

class Core {
    constructor() {
        let vm = this;
        vm._isVue = true;
    }
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    appConfig
                };
            },

            computed: {
                path: {
                    get() {
                        let vm = this;
                        return {
                            group: vm.$route.fullPath.split("/")[1],
                            sub: vm.$route.fullPath.split("/")[2]
                        };
                    }
                }
            },

        });
    }
}
let core = new Core();

/*----------  Style ----------*/

Vue.use(core);

import "./theme/main.css"
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount("#app");