import axios from "axios";
const state = {
    status: [],
    status_table: []
};
const getters = {
    status: (state) => {
        return state.status;
    },
    status_table: (state) => {
        return state.status_table;
    },
};

const actions = {
    initStatus: async({ commit }, pageNumber) => {
        return axios
            .get(`statuses?page=${pageNumber}`)
            .then((response) => {
                commit("SET_STATUS", response.data.data);
                commit("SET_STATUS_TABLE", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    addStatus: async({ commit }, formData) => {
        return await axios
            .post(`status/create`, formData)
            .then((response) => {
                // commit("ADD_UNIT", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    deleteStatus: async({ commit }, id) => {
        return await axios
            .delete(`status/${id}/delete`)
            .then((response) => {
                // commit("REMOVE_UNIT", id);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    editStatus: async({ commit }, id) => {
        return axios
            .get(`status/${id}/edit`)
            .then((response) => {
                // commit("EDIT_SUPPLIER", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    async updateStatus({ commit }, formData) {
        console.log(formData);
        return await axios.post(`status/${formData.id}/update`, formData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error.response);
                return error.response;
            });
    },
};
const mutations = {
    SET_STATUS(state, payload) {
        state.status = payload;
    },
    SET_STATUS_TABLE(state, payload) {
        state.status_table = payload;
    },
    ADD_UNIT: (state, unit) => state.Status.unshift(unit),
    REMOVE_UNIT: (state, id) => state.Status.filter((unit) => unit.id !== id),
    //   EDIT_UNIT: (state, unit) => {
    //     state.Status = unit;
    //   },
    setCurrentPage: (state, data) => {
        state.status_table.current_page = data; // set current page on pagination
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};