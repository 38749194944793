<!--=================================
=            BASETOOLBAR            =
==================================-->
<template>
    <div>
        <v-app-bar clipped-right :clipped-left="appConfig.clipped" class="elevation-0 transparent" :dark="appConfig.dark" app>
            <v-app-bar-nav-icon color="primary" v-if="appConfig.type !== 'permanent'" @click.stop="appConfig.model = !appConfig.model">
                <v-btn color="primary" icon>
                    <v-icon>mdi-view-headline</v-icon>
                </v-btn>
            </v-app-bar-nav-icon>
            <!--<starter-search></starter-search>-->
            <v-spacer></v-spacer>
            <!--<starter-Menu></starter-Menu>-->
            <starter-dropdown></starter-dropdown>
            <starter-avatar></starter-avatar>
            <v-app-bar-nav-icon color="primary" fab small @click.stop="appConfig.drawerRight = !appConfig.drawerRight">
                <i class="fa fa-2x fa-cog fa-spin"></i>
            </v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer class="setting-drawer" v-model="appConfig.drawerRight" app clipped right>
            <!-- APPsETTINGS -->
            <starter-settings></starter-settings>
        </v-navigation-drawer>

    </div>
</template>
<script>
import StarterDropdown from "../Starter/StarterDropdown.vue";
import StarterSettings from "../Starter/StarterSettings.vue";
import StarterMenu from "../Starter/StarterMenu.vue";
import StarterSearch from "../Starter/StarterSearch.vue";

import StarterAvatar from "../Starter/StarterAvatar.vue";
export default {
    name: "base-toolbar",
    components: {
        StarterSettings,
        StarterMenu,
        StarterDropdown,
        StarterAvatar,
        StarterSearch
    },
    data: () => ({
        searchActive: false,

        sheet: false,

        items: [{
                text: "Real-Time",
                icon: "mdi-clock"
            },
            {
                text: "Audience",
                icon: "mdi-account"
            },
            {
                text: "Conversions",
                icon: "mdi-flag"
            }
        ]
    })
};
</script>
<style scoped></style>
<!--====  End of BASETOOLBAR  ====-->