<template lang="">
  <v-row>
    <div class="col-md-12">
      <div class="status_table">
        <!-- <vue-good-table
          :columns="columns"
          :rows="status"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 5,
          }"
          styleClass="vgt-table"
        >
          <template slot="table-row" slot-scope="props">
            <span class="edit_delete" v-if="props.column.field == 'action'">
              <button
                v-b-modal.modal-center
                @click="editRow(props.row.id)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="fas fa-marker"></i>
              </button>
              <button
                @click="deleteRow(props.row.id)"
                class="btn btn-sm btn-outline-danger"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table> -->
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="statusData"
            :headers="headers"
            :items="itemsWithSno"
            :search="search"
            :loading = "loading"
            loading-text="Loading... Please wait"
            :items-per-page="15"
            hide-default-footer>
            <template v-slot:item.actives="{ item }">
                    <button v-if = (item.active)>  Yes </button>
                    <button v-else> No</button> 
            </template>
            <template v-slot:item.accepteds="{ item }">
                    <button v-if = (item.accepted)>  Yes </button>
                    <button v-else>No </button> 
            </template>
            <template v-slot:item.actions="{ item }">
              <button
                v-b-modal.modal-center
                v-b-tooltip.hover
                title="Edit"
                @click="editRow(item.id)">
                <v-icon small>mdi-pencil</v-icon>
              </button>
              <button 
                v-b-tooltip.hover
                title="Delete"
                @click="deleteRow(item.id)" class="text-danger">
                <v-icon small> mdi-delete</v-icon>
              </button>
            </template>
          </v-data-table>
          <v-pagination
            v-model="currentPage"
            :length="lastPage"
            total-visible="10">
          </v-pagination>
        </v-card>
      </div>
    </div>
    <div class="col-md-12">
      <div class="configuration_footer">
        <!-- Actual search box -->
        <v-row>
          <div class="col-md-5">
            <b-btn class="btn-info category_select"
             @mouseover="updateToSaveBtn"
             v-b-modal.modal-center><i class="fa fa-plus-circle"></i> Add Status</b-btn>
            <b-modal
              v-model="is_open"
              id="modal-center"
              hide-footer
              size="lg"
              centered
              :title="modalHeaderName"
            >
              <v-row>
                <div class="col-md-4">
                  <label for="">Name</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    v-model="formData.name"
                    class="form-control"
                    name="name"
                  />
                  <div v-if="submitted && !$v.formData.name.required" class="invalid-feedback">Name is required</div>
                </div>
              </v-row>
              <div class="cancel_save_btn text-right">
                <v-btn @click="saveForm()" rounded color="primary" v-if="isedit">Save</v-btn>
                <v-btn @click="updateForm()" rounded color="primary" v-else>Update</v-btn>
              </div>
            </b-modal>
          </div>
          <div class="col-md-2"></div>
        </v-row>
      </div>
    </div>
  </v-row>
</template>
<script>
import Vue from "vue";
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators'
import Multiselect from "vue-multiselect";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
Vue.use(VueGoodTable);
export default {
  name: "configuration",
  components: {
    Multiselect,
    VueGoodTable,
  },
  data() {
    return {
      modalHeaderName:'Update Status',
      value: "",
      is_open:false,
      formData: {},
      isedit: true,
      submitted: false,
      color: ["Success", "Info", "Warning", "Danger", "Purple"],
      options: [
        { item: "A", name: "Is active" },
        { item: "B", name: "Accepted" },
      ],
      error_sms:"",
      loading:false,
      search:"",
      headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Active",
          value: "actives",
        },
        {
          text: "Accepted",
          value: "accepteds",
        },
        {
          text: "Action",
          value: "actions",
        },
      ],
      statusData: [],
      selected: "",
    };
  },
  validations: {
     formData: {
        name: { required },
      }
    },
      watch: {
    currentPage( newVal, oldVal){
      this.paginatePage(newVal);
    },
     deep: true
  },

  computed:{
    itemsWithSno() {
      return this.statusData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        return this.$store.getters.status_table.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
        }
    },
    lastPage:{
      get(){
          return this.$store.getters.status_table.last_page; // get last page
      }
    }
  },
  methods: {
    updateToSaveBtn(){
      this.isedit = true;
      this.formData = {};
      this.submitted = false;
      this.modalHeaderName = 'Add Status';
    },
      paginatePage(pageNumber){
      this.loading = true;
        this.$store.dispatch("initStatus", pageNumber).then((response) => {
            this.statusData = response.data;
            if(response){
              this.loading = false;
            }
        });
    },
    init() {
      this.$store.dispatch("initStatus").then((success) => { 
        this.statusData =  success.data;
      });
    },
    
    saveForm: function() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
        this.$store.dispatch("addStatus", this.formData).then((response) => {
          console.log(response.data);
          if(response.status == 422){
            this.$fire({
             title: "The name has already been taken.",
             text: "",
             type: "warning",
             timer: 4000
           });
           this.submitted = false;
          }
          if(response.data.status == true){
            this.$fire({
             title: "Status Added Successfully!",
             text: "",
             type: "success",
             timer: 4000
           });
          this.init();
          this.is_open = false;
          }
        });
    },

    updateForm: function() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        console.log(this.formData);
        this.$store.dispatch("updateStatus", this.formData).then((success) => {
          console.log(success);
          if(success.status == 200){
            this.$fire({
              title: "Status Updated Successfully!",
              text: "",
              type: "success",
              timer: 4000
            });
            this.init();
            this.submitted = false;
            this.is_open = false;
          }
        });
      },

    editRow: function(id) {
      this.isedit = false;
      this.error_sms = '';
      this.modalHeaderName = 'Update Status';
      this.$store.dispatch("editStatus", id).then((data) => {
        this.formData = data;
      });
    },
    deleteRow: function(id) {
      this.$confirm("","Are you sure?","question").then(() => {
        this.$store.dispatch("deleteStatus", id).then((success) => {
          console.log(success);
          if (success.status == 200) {
           this.$fire({
            title: "Status Deleted Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
        }
          this.init();
        });
       });
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("initStatus").then((success) => {
       this.statusData =  success.data;
       if(success){
        this.loading = false;
      }
    });
  },
};
</script>
