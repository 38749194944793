import axios from "axios";
import { alpha } from "vuelidate/lib/validators";
import * as UTILS from '../../utilities/functions.js'
const state = {
    quotes: {},
    quote: {},
    quoteCategoriesCost: [],
    quote_settings: [],
    quote_exercises: [],
    quotes_amount: [],
    quotePaginate: {}

};
const getters = {
    quote: (state) => {
        return state.quote;
    },
    quotePaginate: (state) => {
        return state.quotePaginate;
    },
    quoteCategoriesCost: (state) => {
        return state.quoteCategoriesCost;
    },
    isQuoteEmpty: (state) => {
        return UTILS.isObjectEmpty(state.quote);
    },
    isQuoteExercisesEmpty: (state) => {
        if (UTILS.isObjectEmpty(state.quote))
            return true;
        if (!state.quote.quote_exercises)
            return true;
        if (Object.keys(state.quote.quote_exercises).length > 0) {
            /*loop through quote exercises and calculate cost */
            let quoteCats = Object.keys(state.quote.quote_exercises);
            for (let j = 0; j < quoteCats.length; j++) {
                let i = quoteCats[j];

                /* if object is empty continue*/
                if (
                    typeof state.quote.quote_exercises[i] === "undefined" ||
                    typeof state.quote.quote_exercises[i] === "null" ||
                    Object.keys(state.quote.quote_exercises[i]).length == 0
                ) {
                    continue;
                }
                return false;
            } //End of Catgory Array
        } //End of IF quotation exercises added
        return true;

    },
    quotes: (state) => {
        return state.quotes;
    },
    quoteSettings: (state) => {
        return state.quote_settings;
    },
    quoteExercise: (state) => {
        return state.quote_exercises;
    },
    quoteExerciseAmount: (state) => {
        return state.quotes_amount;
    },


};

const actions = {
    initQuotes: async({ commit }, pageNumber) => {
        return axios
            .get(`quote?page=${pageNumber}`)
            .then((response) => {
                commit("SET_QUOTES", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    initQuotes2: async({ commit }, payload) => {
         let pageNumber = '';
        let srch = '';
        if(payload && payload.pageNumber) pageNumber = payload.pageNumber
        if(payload && payload.srch) srch = payload.srch
        return axios
            .get(`quote?page=${pageNumber}&q=${srch}`)
            .then((response) => {
                commit("SET_QUOTES", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },initQuotesSearch: async({ commit }, searchVal ,) => {
        return axios
            .get(`quote?q=${searchVal}`)
            .then((response) => {
               // console.log(response.data);
                commit("SET_QUOTES", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    syncQuote: async({ commit, getters }, payload) => {
        console.log('payload');
        /*commit('SET_QUOTE', Object.assign(getters.quote, payload));
        console.log(getters.quote);*/
    },
    addQuote: async({ commit }, formData) => {
        return await axios
            .post(`quote/create`, formData)
            .then((response) => {
                commit('SET_QUOTE', response.data);
                // console.log(response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    deleteQuote: async({ commit }, id) => {
        return await axios
            .delete(`quote/${id}/delete`)
            .then((response) => {
                // commit("REMOVE_QUOTE", id);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    editQuote: async({ commit }, id) => {
        return axios
            .get(`quote/${id}/edit`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    async updateQuote({ commit }, formData) {
        const response = await axios.post(`quote/${formData.id}/update`, formData);
        // commit("UPDATE_QUOTE", response.data);
        return response;
    },
    async updateQuoteNew({ getters, commit }) {
        let formData = getters.quote;
        const response = await axios.post(`quote/${formData.id}/updateNew`, formData);
        // commit("UPDATE_QUOTE", response.data);
        return response;
    },

    // add quote exercise
    addQuoteExercise: async({ commit }, formData) => {
        console.log(formData);
        return await axios
            .post(`quote/${formData.quote_id}/step2/create`, formData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    // temp API for testing
    tempAPI: async({ getters, commit }, formData) => {
        //axios.defaults.baseURL = 'http://localhost/api-php';
        return await axios
            .post('index.php', getters.quote)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    // Fetch quote exercise
    fetchQuoteExercise: async({ commit }, quote_id) => {
        return await axios
            .get(`quote/${quote_id}/edit/step2`)
            .then((response) => {
                response.data = Object.assign({
                    calc_tax: 0,
                    calc_overhead: 0,
                    calc_profit: 0,
                    calc_items_cost: 0,
                    gross_amount: 0
                }, response.data);
                if (Array.isArray(response.data.quote_exercises) && response.data.quote_exercises.length == 0)
                    response.data.quote_exercises = {}
                commit("SET_QUOTE", response.data);
                commit("SET_QUOTE_AMOUNT", response.data.QuoteExercises_amount);
                commit("EDIT_QUOTE_EXERCISES", response.data.quote_exercises);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    // GET Single quote exercise if exist by ID
    editQuoteExercise: async({ commit }, formdata) => {
        return await axios
            .get(`quote/${formdata.quote_id}/${formdata.item_id}/edit/quote-exercise`)
            .then((response) => {
                //  commit("SET_QUOTE_AMOUNT", response.data.QuoteExercises_amount);
                commit("EXERCISE_DATA", response.data);
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    },
    // Fetch quote exercise
    updateQuoteExercise: async({ commit }, formData) => {
        console.log(formData);
        return await axios
            .post(`quote/${formData.quote_id}/step2/${formData.id}/update`, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    // Update quotes exercise status
    updateExerciseStatus: async({ commit }, formData) => {
        console.log(formData)
        return await axios
            .post(`quote/${formData.quote_id}/edit/step2`, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },

    /**
     * Quote Settings 
     * @param {*} param0 
     * @param {*} formData 
     * @returns 
     */
    addQuoteSetting: async({ commit }, formData) => {
        return await axios
            .post(`quote/settings/create`, formData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    // getlist of quote settings
    initQuoteSettings: async({ commit }, payload) => {
        var url = `quote/settings/get?page=${payload}`;
        if(typeof payload.name !== 'undefined')
        url = `quote/settings/get`;
        return axios
            .get(url)
            .then((response) => {
                console.log('init quote settings');
                console.log(payload);
                console.log(response.data);
                if(typeof payload.name !=='undefined'){
                 const data = UTILS.objectFilter(response.data, ['overhead', 'profit', 'tax', 'tax_type']);
                 commit('SET_QUOTE', data);
                 return data;
                }
                commit('SET_QUOTE_PAGINATE', response);
                return response.data;
                
            })
            .catch((error) => {
                console.log(error);
            });
    },
    editQuoteSettings: async({ commit }, setting_id) => {
        return axios
            .get(`quote/settings/${setting_id}/edit`)
            .then((response) => {
                commit("EDIT_QUOTE_SETTINGS", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    //  update 
    async updateQuoteSetting({ commit }, formData) {
        const response = await axios.post(`quote/settings/${formData.id}/update`, formData);
        // commit("ADD_UNIT", response.data);
        return response;
    },
    deleteQuoteSetting: async({ commit }, id) => {
        return await axios
            .delete(`quote/settings/${id}/delete`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },

};
const mutations = {
    SET_QUOTE(state, payload) {
        console.log(payload);
        state.quote = Object.assign({}, payload);
    },
    SET_QUOTE_PAGINATE(state, payload) {
        state.quotePaginate = Object.assign({}, payload);
    },
    SET_QUOTES(state, payload) {
        state.quotes = payload;
    },
    EDIT_QUOTE_SETTINGS(state, payload) {
        state.quote_settings = [payload, ...state.quote_settings];
    },
    REMOVE_QUOTE(state, id) {
        const index = state.quotes.findIndex(quotes => quotes.id == id)
        state.quotes.splice(index, 1)
    },
    // Quote settings
    SET_QUOTE_SETTINGS(state, payload) {
        state.quote_settings = payload;
    },
    EDIT_QUOTE_EXERCISES(state, payload) {
        state.quote_exercises = payload;
    },
    SET_QUOTE_AMOUNT(state, payload) {
        state.quotes_amount = payload;
    },
    SYNC_QUOTE(state, payload) {
        Object.assign(state.quote, payload);
        //console.log(state.quote);
    },
    SET_QUOTE_CATEGORIES_COST(state, payload) {
        state.quoteCategoriesCost = payload;
    },
    setCurrentPage: (state, data) => {
        state.quotes.current_page = data; // set current page on pagination
    },
    setQuoteCurrentPage: (state, data) => {
        state.quotePaginate.current_page = data; // set current page on pagination
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};