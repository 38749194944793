<!--================================
=            SIDEBARDUO            =
=================================-->
<template>
  <!-- app-drawer -->
  <v-navigation-drawer
    class="elevation-0"
    v-model="appConfig.model"
    :clipped="appConfig.clipped"
    :floating="appConfig.floating"
    :expand-on-hover="appConfig.expandOnHover"
    :permanent="appConfig.type === 'permanent'"
    :temporary="appConfig.type === 'temporary'"
    app
    overflow
    :color="appConfig.color">
    <!-- logo app-drawer-left -->
    <div class="logo">
      <img v-if="logo_url" :src="logo_url" />
    </div>
    <div dark class="appDrawer__left left_change">
      <div @click="goGroup(i, icon[0].name)" v-for="(icon, i) in menus" :class="{ active: path.group == icon[0].group }" :key="i" class="appDrawer__leftItem">
        <div slot="activator">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon slot="activator" large color="white" v-on="on">{{ icon[0].action }}</v-icon>
            </template>
            <span v-if="i">{{  i.toUpperCase() }}</span>
          </v-tooltip>
        </div>
        <div>
          <div v-if='i' class="appDrawer__lefttitle caption">{{ i.toUpperCase() }}</div>
        </div>
      </div>
    </div>
    <!-- logo app-drawer-right -->
    <transition name="fade" mode="out-in" :color="appConfig.color" :class="{ active: isActive }">
      <div :color="appConfig.color" nav dense class="appDrawer__right scroll right_change">
        <!-- <div class="appDrawer__logo flex">
          <img src="../../assets/img/logo11.png" />
        </div> -->
        <!-- <v-list>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title style=" text-transform: capitalize;" class="appDrawer__right-header body-1 font-weight-bold">{{ path.group }}</v-list-item-title>
              <v-list-item-subtitle class="body-2">Lorem ipsum dolor sit.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-list class="pa-2 ma-1 appDrawer__right" color="white" nav dense>
          <v-list-item-group v-bind:class="{ active: isActive }">
            <v-list-item class="appDrawer__right-item" @click="goPage(path.group, item.name)"
             v-for="item in menus[path.group]" :key="item.name" :color="item.active ? 'pink' : 'secondary'">
              <v-list-item-content @click="categoryItem(item)">
                <v-list-item-title 
                  class="appDrawer__right-title body-2"
                  :class="{ 'font-weight-bold': path.sub == item.name }"
                >{{ (`${item.title} `) }}  
                </v-list-item-title> 
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </transition>  
  </v-navigation-drawer>
  <!-- app-drawer -->
</template>

<script>

export default {
  name: "sidebar-duo",  
  data() {
    return { 
      logo_url: require(`@/assets/img/logo-top.png`),
      isActive: true,
      name: "asdfadfadfdfdfdfadfd",

      active: true,

      menus: {
        dashboard: [
          {
            name: "dashboard",
            title: "Home",
            action: "fas fa-home",
            color: "pink",
            group: "dashboard"
          },
        ],
        suppliers: [
          {
            name: "suppliers",
            title: "Home",
            action: "fas fa-users",
            color: "pink",
            group: "suppliers"
          },
          {
            name: "add-supplier",
            title: "Add Supplier",
            action: "fas fa-users",
            color: "pink",
            group: "suppliers"
          },
        ],
        subcontractors: [
          {
            name: "subcontractors",
            title: "Home",
            action: "fas fa-chart-bar",
            color: "pink",
            group: "subcontractors"
          },
          {
            name: "add-subcontractor",
            title: "Add Subcontractor",
            action: "fas fa-chart-bar",
            color: "pink",
            group: "subcontractors"
          },
        ],
        workspace: [
          {
            name: "workspace-list",
            title: "Home",
            action: "far fa-building",
            color: "pink",
            group: "workspace"
          },
        ],
        settings: this.$store.getters.menuCategories,
        
      }
    };
  }, 
  methods: { 
    goGroup(x, y) {
      let vm = this;
      //vm.$router.push(`/${x}/${y}`);
      console.log(y);
      if(y.indexOf('/')!==-1)
      vm.$router.push(y);
      else
      vm.$router.push({name:`${y}`});
    },
    goPage(x, y) {
      let vm = this;
      //vm.$router.push(`/${x}/${y}`);
      if(y.indexOf('/')!==-1)
      vm.$router.push(y);
      else
      vm.$router.push({name:`${y}`});
    },
     categoryItem(item){  
       if(item.group!=='settings' && typeof item.id ==='undefined')
       return;
       this.$store.commit('SET_MENU_CATEGORY',item.id);
      } , 
     /* menuCategories(){
        let settings =[
          {
            name: "home-settings",
            title: "Home",
            action: "ti-settings",
            color: "pink",
            group: "settings"
          }
          
        ];
        
        if(this.$store.getters.categories.length){
          //console.log(this.$store.getters.categories);
        for(let cat of this.$store.getters.categories){
           let m = {
            name: `/settings/cat/${cat.slug}`,
            title: `${cat.name}`,
            action: "ti-settings",
            color: "pink",
            group: "settings"
          }; 
         settings.push(m);
        }
        console.log(settings);
        return settings;
        }
        return settings;
      } 
 */    
  },
  computed:{
    /* menuCategories(){
      console.log('menu categories called');
      return this.$store.getters.menuCategories();
    } */
  },
  created(){ 
    this.role = this.$store.getters.auth.user.workspace_role;
    if(this.role == 'Workspace User'){
      this.$delete(this.menus, 'workspace');
      this.$delete(this.menus, 'suppliers');
    };
    this.$store.dispatch('initCategories').then(success=>{ 
      this.menus.settings=this.$store.getters.menuCategories; 
    });
  },
  watch:{
   '$store.state.categories.categories'(){ 
    /* console.log('state.categories value changes 210');*/
    this.menus.settings=this.$store.getters.menuCategories;
   } 
  }
};
</script>
<style scoped>
/* APPDRAWER */

#appDrawer {
  height: 100% !important;
  top: 0px;
  width: 300px;
}

.app-drawer__left {
  text-align: center;

  padding: -20px;
  width: 80px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.app-drawer__logo-left {
  padding-top: -20px;
  margin-top: -20px;
}

.appDrawer__logo img {
  width: 200px;
  margin-top: 20px !important;
  padding-top: 10px;
  margin-bottom: 10px;
}

.apo-drawer__top {
  padding: 3px;
}

.app-drawer__name {
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
}

.appDrawer__left {
  list-style: none;
  text-align: center;
  position: fixed;
  padding-top: 16px;
  width: 80px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.appDrawer__left i {
  font-size: 18px;
}

.apoDrawer__top {
  padding: 3px;
}

.appDrawer__name {
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
}

.appDrawer__lefttitle {
  font-size: 0.71rem;
}

.appDrawer__leftItem {
  position: relative;
  width: 100%;
  color: #fff;
  padding: 10px 0;

  cursor: pointer;
  border-bottom: 1px solid rgba(177, 165, 165, 0.247);
}

.appDrawer__leftItem i {
  font-size: 24px !important;
}

.appDrawer__leftItem.active {
  color: #fff;
  border-left: 1px solid #f9f9f9;
}

.appDrawer__leftname {
  text-align: center;
  font-size: 0.5rem;
}

.appDrawer__appName img {
  padding: 15px 0 25px 0px;
  margin-top: 10px;
  margin-left: 10px;
  width: 160px;
}
.title-logo {
  margin-left: 50px;
  margin-top: -40px;
}

.appDrawer__lefttitle {
  text-align: center !important;
}

.appDrawer__leftItem.active:after {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  margin-left: 35px;
  background: #fff;
  transform: rotate(45deg);
}

.appDrawer__right .v-item-group{
      text-align: left;
    overflow-y: auto;
    margin-top: 5px;
    width: 100%;
    /* margin-left: 148px; */
    background: #fff;
    cursor: pointer;
    height: 90vh !important;
    position: relative;
}

.appDrawer__right-header {
  background: #fff;
  color: #333;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
}

.appDrawer__right-title {
  background: #fff;
  color: #333;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1.1rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.child-view {
  position: absolute;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* ///////// */
.v-application .white--text{
  color:#fff!important;
}
.v-application .caption{
  color:#fff;
  font-size: 13px!important;
  margin-left: 0px;
}
nav{
  background: #fff;
}
.appDrawer__leftItem.active:after {
    width: 13px;
    height: 13px;
    right: -6px;
    bottom: 26px;
}
.appDrawer__leftItem.active{
    color: #fff;
    border-left: 3px solid #f9f9f9;
}
.logo{
  padding: 30px 10px;
  background: #fff;
}
.logo img{
  width:200px;
  height: auto;
}
.appDrawer__left.left_change{
  width: 105px;
    padding-top: 0px;
}
.appDrawer__right.right_change{
  margin-left: 101px;
}
.v-list--nav .v-list-item {
    padding: 0 0px 0 5px;
}
.v-application .pa-2 {
    padding: 0px 0px 2px 2px!important;
}
.appDrawer__left{
    width: 150px;
    padding-top: 0px;
}
.v-application .ma-1 {
    margin: 0px 0px 0px!important;
}
.v-list--nav .v-list-item, .v-list--nav .v-list-item:before{
  border-radius: 0px;
    border-width: 1px;
    border-color: #d9d9d9 transparent transparent transparent;
    border-style: solid;
}
.appDrawer__right-item{
    margin-bottom: 0px!important;
}
.v-application .pa-2[data-v-3dc6de06] {
    padding: 0px 0px 0px !important;
}
.theme--light.v-app-bar.v-toolbar.v-sheet{
    background-color: #fff!important;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 50%)!important;
}
.theme--light.v-list-item:hover::before {
    opacity: 0.4;
    border-radius: 0px;
}
.v-application .body-2 {
    font-size: 11px !important;
    text-transform: uppercase;
    letter-spacing: normal !important;
}
.v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 32px;
}
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    opacity: 0.3;
}


.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #ffb3f6 !important;
}

</style>
<!--====  End of SIDEBARDUO  ====-->