import axios from "axios";
const state = {
    subcontractors: [],
    contractor: {},
};
const getters = {
    subcontractors: (state) => {
        console.log(state.subcontractors);
        return state.subcontractors;
    },
};

const actions = {
    initSubcontractors: async({ commit }, pageNumber=false) => {
        return axios
            .get(`subcontractors?page=${pageNumber}`)
            .then((response) => {
                commit("SET_SUBCONTRACTORS", response.data.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    initSubcontractors2: async({ commit }, payload) => {
        let pageNumber = '';
        let srch = '';
        if(payload && payload.pageNumber) pageNumber = payload.pageNumber
        if(payload && payload.srch) srch = payload.srch
            
        return axios
            .get(`subcontractors?page=${pageNumber}&q=${srch}`)
            .then((response) => {
                commit("SET_SUBCONTRACTORS", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    initSubcontractorsSearch: async({ commit }, searchVal ,) => {
        return axios
            .get(`subcontractors?q=${searchVal}`)
            .then((response) => {
               // console.log(response.data);
                commit("SET_SUBCONTRACTORS", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    },

    async editSubcontractor({ commit }, id) {
        return axios
            .get(`subcontractor/${id}/edit`)
            .then((response) => {
                commit("EDIT_SUBCONTRACTOR", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateSubcontractor: async({ commit }, formData) => {
        await axios
            .post(`subcontractor/${formData.id}/update`, formData)
            .then((response) => {
                console.log(response.data);
                commit("UPDATE_SUBCONTRACTOR", formData.id);
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    addSubcontractor: async({ commit }, formData) => {
        return await axios
            .post(`subcontractor/create`, formData)
            .then((response) => {
                commit("ADD_SUBCONTRACTOR", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error.response);
                return error.response;
            });
    },
    deleteSubcontractor: async({ commit }, id) => {
        return await axios
            .delete(`subcontractor/${id}/delete`)
            .then((response) => {
                // commit("REMOVE_SUBCONTRACTOR", id);
                return response;
            })
            .catch((error) => {
                console.log(error.response);
                return error.response;
            });
    },
};
const mutations = {
    SET_SUBCONTRACTORS(state, payload) {
        state.subcontractors = payload;
    },
    // ADD_SUBCONTRACTOR: (state, contractor) => {
    //     state.subcontractors.unshift(contractor);
    // },
    // EDIT_SUBCONTRACTOR: (state, contractor) => {
    //     state.contractor = contractor;
    // },
    // UPDATE_SUBCONTRACTOR: (state, contractor) => {
    //     state.contractor = contractor;
    // },
    REMOVE_SUBCONTRACTOR: (state, contractor) => (
        state.subcontractors.filter((contractor) => contractor.id !== id),
        state.subcontractors.splice((contractor) => contractor.id, 1)
    ),
    setCurrentPage: (state, data) => {
        state.subcontractors.current_page = data; // set current page on pagination
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};