<template lang="">
  <v-row>
    <div class="col-md-12">
      <div class="status_table"> 
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="QuoteSettingData"
            :headers="headers"
            :items="itemsWithSno"
            :search="search"
            :loading = "loading"
            loading-text="Loading... Please wait"
            :items-per-page="15"
            hide-default-footer>
            <template v-slot:item.actions="{ item }">
              <button
                v-b-modal.modal-center
                v-b-tooltip.hover
                title="Edit"
                @click="editRow(item.id)">
                <v-icon small>mdi-pencil</v-icon>
              </button>
              <button 
                v-b-tooltip.hover
                title="Delete"
                @click="deleteRow(item.id)" class="text-danger">
                <v-icon small> mdi-delete</v-icon>
              </button>
            </template>
          </v-data-table>
          <v-pagination
            v-model="currentPage"
            :length="lastPage"
            total-visible="10">
          </v-pagination>
        </v-card>
      </div>
    </div>
    <div class="col-md-12">
      <div class="configuration_footer">
        <!-- Actual search box -->
        <v-row>
          <div class="col-md-5">
            <b-btn 
            @mouseover="reset_form_data"
              class="btn-info category_select" 
              v-b-modal.modal-center
              ><i class="fa fa-plus-circle"></i> Add Settings</b-btn
            >
            <b-modal
              v-model="is_open"
              id="modal-center"
              hide-footer
              size="lg"
              centered
              :title="modalHeaderName"
            >
              <v-row>
                <div class="col-md-4">
                  <label for="">Name</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    v-model="formData.name"
                    class="form-control"
                    name="name"
                  />
                  <div v-if="submitted && !$v.formData.name.required" class="invalid-feedback">Name is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">Overhead (%) </label>
                </div>
                <div class="col-md-8">
                  <input
                    type="number"
                    v-model="formData.overhead"
                    class="form-control"
                    name="overhead"
                  />
                  <div v-if="submitted && !$v.formData.overhead.required" class="invalid-feedback">Overhead is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">Profit (%)</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="number"
                    v-model="formData.profit"
                    class="form-control"
                    name="profit"
                  />
                  <div v-if="submitted && !$v.formData.profit.required" class="invalid-feedback">Profit is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">GST (%)</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="number"
                    v-model="formData.tax"
                    class="form-control"
                    name="tax"
                  />
                 <div v-if="submitted && !$v.formData.tax.required" class="invalid-feedback">GST is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">Tax Type</label>
                </div>
                <div class="col-md-8">
                   <v-radio-group v-model="formData.tax_type" row>
                    <v-radio label="VAT" v-bind:checked="formData.tax_type == 'vat'" value="vat"></v-radio>
                    <v-radio label="GST" v-bind:checked="formData.tax_type == 'gst'" value="gst" ></v-radio>
                  </v-radio-group>
                  <div v-if="submitted && !$v.formData.tax_type.required" class="invalid-feedback">Tax type is required</div>
                </div>
              </v-row>
              <div class="cancel_save_btn text-right">
                <v-btn @click="updateForm()" rounded color="primary" v-if="quote_settings.id>0">Update</v-btn>
                <v-btn @click="submitForm()" rounded color="primary" v-else>Save</v-btn>
              </div>
            </b-modal>
          </div>
          <div class="col-md-2"></div>
        </v-row>
      </div>
    </div>
  </v-row>
</template>
<script>
import Vue from "vue";
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators';
import Multiselect from "vue-multiselect";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
Vue.use(VueGoodTable);
export default {
  name: "configuration",
  components: {
    Multiselect,
    VueGoodTable,
  },
  data() {
    return {
      quote_settings: {}, 
      formData: {},  
      tax_type: ["Gst", "Vat"],
      modalHeaderName:"Add Quote Settings",
      loading:false,
      is_open:false,
      submitted:false,
      search:"",
      headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Overhead",
          value: "overhead",
        },
        {
          text: "Profit",
          value: "profit",
        },
        {
          text: "Tax Type",
          value: "tax_type",
        },
        {
          text: "Action",
          value: "actions",
        },
      ],
      QuoteSettingData: [],
    };
  },
  validations: {
     formData: {
        name: { required },
        overhead:{ required },
        profit: { required },
        tax: { required },
        tax_type: { required },
      }
    },
  watch: {
    currentPage( newVal, oldVal){
      this.paginatePage(newVal);
    },
     deep: true
  },

  computed:{
    itemsWithSno() {
      return this.QuoteSettingData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        console.log(this.$store.getters.quotePaginate.current_page);
        return this.$store.getters.quotePaginate.current_page; // get current page
      },
      set(value){
          this.$store.commit('setQuoteCurrentPage', value); // set page value
        }
    },
    lastPage:{
      get(){
          return this.$store.getters.quotePaginate.last_page; // get last page
      }
    }
  },
  methods: {
    paginatePage(pageNumber){
      this.loading = true;
        this.$store.dispatch("initQuoteSettings", pageNumber).then((response) => {
          console.log(response);
            this.QuoteSettingData = response.data;
            if(response){
              this.loading = false;
            }
        });
    },
    init() {
      this.$store.dispatch("initQuoteSettings",1).then((data) => {
      this.QuoteSettingData = data.data
      });
    },
    reset_form_data() {
      this.quote_settings = {};
      this.formData = {};
      this.submitted = false;
      this.modalHeaderName ="Add Quote Settings"; 
    }, 
    submitForm: function () { 
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.$store.dispatch("addQuoteSetting", this.formData).then((response) => {
        console.log(response);
        if(response.status == 422){
            this.$fire({
             title: "The name has already been taken.",
             type: "warning",
             timer: 4000
           });
          }
          if(response.data.status == true){
            this.$fire({
             title: "Quote Settings Added Successfully!",
             type: "success",
             timer: 4000
           });
          this.init();
          this.is_open = false;
          }
      });
    },
    updateForm: function () {  
      this.$store.dispatch("updateQuoteSetting", this.formData).then((data) => {
        console.log(data);
        this.$fire({
            title: "Quote Settings Updated Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
          this.init();
          this.is_open = false;
      }); 
    },
    editRow: function (id) { 
      this.modalHeaderName ="Update Quote Settings";
      this.$store.dispatch("editQuoteSettings", id).then((data) => {
        this.quote_settings = data;
        this.formData = data;
      });
    },
    deleteRow: function (id) { 
      this.$confirm("","Are you sure?","question").then(() => {
      this.$store.dispatch("deleteQuoteSetting", id).then((success) => {
        this.$fire({
            title: "Quote Settings Delete Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
        this.init();
        });
      }); 
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("initQuoteSettings",1).then((data) => {
      console.log(data.data)
      this.QuoteSettingData = data.data
      if(data){
        this.loading = false;
      }
    });
  }, 
};
</script>
<style scoped>
  .invalid-feedback {
    text-align: start;
}
</style>
