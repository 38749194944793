import { categories } from "../../data/categories";
import axios from "axios";
const state = {
    categories: [],
    category: [],
    categoriesList: [],
    selectedMenuCategoryId: 0,
    globalCategories: [],
};
const getters = {
    globalCategories: (state) => {
        return state.globalCategories;
    },
    categories: (state) => {
        return state.categories;
    },
    category: (state) => {
        return state.category;
    },
    selectedMenuCategoryId: (state) => {
        return state.selectedMenuCategoryId;
    },
    menuCategories(state) {
        console.log("menu categories");
        let settings = [{
            name: "home-settings",
            title: "Home",
            action: "fa fa-cog",
            color: "pink",
            group: "settings",
        }, ];
        console.log(state.categories);
        if (state.categories.length) {
            //console.log(this.$store.getters.categories);
            for (let cat of state.categories) {
                let m = {
                    name: `/settings/cat/${cat.slug}`,
                    title: `${cat.name}`,
                    action: "fa fa-cog",
                    color: "pink",
                    group: "settings",
                    id: `${cat.id}`
                };
                settings.push(m);
            }
            console.log(settings);
            return settings;
        }
        return settings;
    },
    listCategories(state) {
        let list = [];
        if (state.categories.length) {
            for (let cat of state.categories) {
                let m = {
                    name: `${cat.name}`,
                };
                list.push(m);
            }
            return list;
        }
    }
};
const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    ADD_CATEGORY(state, data) {
        state.categories.push(data);
    },
    EDIT_CATEGORY(state, data) {
        state.category = data;
    },
    UPDATE_CATEGORY(state, data) {
        console.log('Line Number 67');
        console.log(data);
        //let categories = state.categories;
        state.categories = state.categories.map((category) => {
            if (category.id == data.id) {
                console.log(category);
                return data;
            } else {
                return category;
            }

        });
    },
    SET_CATEGORIES_LIST(state, listcategoies) {
        state.categoriesList = listcategoies;
    },
    SET_MENU_CATEGORY(state, id) {
        console.log('SET_MENU_CATEGORY' + id);
        state.selectedMenuCategoryId = id;
    },
    SET_GLOBAL_CATEGORY(state, globalCategories) {
        state.globalCategories = globalCategories;
    }
};

const actions = {
    async getGlobalCategories({ commit }) {
        const response = axios.get("/category/global")
            .then((response) => {
                console.log(Object.values(response.data))
                    // console.log(response.data);
                commit("SET_GLOBAL_CATEGORY", Object.values(response.data));
            });
    },
    importedCategory: async({ commit }, formData) => {
        return await axios
            .post('/category/import', formData)
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    initCategories: async({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            dispatch('fetchCategories').then((response) => {

                commit("SET_CATEGORIES", response.data);
                resolve(response.data);
            }).catch((error) => {
                console.log(error.response)
                reject(error);
            });
        });
        //dispatch()
        /*  return axios
            .get(`categories`)
            .then((response) => {
                console.log("init Categories 21");
                console.log(response.data);
                commit("SET_CATEGORIES", response.data.data);
                //return true;
            })
            .catch((error) => {
                console.log(error);
            }); */
    },
    addCategory: async({ commit }, formData) => {
        return axios
            .post(`category/create`, formData)
            .then((response) => {
                console.log(response);
                commit("ADD_CATEGORY", response.data);
                return true;
            })
            .catch((error) => {
                console.log(error);
            });

    },
    fetchCategories: async({ commit }, payload) => {
        let url = 'categories';
        if (typeof payload !== 'undefined' && payload.q !== 'undefined')
            url += '?q=' + payload.q;
        return new Promise((resolve, reject) => {
            axios
                .get(url)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error.response)
                    reject(error);
                })
        });

    },
    async editCategory({ commit }, id) {
        return axios
            .get(`category/${id}/edit`)
            .then((response) => {
                commit("EDIT_CATEGORY", response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response;
            });
    },
    async deleteCategory({ commit }, id) {
        return axios.delete(`category/${id}/delete`)
            .then((response) => {
                // commit("EDIT_CATEGORY", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    updateCategory: async({ commit }, formData) => {
        return axios
            .post(`category/${formData.id}/update`, formData)
            .then((response) => {
                commit('EDIT_CATEGORY', response.data);
                commit('UPDATE_CATEGORY', response.data);
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    },
    getCategories: async({ commit }) => {
        return await axios
            .get(`category/get-list`)
            .then((response) => {
                commit("SET_CATEGORIES_LIST", response.data);
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }

};



export default {
    state,
    mutations,
    actions,
    getters,
};