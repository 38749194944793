import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'

Vue.use(colors);
Vue.use(Vuetify)
    // = 8 * 2
export default new Vuetify({
    theme: {
        themes: {
            light: {

                primary: '#672178',
                secondary: '#ff00e1',
                error: '#ff0081',
                accent: '#82B1FF',
                info: '#080DCC',
                success: '#4CAF50',
                warning: '#FB8C00',

            },
            dark: {
                base: '#280a46',
                primary: '#8000ff',
                secondary: '#ff00e1',
                error: '#ff0081',
                accent: '#82B1FF',
                info: '#080DCC',
                success: '#4CAF50',
                warning: '#FB8C00',
                background: '#280a46', // If not using lighten/darken, use base to return hex
            }



        },
        dark: false
    },
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
})