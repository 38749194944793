<template lang="">
  <div class="col-md-12">
    <div class="top_bar">
      <h2><i class="fas fa-hammer"></i> Subcontractor</h2>
    </div>
    <div class="home_box1">
      <form method="post" @submit.prevent="submitForm()"> 
        <v-row>
          <div class="col-md-12">
            <h3><span v-if="this.$route.params.id">Edit</span
                  ><span v-else>New</span> Subcontractor</h3>
            <v-row>
              <div class="col-md-6">
                <label class="form-control-label">Name*</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="Name of Subcontractor"
                  v-model.trim="$v.formData.name.$model"
                  @blur="$v.formData.name.$touch()"  
                  :class="{invalid: $v.formData.name.$error}"                    
                />
                <div
                      v-if="$v.formData.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.name.required"
                        >Name is Required</span
                      >
                      <span v-if="!$v.formData.name.minLength"
                        >Minimum 5 Characters Required</span
                      >
                    </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Email*</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model.trim="$v.formData.email.$model"
                  @blur="$v.formData.email.$touch()"
                  placeholder="Email"
                  :class="{invalid: $v.formData.email.$error}"
                  />
                  <div
                      v-if="$v.formData.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.email.required"
                        >Email is Required</span
                      >
                      <span v-if="!$v.formData.email.email"
                        >Email is Invalid</span
                      >
                    </div>
                </div>
               
              <div class="col-md-6">
                <label class="form-control-label">Phone*</label>
                <input
                  type="number"
                  class="form-control w-100"
                  name="phone"
                  v-model="formData.phone"
                  @blur="$v.formData.phone.$touch()"
                  placeholder="Phone"
                  :class="{invalid: $v.formData.phone.$error}"
                />
                <div
                      v-if="$v.formData.phone.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.phone.required"
                        >Phone is Required</span
                      >
                      <span v-if="!$v.formData.phone.minLength"
                        >Minimum 8 Numbers Required</span
                      >
                    </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Company*</label>
                <input
                  type="text"
                  class="form-control"
                  name="company"
                   v-model="formData.company"
                  placeholder="Company Name"
                  @blur="$v.formData.company.$touch()"
                  :class="{invalid: $v.formData.company.$error}"
                />
                <div
                      v-if="$v.formData.company.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.company.required"
                        >Company is Required</span
                      >
                      <span v-if="!$v.formData.company.minLength"
                        >Minimum 5 Characters Required</span
                      >
                    </div>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Description*</label>
                <b-form-textarea
                  id="description"
                  v-model="formData.description" 
                  placeholder=""
                  rows="3"
                  @blur="$v.formData.description.$touch()"
                  :class="{invalid: $v.formData.description.$error}"
                ></b-form-textarea>
                <div
                      v-if="$v.formData.description.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.description.required"
                        >Description is Required</span
                      >
                      <span v-if="!$v.formData.description.minLength"
                        >Minimum 8 Characters Required</span
                      >
                </div>
              </div>
            </v-row>
          </div>
          <div class="col-md-6">
            <h3>Categories</h3>
            <v-row>
              <div class="col-md-12">
                <label class="form-control-label"
                  ><i class="fa fa-plus-circle"></i> Add Categories*</label
                >
                <!-- <b-button class="btn btn-success"><i class="fa fa-plus-circle"></i> Add Categories</b-button> -->
                <multiselect
                  v-model="cats"
                  :options="categories"
                  :multiple="true"
                  :taggable="true"
                  :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Category" label="name" 
                  track-by="name"
                  @open="$v.cats.$touch()"
                  :class="{invalid: $v.cats.$error}" 
                ></multiselect>
                <div
                      v-if="$v.cats.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.cats.required"
                        >Category is Required</span
                      >
                      <span v-if="!$v.cats.minLength"
                        >Select Atleast One Category</span
                      >
                    </div>
              </div>
            </v-row>
          </div>
          <div class="col-md-12">
            <div class="col-md-12 addsupplier_btn">
              <b-button class="btn btn-danger" @click="$router.back()">Cancel</b-button>
              <b-button type="submit" class="btn btn-success" :disabled="$v.formData.$invalid || $v.formData.$error || $v.cats.$invalid"><span v-if="this.$route.params.id">Update</span
                    ><span v-else>Save</span></b-button>
            </div>
          </div>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import Multiselect from "vue-multiselect";
import { required, email, minLength } from "vuelidate/lib/validators";
/* import { validationMixin } from "vuelidate"; */
import { mapGetters } from "vuex";
export default {
  name: "subcontractorForm",
  components: {
    Multiselect
  },
  data() {
    return {      
      is_submitted: false, 
      formData: {},
      cats: [],
/*       app_date: new Date().toISOString().slice(0, 10), */
    };
  },
  computed:{
    ...mapGetters([
      'categories'
    ]),
  }, 
validations: {
    formData: {
      name: { required,minLength: minLength(5) },
      company: {required,minLength: minLength(5)},
      email: { required, email },
      phone: { required, minLength: minLength(8) },
      description: {required, minLength: minLength(8)}, 
    },
    cats:{required,minLength: minLength(1)}
  },
  methods: {   
    submitForm() {
      if(this.cats.length==0){
        alert('Select Categories');
        return;
      }
      this.formData.categories = [];
      this.cats.map((cat)=>{
this.formData.categories.push(cat.id);
      });
      if (this.$route.params.id && typeof this.formData.id !=='undefined') { 
        this.$store.dispatch("updateSubcontractor", this.formData).then((success) => { 
             this.$fire({
              title: "Subcontractor Successfully Updated!",
              text: "",
              type: "success",
              timer: 4000
            });
            router.push("/subcontractors/home");
        });
      } else {  
        this.$store.dispatch("addSubcontractor", this.formData).then((response) => {
          console.log(response);
          if (response.status == 200) {
             this.$fire({
              title: "Subcontractor Added Successfully!",
              text: "",
              type: "success",
              timer: 4000
            });
            router.push("/subcontractors/home");
            this.resetForm();
          } 
          else if (response.status == 422){
            this.$fire({
              title: response.data.company[0],
              text: "",
              type: "warning",
              timer: 3000
            });
          }
        });
      }
    }, 
    fetchSubContractor(id){
    this.$store.dispatch("editSubcontractor",id).then((data)=>{
      this.cats = this.categories.filter((cat,index)=>{
          if(data.categories.indexOf(cat.id)!==-1)
          return cat;

        })
     this.formData = data;
    });
    },
    resetForm(){
      this.formData = {name:'',email:'',phone:'',company:'',description:''};
        this.cats = [];
    }
  },
  created() { 
        if(this.categories.length==0){
      console.log('subcontractor  add 166');
    this.$store.dispatch("initCategories").then((data) => {
        
    });
    }
    if (this.$route.params.id) {
      this.fetchSubContractor(this.$route.params.id);
     
    }
    else{
      this.resetForm();
    }
  },
  watch:{
    $route(to,from){
      if(to.params.id){
        this.fetchSubContractor(to.params.id);
      }
      else{
        this.resetForm();
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.addsupplier_btn button {
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  padding: 10px 20px;
}
.addsupplier_btn button:last-child {
  margin-left: 20px;
}
.btn-danger {
  background: #f44336;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.v-application ul,
.v-application ol {
  padding-left: 0px;
}
.multiselect__tags {
  min-height: 40px;
  border-radius: 0px;
}
input.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
textarea.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.multiselect.invalid .multiselect__tags {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>
