<!--====================================
=            FORGOTPASSWORD            =
=====================================-->
<template>
<v-app light>
    <section id="welcome">
      <div class="container">
        <form action="" method="post" @submit.prevent="handlesubmit">
          <v-row class="main_login">
            <div class="col-md-6 col-12 m-auto">
              <img
                width="100%"
                src="../../assets/img/bg-login.png"
                alt="Vuetify.js "
              />
            </div>
            <div class="col-md-5 col-12 m-auto">
              <!-- Log In page -->
                      <div class="account-body accountbg">
                        <!-- Log In page -->
                        <div class="row">
                          <div class="col-12 align-self-center">
                            <div class="auth-page">
                              <div class="card auth-card shadow-lg">
                                <div class="card-body">
                                  <div class="px-3">
                                    <!--end auth-logo-box-->
                                    <div class="text-center auth-logo-text">
                                      <h4 class="mt-0 mb-3 mt-5">Reset Password</h4>
                                      <p
                                        class="text-muted mb-0"
                                      >Enter your new Password!</p>
                                    </div>
                                    <!--end auth-logo-text-->
                                    <v-card-text>
                                      <v-form>
                                        <v-text-field
                                          v-model="formForm.email"
                                          label="Email"
                                          name="Email"
                                          prepend-icon="person"
                                          type="text"
                                        />
                                        <v-text-field
                                          v-model="formForm.password"
                                          label="Password"
                                          name="Password"
                                          prepend-icon="lock"
                                          type="password"
                                        />
                                         <v-text-field
                                          v-model="formForm.password_confirmation"
                                          label="Confirm Password"
                                          name="confirm_password"
                                          prepend-icon="lock"
                                          type="password"
                                        />
                                      </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer />
                                      <v-btn type="submit" color="primary">Update</v-btn>
                                    </v-card-actions>
                                    <!--end form-->
                                  </div>
                                  <!--end /div-->
                                </div>
                                <!--end card-body-->
                              </div>
                              <!--end card-->
                            </div>
                            <!--end auth-page-->
                          </div>
                          <!--end col-->
                        </div>
                      </div>
                <!--end auth-page-->
              </div>
          </v-row>
        </form>
      </div>
    </section>
  </v-app>
</template>
<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    loading: false,
    drawer: null,
    formForm: {
      email:"",
      password: "",
      password_confirmation: "",
    }
  }),

  methods: {
    handlesubmit(e){
      this.formForm['token'] = this.$route.query.token;
      console.log(this.formForm);
        this.$store.dispatch('resetPassword',this.formForm).then((response)=>{
          console.log(response);
          if(response.data.status == true){
            this.$fire({
              title: "Password Reset Successfully.",
              text: "",
              type: "success",
              timer: 4000
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 500);
          }
          else if(response.data.status == false){
            this.$fire({
              title: "Something went wrong!",
              text: "",
              type: "warning",
              timer: 4000
            });
          }
          else if(response.status == 422){
            this.$fire({
              title: "The password confirmation does not match.",
              text: "",
              type: "warning",
              timer: 4000
            });
          }
        });
    }
  }
};
</script>


<!--====  End of FORGOTPASSWORD  ====-->