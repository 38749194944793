<template lang="">
  <!-- <v-container> -->
    <!-- HEADER -->
   
      <div class="col-md-12">
        <div class="top_bar">
          <h2><i class="fas fa-users"></i> SUPPLIER</h2>
        </div>
        <div class="home_box1">
          <form method="post" @submit.prevent="submitForm()">
            <input
              type="hidden"
              v-if="this.$route.params.id"
              name="id"
              readonly
              v-model="formData.id"
            />
            <v-row>
              <div class="col-md-12">
                <h3>
                  <span v-if="this.$route.params.id">Edit</span
                  ><span v-else>New</span> Supplier
                </h3>
                <v-row>
                  <div class="col-md-4">
                    <label class="form-control-label">Name*</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Name of Supplier"
                      v-model.trim="$v.formData.name.$model"
                      @blur="$v.formData.name.$touch()"  
                      :class="{invalid: $v.formData.name.$error}"                    
                    />
                    <div
                      v-if="$v.formData.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.name.required"
                        >Name is Required</span
                      >
                      <span v-if="!$v.formData.name.minLength"
                        >Minimum 5 Characters Required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label">Email*</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      v-model.trim="$v.formData.email.$model"
                      @blur="$v.formData.email.$touch()"
                      placeholder="Email"
                      :class="{invalid: $v.formData.email.$error}"
                    />
                    <div
                      v-if="$v.formData.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.email.required"
                        >Email is Required</span
                      >
                      <span v-if="!$v.formData.email.email"
                        >Email is Invalid</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label">Phone*</label>
                    <input
                      type="number"
                      class="form-control w-100"
                      name="phone"
                      v-model="formData.phone"
                      @blur="$v.formData.phone.$touch()"
                      placeholder="Phone"
                      :class="{invalid: $v.formData.phone.$error}"
                    />
                    <div
                      v-if="$v.formData.phone.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.phone.required"
                        >Phone is Required</span
                      >
                      <span v-if="!$v.formData.phone.minLength"
                        >Minimum 8 Numbers Required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label">Local council</label>
                    <input
                      type="text"
                      class="form-control"
                      name="council"
                      v-model="formData.council"
                      placeholder="Local Council"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label">Company Name*</label>
                    <input
                      type="text"
                      class="form-control"
                      name="company"
                      v-model="formData.company"
                      placeholder="Company Name"
                      @blur="$v.formData.company.$touch()"
                      :class="{invalid: $v.formData.company.$error}"
                    />
                                        <div
                      v-if="$v.formData.company.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.company.required"
                        >Company is Required</span
                      >
                      <span v-if="!$v.formData.company.minLength"
                        >Minimum 5 Characters Required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label"
                      ><i class="fa fa-plus-circle"></i> Add Categories*</label
                    >
                    <!-- label="name" -->
                    <multiselect
                      v-model.trim="cats"
                      :options="categories"
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Category" label="name" 
                      track-by="name"
                      @open="$v.cats.$touch()"
                      :class="{invalid: $v.cats.$error}"
                    ></multiselect>
                    <div
                      v-if="$v.cats.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.cats.required"
                        >Category is Required</span
                      >
                      <span v-if="!$v.cats.minLength"
                        >Select Atleast One Category</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Address*</label>
                    <b-form-textarea
                      id="textarea"
                      name="address"
                      v-model="formData.address"
                      placeholder=""
                      rows="3"
                      @blur="$v.formData.address.$touch()"
                      :class="{invalid: $v.formData.address.$error}"
                    ></b-form-textarea>
                    <div
                      v-if="$v.formData.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.address.required"
                        >Address is Required</span
                      >
                      <span v-if="!$v.formData.address.minLength"
                        >Minimum 8 Characters Required</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Description*</label>
                    <b-form-textarea
                      id="description"
                      name="description"
                      v-model="formData.description"
                      placeholder=""
                      rows="3"
                      @blur="$v.formData.description.$touch()"
                      :class="{invalid: $v.formData.description.$error}"
                    ></b-form-textarea>
                    <div
                      v-if="$v.formData.description.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.formData.description.required"
                        >Description is Required</span
                      >
                      <span v-if="!$v.formData.description.minLength"
                        >Minimum 8 Characters Required</span
                      >
                    </div>
                  </div>
                </v-row>
              </div>

              <div class="col-md-12">
                <div class="col-md-12 addsupplier_btn">
                  <b-button class="btn btn-danger" @click="$router.back()">Cancel</b-button>
                  <b-button type="submit" class="btn btn-success"
                   :disabled="$v.formData.$invalid || $v.formData.$error || $v.cats.$invalid" ><span v-if="this.$route.params.id">Update</span
                    ><span v-else>Save</span></b-button
                  >
                </div>
              </div>
            </v-row>
          </form>
        </div>
      </div>
    
    <!-- HEADER END-->
  <!-- </v-container> -->
</template>

<script>
import router from "../../router";
import Multiselect from "vue-multiselect";
import { required, email, minLength } from "vuelidate/lib/validators";
/* import { validationMixin } from "vuelidate"; */
import { mapGetters } from "vuex";
export default {
  /* mixins: [validationMixin], */
  name: "supplierForm",
  components: {
    Multiselect
  },
  data() {
    return {
      is_submitted: false,
      formData: {},
      cats: [],
      // set: true,
      //app_date: new Date().toISOString().slice(0, 10),
    };
  },
  computed:{
    ...mapGetters([
      'categories'
    ]),
  },
  validations: {
    formData: {
      name: { required,minLength: minLength(5) },
      company: {required,minLength: minLength(5)},
      email: { required, email },
      phone: { required, minLength: minLength(8) },
      address: {required, minLength: minLength(8)},
      description: {required, minLength: minLength(8)}, 
    },
    cats:{required,minLength: minLength(1)}
  },
  methods: {
    submitForm() {
       if(this.cats.length==0){
        alert('Select Categories');
        return;
      }
      this.formData.categories = [];
      this.cats.map((cat)=>{
this.formData.categories.push(cat.id);
      });
      if (this.$route.params.id && typeof this.formData.id !=='undefined') {
        this.$store.dispatch("updateSupplier", this.formData).then((response) => {
          console.log(response);
           if(response.status == 200){
              this.$fire({
              title: "Supplier Updated Successfully!",
              text: "",
              type: "success",
              timer: 4000
            });
            router.push("/suppliers/home"); 
           }
          });
      } else {
        this.$store.dispatch("addSupplier", this.formData).then((response) => {
          console.log(response);
          if(response.status == 422){
              this.$fire({
              title: response.data.company[0],
              text: "",
              type: "warning",
              timer: 4000
            });
          }
          else if(response.status == 201){
           this.$fire({
            title: "Supplier Added Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
          router.push("/suppliers/home");
          this.resetForm();
          }
        });
      }
    },
    fetchSupplier(id) {
      this.$store.dispatch("editSupplier", id).then((data) => {
          this.cats = this.categories.filter((cat,index)=>{
          if(data.categories.indexOf(cat.id)!==-1)
          return cat;

        })
        this.formData = data;
      });
    },
    resetForm(){
      this.formData = {name:'',email:'',phone:'',company:'',address:'',description:''};
        this.cats = [];
    }
  },
  created() {
    /* this.categories = this.$store.getters.categories; */
    if(this.categories.length==0){
      console.log('supplier add 200');
    this.$store.dispatch("initCategories").then((data) => {
        
    });
    }
    if (this.$route.params.id) {
      this.fetchSupplier(this.$route.params.id);
    }
    else{
      this.resetForm();
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.id) {
        this.fetchSupplier(to.params.id);
      }
      else{
        this.resetForm();
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.addsupplier_btn button {
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  padding: 10px 20px;
}
.addsupplier_btn button:last-child {
  margin-left: 20px;
}
.btn-danger {
  background: #f44336;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
.multiselect__tags {
  min-height: 40px;
  border-radius: 0px;
}
input.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
textarea.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.multiselect.invalid .multiselect__tags {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>
