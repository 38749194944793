<template lang="">
  <div class="col-md-12">
    <div class="home_box1">
      <h3>View Subcontractor</h3>
      <v-card>
          <v-card-title>
            <v-text-field 
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchIt"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="subcontractorData"
            :headers="headers"
            :items="itemsWithSno"
            :search="search"
            :loading = "loading"
            loading-text="Loading... Please wait"
            :items-per-page="15"
            hide-default-footer>
            <template v-slot:item.actions="{ item }">
               <router-link 
                  v-b-tooltip.hover
                  title="Edit" 
                  :to="{ name: 'edit-subcontractor', params: { id: item.id } }">
                  <v-icon small>mdi-pencil</v-icon>
                </router-link>
              <button 
                v-b-tooltip.hover
                title="Delete"
                @click="deleteSubcontractor(item.id)" class="text-danger">
                <v-icon small> mdi-delete</v-icon>
              </button>
            </template>
          </v-data-table>
          <v-pagination
          v-model="currentPage"
          :length="lastPage"
          total-visible="15">
        </v-pagination>
        </v-card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import "vue-good-table/dist/vue-good-table.css";
// import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
// Vue.use(VueGoodTable);
export default {
  name: "subcontractor",
  components: {
    // VueGoodTable,
  },
  data() {
    return {
      loading:false,
      search: '',
        headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Company",
          value: "company",
        },

        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Action",
          value: "actions",
        },
        ],
        subcontractorData: [],
    };
  },
    watch:{
      currentPage( newValu, oldVal){
          this.paginatePage(newValu);
      }
    },
    computed:{
    itemsWithSno() {
      return this.subcontractorData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        return this.$store.getters.subcontractors.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
      }
    },
    lastPage:{
        get(){
            return this.$store.getters.subcontractors.last_page; // get last page
        }
    }
  },
  methods: {
    paginatePage(pageNumber){
      this.loading = true;
      let srch = this.search
        let payload = {'pageNumber': pageNumber, 'srch': srch}
        this.$store.dispatch("initSubcontractors2", payload).then((response) => {
            this.subcontractorData = response.data.data;
            if(response){
              this.loading = false;
            }
        });
    },
    init() {
      this.$store.dispatch("initSubcontractors2").then((response) => { 
        this.subcontractorData = response.data.data;
      });
    },searchIt(e){
        if(e.length > 0){
            this.$store.dispatch("initSubcontractorsSearch", e).then((succes) => {
                this.subcontractorData = succes.data.data;
                if(succes){
                  this.loading = false;
                }
            });
        }else{
            this.$store.dispatch("initSubcontractors2").then((succes) => {
              this.subcontractorData = succes.data.data; 
            });
        }
    },
    deleteSubcontractor(id) {
      this.$confirm("","Are you sure?","question").then(() => {
        this.$store.dispatch("deleteSubcontractor", id).then((response) => {
          if (response.status == 200) {
             this.$fire({
              title: "Subcontractor Successfully Deleted!",
              text: "",
              type: "success",
              timer: 4000
            });
            this.init();
          } else {
            this.$fire({
              title: "Something Missing!",
              text: "",
              type: "error",
              timer: 3000
            });
          }
        });
      });
    }, 
  }, 
  created() {
    this.loading = true;
    this.$store.dispatch("initSubcontractors2").then((response) => { 
      this.subcontractorData = response.data.data;
      if(response){
        this.loading = false;
      }
    });
  }, 
};
</script>
