// import store from "store";
//import { API_BASE_URL } from "../../config";
import axios from "axios";
import router from "../../router";
// const user = {
//   namespaced: true,
//   state: {
//     token: null,
//     email: "",
//     username: "",
//     status: "",
//     profile: {},
//   },
//   getters: {},
//   mutations: {
//     SET_TOKEN(state, token) {
//       state.token = token;
//     },
//   },
//   actions: {
//     async signIn({ dispatch }, user) {
//       axios
//         .post(API_BASE_URL + "/auth/login", this.formData)
//         .then((response) => {
//           console.log(response);
//           dispatch("attempt", response.data.token);
//           //   localStorage.setItem("token", response.data.token);
//           //   setTimeout(() => {
//           //     // this.$router.push("/dashboard/home");
//           //   }, 1000);
//         })
//         .catch((error) => {
//           console.log(response);
//           // handle authentication and validation errors here
//           this.errors = error.response.data.errors;
//           this.isLoading = false;
//         });
//     },
//     async attempt({ commit }, token) {
//       commit("SET_TOKEN", token);
//     },
//   },
// };
const state = {
    auth: {
        token: null,
        expires: 0,
        user: {
            id: null,
            email: null,
            username: null,
            workspace: null,
            count_categories: null,
            workspace_role: null
        },
    },
    error: [],
    userprofile: null,
    workSpaceUserList: {},
    getWorkSpaceUserList: {}
};

const getters = {
    auth: (state) => {
        return state.auth;
    },
    error: (state) => {
        return state.error;
    },
    getprofile: (state) => {
        return state.userprofile;
    },
    getWorkSpaceUserList: (state) => {
        return state.workSpaceUserList;
    },
    setCurrentPage: (state, data) => {
        state.workSpaceUserList.current_page = data; // set current page on pagination
    },
};

const mutations = {
    LOGIN_ERROR(state, error) {
        return state.error = error;
    },
    SET_AUTH(state, authData) {
        state.auth = authData;
    },
    SET_LOGOUT(state) {
        state.auth = {
            token: null,
            expires: 0,
            user: {
                id: null,
                email: null,
                username: null,
                workspace: null,
            },
        };
    },
    GET_PROFILE(state, data) {
        state.userprofile = data;
    },
     setCurrentPage: (state, data) => {
        state.workSpaceUserList.current_page = data; // set current page on pagination
    },
    SET_USERLIST(state, data) {
        state.workSpaceUserList = data;
    }
};

const actions = {
    async emailVerify({ commit }, formData) {
        return await axios.get(`email/verify/${formData.id}/${formData.hash}?expires=${formData.expires}&signature=${formData.signature}`)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async updateEmail({ commit }, formData) {
        return await axios.post(`user/userid/changeemail`, formData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async verifyOtp({ commit }, formData) {
        return await axios.post(`auth/verify-phone`, formData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async verifyMobile({ commit }) {
        return await axios.post(`auth/send-verification`)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async changePassword({ commit }, formData) {
        return await axios.post(`/user/userid/changepassword`, formData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async updatePassword({ commit }, formData) {
        return await axios.post(`/user/${formData.id}/changepassword`, formData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },

    async forgotPassword({ commit }, data) {
        return await axios.post("auth/forgot-password", data)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },

    async resetPassword({ commit }, data) {
        return await axios.post("auth/reset-password", data)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },

    async getProfile({ commit }) {
        return await axios.get(`user/userid/profile`)
            .then((response) => {
                commit("GET_PROFILE", response.data.user);
                console.log(response.data.user);
                return response.data.user;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    async UserEditEmail({ commit }, userId) {
        return await axios.get(`user/${userId}/profile`)
            .then((response) => {
                console.log(response.data);
                return response.data.user;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    async UserUpdateEmail({ commit }, EmailData) {
        return await axios.post(`user/${EmailData.id}/changeemail`, EmailData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                return error.response
            })
    },
    async getUserList({ commit }) {
        return await axios.get(`user/list`)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    }, 

    usersList: async ({ commit }, data) => {
        return await axios.get(`user/list?workspace=${data}`)
            .then((response) => {
                commit("SET_USERLIST", response.data);
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    usersListPaginate: async ({ commit }, getPage) => {
         console.log("hi");
        return await axios.get(`user/list?page=${getPage.pageNumber}&workspace=${getPage.workSpaceName}`)
            .then((response) => {
                commit("SET_USERLIST", response.data);
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    async getUserWorkSpaceList({ commit }) {
        return await axios.get(`workspaces`)
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    async profileUpdate({ commit }, data) {
        return await axios.post(`user/userid/profile`, data)
            .then((response) => {
                console.log(response);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },

    async signIn({ commit, dispatch }, user) {
        return await axios
            .post(`/auth/login`, user)
            .then((response) => {
                console.log(router);
                console.log(response.data);
                dispatch("setHeaderToken", response.data);
                // router.push("/dashboard");
                commit('LOGIN_ERROR', response.data);
                return (response);
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },

    async setHeaderToken({ commit, dispatch }, resData) {
        const now = new Date()
        const expirationDate = new Date(now.getTime() + resData.expires_in * 1000)
        console.log(resData);
        let authData = {
            token: resData.token,
            expires: expirationDate,
            user: {
                id: resData.user.id,
                email: resData.user.email,
                username: resData.user.email,
                workspace: resData.workspace,
                count_categories: resData.count_categories,
                workspace_role: resData.role,
            }

        }
        localStorage.setItem("auth", JSON.stringify(authData));
        await commit("SET_AUTH", authData);
        dispatch('autoLogout', resData.expires_in);
        try {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${resData.token}`;
        } catch (e) {
            console.log(e);

        }

    },

    async signUp({ commit }, formData) {
        return await axios
            .post(`/auth/register`, formData)
            .then((response) => {
                console.log(response.data);
                router.push({ name: 'login' });
                return response;
            })
            .catch((error) => {
                console.log(error.response);
                return error.response;
                // handle authentication and validation errors here
                // this.errors = error.response.data.errors;
                // this.isLoading = false;
            });
    },

    async logout({state, commit }, data) {
        return await axios
            .get(`/logout?token=${state.token}`)
            .then((response) => {
                router.push('/');
                console.log(response);
                commit('SET_LOGOUT', response);
                // commit('SET_LOGOUT_RES', response.data);
                localStorage.removeItem('auth');
                // router.replace({ name: 'login' });
                return response.data;
            })
            .catch((error) => {
                console.log(error.response);
                return error.response;
            });
    },
    // logout({ commit }) {
    //     alert('here');
    //     commit('SET_LOGOUT');
    //     localStorage.removeItem('auth');
    //     router.replace({ name: 'login' });
    // },

    autoLogin({ commit, dispatch }) {
        console.log('autologin')
        let authData = localStorage.getItem('auth');
        if (!authData) {
            return false
        }
        let auth = JSON.parse(authData)
        console.log(auth);
        let expirationDate = new Date(auth.expires);
        let now = new Date()
        console.log('now date is')
        console.log(now);
        console.log('expiredate is')
        console.log(expirationDate)
        if (now >= expirationDate) {
            return false
        }
        try {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${auth.token}`;
        } catch (e) {
            console.log(e);

        }

        var mseconds = (expirationDate.getTime() - now.getTime());
        console.log(mseconds)
            //dispatch('autoLogout', mseconds)
        commit('SET_AUTH', auth)
        return true;


    },

    autoLogout({ dispatch }, expires) {
        console.log('expires in ' + expires);
        setTimeout(() => {
            console.log('logout');
            dispatch('logout');
        }, expires * 1000);
    }

};



export default {
    state,
    mutations,
    actions,
    getters,
};