<!--===============================
=           Starteravatar           =
================================-->
<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="avatar-img" icon v-on="on">
          <img v-if="userDetail.userprofile.profile_image" :src="userDetail.userprofile.profile_image"/>
          <img v-else src="../../../assets/img/default.png" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in items1" :key="i" @click="starterClick(item.text)">
            <v-list-item-icon>
              <v-icon :color="item.color" v-text="item.img"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "starter-avatar",
  data(){
    return{
       userDetail: {
        userprofile:{
          profile_image:null,
        },
        auth:{}
      },
      items1: [
        {
          text: "Profile",
          img: "mdi-home",
          color: "success"
        },
        {
          text: "Logout",
          img: "mdi-lock",
          color: "pink"
        }
      ],
      items: [
        {
          text: "Real-Time",
          icon: "mdi-clock"
        },
        {
          text: "Audience",
          icon: "mdi-account"
        },
        {
          text: "Conversions",
          icon: "mdi-flag"
        }
      ]
    }
  },
  methods:{
    starterClick(value){
      if(value=='Logout'){
        this.$store.dispatch('logout',this.auth);
       } 
       else{
        this.$router.push("/profile");
       }
    }
  },
  created(){
    this.$store.dispatch("getProfile").then((success) => {
      this.userDetail = this.$store.getters.getprofile;
    });
    this.auth = this.$store.getters.auth;
    console.log(this.$store.getters.auth);
  }
};
</script>
<style scoped>
.avatar-img img {
  width: 32px;
  border-radius: 50%;
}
.profile_page img{
  width: 100%;
  border-radius: 100%;
  margin: auto;
}
.profile_page .name{
  font-weight:600;
  font-size:20px;
}
.profile_page .email{
  font-weight:500;
  font-size:18px;
  margin: 14px 0px;
}
</style>
<!--===============================
=           Starteravatar  end          =
================================-->
