import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import categories from "./modules/categories";
import items from "./modules/items";
import suppliers from "./modules/suppliers";
import units from "./modules/units";
import subcontractors from "./modules/subcontractors";
import status from "./modules/status";
import quotes from "./modules/quotes";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        user,
        categories,
        items,
        units,
        status,
        suppliers,
        subcontractors,
        quotes
    },
});