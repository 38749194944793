<!--==============================
=            ERROR505            =
===============================-->

<template>
  <v-app light>
    <section id="welcome" class="hide-overflow">
      <v-layout>
        <v-flex hidden-sm-and-down md6>
          <v-parallax style="margin-top:0" color="primary" height="700">
            <v-layout column align-center justify-center>
              <img width="100%" src="../../assets/img/error1.png" alt="Vuetify.js " height="150" />
            </v-layout>
          </v-parallax>
        </v-flex>

        <v-flex
          xs12
          md6
          align-content-space-between
          layout
          :pa-5="$vuetify.breakpoint.smAndDown"
          wrap
        >
          <v-layout column align-center justify-center>
            <v-flex xs10 md6 mx-auto justify-center>
              <v-row style="margin-top:50%">
                <img src="../../assets/img/500.png" height="300" />
              </v-row>
              <v-row style="margin-left:30%">
                <v-btn
                  class="mt-12 ml-5 mx-auto"
                  color="primary"
                  dark
                  large
                  @click="login()"
                >Go Home</v-btn>
              </v-row>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </section>
  </v-app>
</template><script>
export default {
  props: {
    source: String
  },
  data: () => ({
    loading: false,
    drawer: null,
    model: {
      username: "admin@admin.com",
      password: "password"
    }
  }),

  methods: {
    login() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push("/dashboard/dashboard1");
      }, 1000);
    }
  }
};
</script>
<!--====  End of ERROR505  ====-->
