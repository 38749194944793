<!--====================================
=            FORGOTPASSWORD            =
=====================================-->
<template>
<v-app light>
    <section id="welcome">
      <div class="container">
        <form action="" method="post" @submit.prevent="handlesubmit">
          <v-row class="main_login">
            <div class="col-md-6 col-12 m-auto">
              <img
                width="100%"
                src="../../assets/img/bg-login.png"
                alt="Vuetify.js "
              />
            </div>
            <div class="col-md-5 col-12 m-auto">
              <!-- Log In page -->
                      <div class="account-body accountbg">
                        <!-- Log In page -->
                        <div class="row">
                          <div class="col-12 align-self-center">
                            <div class="auth-page">
                              <div class="card auth-card shadow-lg">
                                <div class="card-body">
                                  <div class="px-3">
                                    <!--end auth-logo-box-->
                                    <div class="text-center auth-logo-text">
                                      <h4 class="mt-0 mb-3 mt-5">Reset Password</h4>
                                      <p
                                        class="text-muted mb-0"
                                      >Enter your Email and instructions will be sent to you!</p>
                                    </div>
                                    <!--end auth-logo-text-->
                                    <v-card-text>
                                      <v-form>
                                        <v-text-field
                                          v-model="formForm.email"
                                          label="Email"
                                          name="email"
                                          prepend-icon="person"
                                          type="text"
                                        />
                                      </v-form>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-content-between">
                                      <v-btn type="button" @click="login" color="info">Login</v-btn>
                                      <v-btn type="submit" color="primary">Forgot</v-btn>
                                    </v-card-actions>
                                    <!--end form-->
                                  </div>
                                  <!--end /div-->
                                </div>
                                <!--end card-body-->
                              </div>
                              <!--end card-->
                            </div>
                            <!--end auth-page-->
                          </div>
                          <!--end col-->
                        </div>
                      </div>
                <!--end auth-page-->
              </div>
          </v-row>
        </form>
      </div>
    </section>
  </v-app>
</template>
<script>
export default {
  props: {
    source: String
  },
  data: () => ({
    loading: false,
    drawer: null,
    formForm:{
      email: "",
    }
  }),

  methods: {
    handlesubmit(e){
        this.$store.dispatch('forgotPassword',this.formForm).then((response)=>{
          console.log(response.data.status);
          if(response.data.status == true){
            this.$fire({
              title: "Email Sent Successfully.",
              text: "Check Your Email for Reset Password Link.",
              type: "success",
              timer: 6000
            });
          }
          else if(response.data.status == false){
            this.$fire({
              title: "Sending Email Failed",
              text: "Something went wrong!",
              type: "warning",
              timer: 4000
            });
          }
        });
    },
    login() {
      setTimeout(() => {
        this.$router.push("/");
      }, 500);
    },
  }
};
</script>


<!--====  End of FORGOTPASSWORD  ====-->