<template>
<div>
    <a id="show-sidebar" class="btn btn-sm btn-dark" href="#">
        <i class="fas fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
            <div class="sidebar-brand">
                <a href="#"></a>
                <div id="close-sidebar">
                    <i class="fas fa-times"></i>
                </div>
            </div>
            <div class="sidebar-menu">
                <ul>
                    <li class="header-menu">
                        <span>NAVIGATE</span>
                    </li>
                    <li>
                        <a href="#">
                            <i class="far fa-circle"></i>
                            <span>My Apps</span>
                        </a>
                    </li>
                    <li class="sidebar-dropdown active">
                        <a href="#">
                            <i class="far fa-circle"></i>
                            <span>Marketplace</span>
                        </a>
                        <div class="sidebar-submenu" style="display: block;">
                            <ul>
                                <li>
                                    <a href="#">Summary</a>
                                </li>
                                <li>
                                    <a href="#">Details</a>
                                </li>
                                <li>
                                    <a href="#">Groups</a>
                                </li>
                                <li>
                                    <a href="#">Branding</a>
                                </li>
                                <li>
                                    <a href="#">Services</a>
                                </li>
                                <li>
                                    <a href="#">Authentication</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="header-menu">
                        <span>Helps</span>
                    </li>
                    <li class="active">
                        <a href="#">
                            <i class="far fa-circle"></i>
                            <span>Documentation</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="far fa-circle"></i>
                            <span>Tutorials</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="far fa-circle"></i>
                            <span>Forums</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
    </nav>
    </div>
</template>
<script>
export default {
  name: "sidebar-branding",
  components: {
    
  }
}
</script>