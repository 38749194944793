<template>
<div class="container-fluid">
    <h2 class="my-4">Account Information</h2>
    <div class="row">
        <div class="col-md-4 mt-4">
            <div class="card shadow">
                <div class="card-header">
                    <h4 class="my-2">TENANT INFORMATION</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <span>Organization:</span>
                            <span>xyz</span>
                        </li>
                        <li>
                            <span>Email Id:</span>
                            <span>xyz</span>
                        </li>
                        <li>
                            <span>Country:</span>
                            <span>xyz</span>
                        </li>
                        <li>
                            <span>Timezone:</span>
                            <span>xyz</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card shadow">
                <div class="card-header">
                    <h4 class="my-2">USERS & GROUPS</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <span>ACTIVE USER:</span>
                            <span class="font-weight-bold">1</span>
                        </li>
                        <li>
                            <span>TOTAL GROUPS:</span>
                            <span class="font-weight-bold">0</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card shadow">
                <div class="card-header">
                    <h4 class="my-2">BRANDING</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <span><i class="fas fa-chalkboard-teacher text-color"></i> Login</span>
                        </li>
                        <li>
                            <span><i class="far fa-envelope text-color"></i> Email</span>
                        </li>
                        <li>
                            <span><i class="fas fa-sms text-color"></i> SMS</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-4">
            <div class="card shadow">
                <div class="card-header">
                    <h4 class="my-2">USERS & GROUPS</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <span>ACTIVE USER:</span>
                            <span>1</span>
                        </li>
                        <li>
                            <span>TOTAL GROUPS:</span>
                            <span>0</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card shadow">
                <div class="card-header">
                    <h4 class="my-2">BRANDING</h4>
                </div>
                <div class="card-body">
                    <ul>
                        <li>
                            <span>Login</span>
                        </li>
                        <li>
                            <span>Email</span>
                        </li>
                        <li>
                            <span>SMS</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- page-wrapper -->
</template>

<script>
export default {
  name: "summary",
  components: {
  }
};
</script>
