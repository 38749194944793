import axios from "axios";
const state = {
    units: [],
    unit: [],
};
const getters = {
    units: (state) => {
        return state.units;
    },
    unit: (state) => {
        return state.unit;
    },
};

const actions = {
    initUnits: async({ commit }, pageNumber) => {
        return axios
            .get(`units?page=${pageNumber}`)
            .then((response) => {
                commit("SET_UNITS", response.data.data);
                commit("SET_UNIT", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    addUnit: async({ commit }, formData) => {
        console.log("addd module");
        return await axios
            .post(`unit/create`, formData)
            .then((response) => {
                // commit("ADD_UNIT", response.data);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    },
    deleteUnit: async({ commit }, id) => {
        return await axios
            .delete(`unit/${id}/delete`)
            .then((response) => {
                // commit("REMOVE_UNIT", id);
                return response;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    },
    fetchUnits: async({ commit }) => {
        await axios
            .get(`units`)
            .then((response) => {
                console.log(response);
                // console.log("del");
                // commit("REMOVE_UNIT", response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    editUnit: async({ commit }, id) => {
        return axios
            .get(`unit/${id}/edit`)
            .then((response) => {
                commit("EDIT_SUPPLIER", response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    async updateUnit({ commit }, formData) {
        const response = await axios.post(`unit/${formData.id}/update`, formData);
        // commit("ADD_UNIT", response.data);
        return response;
    },
};
const mutations = {
    SET_UNITS(state, payload) {
        state.units = payload;
    },
    SET_UNIT(state, payload) {
        state.unit = payload;
    },
    ADD_UNIT: (state, unit) => state.units.unshift(unit),
    REMOVE_UNIT: (state, id) => state.units.filter((unit) => unit.id !== id),
    //   EDIT_UNIT: (state, unit) => {
    //     state.units = unit;
    //   },
    setCurrentPage: (state, data) => {
        state.units.current_page = data; // set current page on pagination
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};