<template>
        <v-row>
          <div class="col-12 item_configure"> 
              <div>
                <tabs>
                  <tab name="Add Category">
                    <category-form></category-form>
                  </tab>
                  <tab name="Edit Category">
                    <v-row>
                      <!-- :img-photo="'path-img'" -->
                      <div class="col-md-6">
                        <vue-search
                          placeholder="Search category"
                          :img-photo="''"
                          @newitem="newitem()"
                          v-model="category.id"
                          @itemselected="categorySelected($event)"
                          :source-field="'name'"
                          :show-new-botton="true"
                          :search-by-field="true"
                          :api-source="'/categories'"
                          :query-key="'q'"
                        >
                        </vue-search>
                      </div>
                      <div class="col-md-6">
                        <v-btn class="btn btn-success" @click="editcategory()">Edit</v-btn>
                      </div> 
                    </v-row>
                   <category-form :_category="category" title="Update Category" v-if="isEdit"></category-form>
                  </tab>
                </tabs>
              </div> 
          </div>
        </v-row>
</template>

<script>
import Vue from "vue"
import CategoryForm from './CategoryForm.vue'
import Tabs from "vue-tabs-component";
import VueSearch from "../../utils/VueSearch";
import {API_BASE_URL} from "../../../config.js"
Vue.use(Tabs);
export default {
  name: "category",
  components: {
    CategoryForm,
    VueSearch
  },
  data() {
    return {
      tabActive: "supplier",
      listCategory: "",
      category: {
        name: "",
        order: "",
        suppliers: [],
        subcontractors: [],
        type: "global",
        active: 1,
         category_id: null,
      },
      isEdit:false,
      API_BASE_URL:API_BASE_URL
    };
  },
  methods:{
      categorySelected(e) { 
        if (e.id != null && e.id !== "undefined") {
            console.log('Category selected');
            //console.log(e);
            this.category = e;            
        }
      },
      editcategory() {
         
          this.isEdit = false;
          if (Object.keys(this.category).length > 0) {
              let vm =this;
              setTimeout(()=>{
                vm.isEdit=true;
              },1000); 
          }
         if(this.category.id) {
              this.$store.dispatch("editCategory",this.category.id).then((data) => {
                this.category = data;  
                this.isEdit = true;
              });
        }  
    },
  }
}
</script>

<style>
.tabs-component ul {
  display: flex;
  align-items: center;
}

.tabs-component ul li:last-child {
  margin-left: 10px;
}

.tabs-component ul li a {
  font-size: 14px !important;
  border: 0px solid #ccc;
  border-width: 1px 1px 3px 1px !important;
}

.tabs-component ul li.is-active a {
  font-size: 14px !important;
  border: 0px solid rgb(103, 33, 120);
  border-width: 1px 1px 3px 1px !important;
  color: rgb(103, 33, 120);
}

.tabs-component ul li:last-child {
  border-bottom: 1px solid transparent;
}

.tabs-component .tabs-component-panels .content-input input {
  height: 43px;
}

.tabs-component .tabs-component-panels button {
  margin-left: 20px;
}

.content-input .input-txt {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
