<!--==============================
=            REGISTER            =
===============================-->

<template>
  <v-app light>
    <section id="welcome">
      <v-layout class="container">
           <form action="" method="post" @submit.prevent="register">
        <v-row class="main_login">
          <div class="col-md-6 col-12 m-auto">
            <img width="100%" src="../../assets/img/bg-login.png" alt="Vuetify.js "/>
          </div>
          <div class="col-md-5 col-12 m-auto">
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <!--end auth-logo-box-->
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">REGISTER</h4>
                        <p class="text-muted mb-0">Sign in to continue</p>
                      </div>
                      <!--end auth-logo-text-->
                      <v-form>
                        <v-text-field
                          label="Email"
                          id="email"
                          name="email"
                          v-model="formData.email"
                          prepend-icon="mail"
                          type="email"
                        />
                        <div v-if="submitted && $v.formData.email.$error" class="invalid-feedback">
                            <span v-if="!$v.formData.email.required">Email is required</span>
                            <span v-if="!$v.formData.email.email">Email is invalid</span>
                        </div>
                        <v-text-field
                          label="Name"
                          v-model="formData.name"
                          name="name"
                          prepend-icon="person"
                          type="text"
                        />
                        <div v-if="submitted && !$v.formData.name.required" class="invalid-feedback">User Name is required</div>

                        <v-text-field
                          id="password"
                          v-model="formData.password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                        />
                         <div v-if="submitted && $v.formData.password.$error" class="invalid-feedback">
                            <span v-if="!$v.formData.password.required">Password is required</span>
                            <span v-if="!$v.formData.password.minLength">Password must be at least 8 characters</span>
                        </div>
                        
                        <v-text-field
                          id="confirmPassword"
                          v-model="formData.confirmPassword"
                          label="Confirm Password"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                        />
                        <div v-if="submitted && $v.formData.confirmPassword.$error" class="invalid-feedback">
                            <span v-if="!$v.formData.confirmPassword.required">Confirm Password is required</span>
                            <span v-else-if="!$v.formData.confirmPassword.sameAsPassword">Passwords must match</span>
                        </div>
                        <v-text-field
                          label="Workspace Name"
                          v-model="formData.workspace"
                          name="workspace"
                          prepend-icon="person"
                          type="text"
                        />
                        <div v-if="submitted && !$v.formData.workspace.required" class="invalid-feedback">Workspace Name is required</div>
                      </v-form>
                      <!--end form-->
                      <v-card-actions>
                        <v-spacer />
                        <v-btn type="submit"  color="primary">Register</v-btn>
                      </v-card-actions>
                      <v-card-actions class="login_footer">
                          <v-spacer />
                          <h6>Already a member ? </h6>
                          <v-btn @click="login()" class="ml-3" color="info">Login</v-btn>
                      </v-card-actions>
                    </div>
                    <!--end /div-->
                  </div>
                  <!--end card-body-->
                </div>
                <!--end card-->
              </div>
              <!--end auth-page-->
          </div>
        </v-row>
           </form>
      </v-layout>
      <b-modal v-model="is_open" hide-footer hide-header ok-only scrollable id="vb-close" class="p-0" size="lg">
        <form method="post" @submit.prevent="submitImportCategory()">
        <v-container>
            <v-row>
                <div class="col-md-12 p-0">
                    <div class="madal_dead text-center">
                        <h2>You have successfully registered.</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="select_cate_css border">
                        <b-form-checkbox v-for="item in globalCategories" :key="item.id"
                            v-model="formData.category"
                            color="primary"
                            :value="item.id"
                            switch> {{ item.name }}
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="col-md-12 p-0">
                    <div class="madal_dead text-center">
                    </div>
                </div>
                <div class="col-md-12 m-auto p-0">
                    <div class="madal_dead mt-3 text-center">
                        <b-button class=" float-right model_remore" variant="danger" @click="$bvModal.hide('vb-close')">CLOSE</b-button>
                    </div>
                </div>
            </v-row>
        </v-container>
        </form>
    </b-modal>
    </section>
  </v-app>
</template>
<script>

import { mapActions } from 'vuex'
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators'
export default {
  props: {
    source: String,
  },
  data() {
    return{
      is_open:false,
      loading: false,
      drawer: null,
      formData: {
        name:"" ,
        email:"",
        password: "",
        confirmPassword:"",
        workspace:""
      },
      submitted: false,
    }
  },
validations: {
     formData: {
        name: { required },
        email:{required,email},
        password: { required, minLength: minLength(8) },
        confirmPassword: { required, sameAsPassword: sameAs('password') },
        workspace: { required },
      }
    },
  methods: {
    // ...mapActions(['signUp']),
    register(e){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      this.$store.dispatch("signUp",this.formData).then((response) => {
        console.log(response.status);
        if(response.status == 422){
          this.$fire({
                title: "The email has already been taken.",
                text: "Use other email",
                type: "warning",
                timer: 4000
            });
        }
        else if(response.status == 200){
          this.$fire({
                title: "You have successfully registered.",
                text: "Check your email for verification.",
                type: "success",
                timer: 10000
            });
        }
      });
    },
    login() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push("/");
      }, 1000);
    },
  },
};
</script>
<style scoped>
  .input.invalid label {
    color: red;
  }

  .input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
  }
</style>

<!--====  End of REGISTER  ====-->