import axios from "axios";
const state = {
    category: "",
    items: [],
    item: {},
    categoryItems: [],
    categoryItem: {},
    categoryAllItems: []
};

const getters = {
    items: (state) => {
        return state.items;
    },
    item: (state) => {
        return state.item;
    },
    menuItems: (state) => {
        return state.categoryItems;
    },
    categoryItems: (state) => {
        return state.categoryItems;
    },
    categoryItem: (state) => {
        return state.categoryItem;
    },
    categoryAllItems: (state) => {
        return state.categoryAllItems;
    }
};
const mutations = {
    SET_ALL_CATEGORY_ITEMS(state, payload) {
        state.categoryAllItems[payload.category_id] = payload.items;
    },
    SET_CATEGORY_ITEMS(state, items) {
        state.categoryItems = items;
    },
    SET_CATEGORY_ITEM(state, item) {
        state.categoryItem = item;
    },

    ADD_ITEM(state, data) {},
};

const actions = {
    addItem: async({ commit }, formData) => {
        return await axios
            .post(`item/create`, formData)
            .then((response) => {
                console.log(response.data);
                return response.data;
            }).catch((error) => {
                console.log(error);
            });
    },

    fetchCategoryItems: async({ commit }, category) => {
        let setCategoryItems = true;
        let category_id = 0;
        if (typeof category === 'object') {
            category_id = category.id;
            setCategoryItems = category.setItems !== undefined ? category.setItems : true;
        } else {
            category_id = category;
        }
        return await axios
            .get(`items/${category_id}/category`)
            .then((response) => {
                if (setCategoryItems)
                    commit("SET_CATEGORY_ITEMS", response.data.data);
                commit("SET_ALL_CATEGORY_ITEMS", { category_id: category_id, items: response.data.data })
                return response.data.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    editItem: async({ commit }, item_id) => {
        return await axios
            .get(`item/${item_id}/edit`)
            .then((response) => {
                // console.log(response.data);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateItem: async({ commit }, formData) => {
        console.log(formData);
        return await axios
            .post(`item/${formData.id}/update`, formData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    deleteItem: async({ commit }, id) => {
        return await axios
            .delete(`item/${id}/delete`)
            .then((response) => {
                // fetchCategoryItems();
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },
};



export default {
    state,
    mutations,
    actions,
    getters,
};