<!--================================
=            APP LAYOUT            =
=================================-->
<template>
  <v-app id="v-app" :dark="appConfig.dark">
    <!-- if to route.js is public or not  -->
    <template v-if="!$route.meta.public">
      <!-- v-if sidebar basic  -->
      <sidebar-duo></sidebar-duo>
      <!-- or sidebar duo -->

      <!-- toolbar -->
      <base-toolbar></base-toolbar>
      <!-- container -->
      <v-content class="v-content__wrap">
        <vue-page-transition name="fade-in-down">
          <router-view />
        </vue-page-transition>
        <!-- app-footer -->
        <base-footer></base-footer>
      </v-content>
      <!--base-chat></base-chat-->
    </template>
    <!-- else pages session -->
    <template v-else>
      <transition>
        <keep-alive>
          <router-view :key="$route.fullpath"></router-view>
        </keep-alive>
      </transition>
    </template>
  </v-app>
</template>
<script>
import SidebarDuo from "./Sidebar/SidebarDuo.vue";
import BaseToolbar from "./Base/BaseToolbar.vue";
import BaseFooter from "./Base/BaseFooter.vue";

/*import BaseChat from "./Base/BaseChat.vue";*/

export default {
  name: "app-layout",
  components: {
    SidebarDuo,
    BaseFooter,

    BaseToolbar,

    //BaseChat
  },
  data() {
    return {
      showDrawer: true
    };
  },
  methods: {
    handleDrawerVisiable() {
      this.showDrawer = !this.showDrawer;
    }
  }
};
</script>
<style scoped>
.page-wrapper {
  min-height: calc(100vh - 64px - 50px - 81px);
}
</style>
<!--====  End of APP LAYOUT  ====-->