<!--===============================
=           Startermenu           =
================================-->
<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <img width="30" src="../../../assets/img/Usa.png" />
        </v-btn>
      </template>
      <v-list>
        <v-list-group :value="true">
          <template #activator>Country</template>
          <v-list-item v-for="(item, i) in items1" :key="i">
            <v-list-item-avatar>
              <img width="30" :src="item.img" />
            </v-list-item-avatar>
            <v-list-item-content>{{ item.text }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "starter-menu",
  data: () => ({
    items1: [
      {
        text: "Brasil",
        img: "https://cdn.countryflags.com/thumbs/brazil/flag-wave-250.png"
      },
      {
        text: "Austria",
        img: "https://cdn.countryflags.com/thumbs/iceland/flag-800.png"
      },
      {
        text: "França",
        img: "https://cdn.countryflags.com/thumbs/france/flag-800.png"
      },
      {
        text: "United  kingdom",
        img: "https://cdn.countryflags.com/thumbs/united-kingdom/flag-800.png"
      }
    ],

  })
};
</script>
<style></style>
<!--===============================
=           Startermenu  end          =
================================-->
