<template>
<div>
  
  <form method="post"  @submit.prevent="submitForm($event)">
    <v-row>
        <div class="col-12 item_configure">
            <h5>{{title}}</h5>
        </div>
    </v-row>
    <v-row>
        <div class="col-md-3">
            <label for="">Name*</label>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control" name="name"  
            v-model.trim="$v.category.name.$model"
            @blur="$v.category.name.$touch()"  
            :class="{invalid: $v.category.name.$error}"                 
            />
            <div
                      v-if="$v.category.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.category.name.required"
                        >Name is Required</span
                      >
                      <span v-if="!$v.category.name.minLength"
                        >Minimum 3 Characters Required</span
                      >
                    </div>
        </div>
    </v-row>
    <v-row>
        <div class="col-md-3">
            <label for="">Type(Global/Local)*</label>
        </div>
        <div class="col-md-9">
            <multiselect v-model="category.type" :options="categoryTypes" placeholder="Select Type" :multiple="false" :taggable="true" 
            @open="$v.category.type.$touch()" :class="{invalid: $v.category.type.$error}" />

            <div v-if="$v.category.type.$error" class="invalid-feedback">
                  <span v-if="!$v.category.type.required">Category Type is Required</span>
                  <span v-if="!$v.category.type.minLength">Select One Category</span>
            </div>
        </div>
        
    </v-row>
    <v-row>
        <div class="col-md-3">
            <label for="">Order</label>
        </div>
        <div class="col-md-9">
            <input type="number" class="form-control w-100" name="order" v-model="category.order" />
            <span class="font-italic">Used for sorting. The lowest orders displays on
                top</span>
        </div>
    </v-row>
    <v-row>
        <div class="col-md-3">
            <label for="">Partners for this category</label>
        </div>
        <div class="col-md-9">
            <div class="tiles tabs-css" id="tiles-2">
                <!-- Nav tabs -->
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link" 
                        :class="{ active: tabActive == 'supplier' }" @click="tabActive = 'supplier'">Suppliers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" 
                        :class="{ active: tabActive == 'subcontractor' }" @click="tabActive = 'subcontractor'">Sub Contractors</a>
                    </li>
                </ul>

                <v-row v-if="tabActive == 'supplier'">
                    <div class="col-md-12">
                        <label class="
                                  form-control-label
                                  btn
                                  text-white
                                  btn-info
                                "><i class="fas fa-angle-down"></i> Add
                            Supplier</label>
                        <multiselect
                                v-model="category.suppliers"
                                label="name"
                                :options="suppliers"
                                placeholder="Select Supplier"
                                :multiple="true"
                                :options-limit="300"
                                track-by="name"
                              ></multiselect>
                    </div>
                </v-row>
                <v-row v-if="tabActive == 'subcontractor'">
                    <div class="col-md-12">
                        <label class="form-control-label btn text-white btn-info ">
                                <i class="fas fa-angle-down"></i> Add
                            Subcontractors</label>
                       <multiselect
                                v-model="category.subcontractors"
                                label="name"
                                :options="subcontractors"
                                placeholder="Select Subcontractor"
                                :multiple="true"
                                :options-limit="300"
                                track-by="name"
                              ></multiselect>
                    </div>
                </v-row>
            </div>
        </div>
    </v-row>
    <div class="cancel_save_btn text-right">
        <b-btn class="btn-danger category_select ml-2" @click="$router.back()">Cancel</b-btn>
        <b-btn type="submit" class="btn-info item_select ml-2" :disabled="$v.category.$invalid || $v.category.$error" >
                    <span v-if="this.category.id">Update</span><span v-else>Save</span></b-btn>
    </div>
    </form>
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { required, numeric, minLength } from "vuelidate/lib/validators";
export default {
  name: "categoryform",
  components: {
    Multiselect,
  },
  props:{
    _category:{
      type:Object,
      default:()=>({
        name: "",
        order: "",
        suppliers: [],
        subcontractors: [],
        
        
         
      })
    },
    title:{
      type:String,
      default:'Add Category'
    }

  },
  data() {
    return {
      tabActive: "supplier",
      images: [],
      categoryTypes: ["global", "local"],
      category:this._category
    };
  },
  validations: {
    category: {
      name: {
        required,
        minLength: minLength(3),
      },
      type: {
        required,
        minLength:minLength(1),
      },
      
    },
  },
  methods: {
    submitForm($event) {
        if(typeof this.category.id==='undefined') this.addCategory($event);
        else if(typeof this.category.id!=='undefined' && Number(this.category.id)>0) this.editCategory();  
    },

addCategory(event){
this.$store.dispatch("addCategory", this.category).then((success) => {
        if (success) {

        this.$fire({
              title: "Category Added Successfully!",
              text: "",
              type: "success",
              timer: 4000
            });
          console.log(event);
          event.target.reset();
          this.$v.$reset()
          this.category = {
            name: "",
            order: "",
            suppliers: [],
            subcontractors: []      
           };
        console.log('categories after add are');
        console.log(this.$store.state.categories.categories);
        }
      });
    },
    editCategory(){
      //this.category.type='global';
      this.$store.dispatch("updateCategory", this.category).then((success) => {
                 this.$fire({
              title: "Category Updated Successfully!",
              text: "",
              type: "success",
              timer: 4000
            });
              console.log(success) 
       });
},

  },
  computed: {
    suppliers() {
      return this.$store.getters.suppliers;
    },
    subcontractors() {
      return this.$store.getters.subcontractors;
    },
  },
  created() {
     this.$store.dispatch("initSuppliers")
    this.$store.dispatch("initSubcontractors")
   /* this.$store.dispatch("fetchCategories").then((response)=>{
      console.log("fetch Categories response");
console.log(response);
    }); */

  },
  
};
</script>
<style>
input.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
textarea.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.multiselect.invalid .multiselect__tags {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>