<template>
  <div> 
    <table width="100%" cellspacing="1" cellpadding="5" ref="quote" class="quoteTable">
      <tr><th>Ref</th><th>Name</th><th>Email</th><th>Phone</th><th>Address</th><th>Local Council</th></tr>
      <tr><td>{{quote.reference_no}}</td><td>{{quote.name}}</td><td>{{quote.email}}</td><td>{{quote.phone}}</td><td>{{quote.address}}</td><td>{{quote.local_council}}</td></tr>
      <tr><th>Items Cost</th><th>Overhead({{quote.overhead}}%)</th><th>Profit({{quote.profit}}%)</th><th>Total Amount</th><th>Tax({{quote.tax}}%)</th><th>Gross Amount</th></tr>
      <tr><td>&#163;{{quote.calc_items_cost}}</td><td>&#163;{{quote.calc_overhead}}</td><td>&#163;{{quote.calc_profit}}</td><td>&#163;{{quote.tamount}}</td><td>&#163;{{quote.calc_tax}}</td><td>&#163;{{quote.gross_amount}} </td></tr>
      </table><br />
      
      
      
  </div>
</template>
<script>
import { mapGetters,mapActions} from "vuex";
export default {
  computed:{
...mapGetters(['quote','categories'])
  },
methods:{
getCategoryName(id){
  //console.log(this.$store.getters.categories);
  for(var i=0;i<=this.categories.length;i++){
    if(this.categories[i].id==id)
    return this.categories[i].category_label;
  }
          
    },
  },
created(){
  console.log(this.$refs);
}
}
</script>
