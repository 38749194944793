<template>
  <v-container class="register_fullheight">
      <v-row class="no-gutters">
        <div class="col-md-12">
              <div class="top_bar edit_q">
                <div>
                    <h6><i class="fas fa-user"></i> Registered Clients</h6>
                </div>
            </div>
        </div>
  
       <div class="col-md-12">
       <div class="">
            <vue-good-table
             :columns="columns"
             :rows="rows" 
             :search-options="{enabled: true }"
             styleClass="vgt-table striped">
            </vue-good-table>
        </div>
      </div>
       <div class="regis_client_footer bg-white">
            <v-row>
                <div class="col-md-3">
                    <router-link to="/editquote">
                        <b-button class="border-left-set" variant="light">BACK TO QUOTE</b-button>
                    </router-link>
                </div>
                <div class="col-md-3">
                    <b-button @click="register_clients = !register_clients"  variant="info"><i class="fa fa-user-plus"></i> REGISTER CLIENT</b-button>
                </div>
            </v-row>
            <!-- REGISTER CLIENTS MODAL -->
              <b-modal v-model="register_clients" hide-footer hide-header ok-only id="vb-close" class="p-0" size="md">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead text-center">
                        <h2>Client</h2>
                      </div>
                    </div>
                    <div class="col-md-12 border">
                      <transition name="fade">
                          <v-row>
                              <div class="col-md-4">
                                <label for="f_name">First Name</label>
                              </div>
                               <div class="col-md-8">
                                <input type="text" class="form-control" name="f_name" id="f_name">
                              </div>
                               <div class="col-md-4">
                                <label for="l_name">Last Name</label>
                              </div>
                               <div class="col-md-8">
                                <input type="text" class="form-control" name="l_name" id="l_name">
                              </div>
                              <div class="col-md-4">
                                 <label for="email">Email</label>
                              </div>
                               <div class="col-md-8">
                                 <input type="email" class="form-control" name="email" id="email">
                              </div>
                              <div class="col-md-4">
                                <label for="phone">Phone</label>
                              </div>
                               <div class="col-md-8">
                                <input type="number" class="form-control" name="phone" id="phone">
                              </div>
                              <div class="col-md-12">
                                <b-button variant="light" disabled class="border-left-set"><strong class="text-info"><i class="fas fa-info-circle"></i> After creation an activation email will be sent.</strong></b-button>
                              </div>
                              <div class="col-md-12 text-center">
                                <b-button variant="success">OK</b-button>
                              </div>
                            </v-row>
                       </transition>
                    </div>
                     <div class="col-md-12 m-auto p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="w-25" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal>
              <!-- REGISTER CLIENTS MODAL END-->
        </div>
    </v-row>
  </v-container>
</template>

<script>
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Multiselect from "vue-multiselect";
export default {
  name: "register-clients",
    components: {
    Multiselect,
    VueGoodTable
  },
  data() {
    return {  
        register_clients: false,
        columns: [
        {
          label: 'First Name',
          field: 'fname',
           type: 'text', 
        },
        {
          label: 'Last Name',
          field: 'lname',
          type: 'text',
        },
        {
          label: 'Email',
          field: 'email',
          type: 'email',
        },
         {
          label: 'Phone',
          field: 'phone_num',
          type: 'phone',
        },
         {
          label: 'Created',
          field: 'created',
          type: 'created',
        },
      ],
      rows: [
        { id:1, fname:"abc 1", lname:'last',email:'abc@abc.com',  phone_num:'9876543210', created: '2011-10-31'},
        { id:2, fname:"abc 2", lname:'last',email:'abc@abc.com',  phone_num:'9876543210', created: '2011-10-31'},
      ], 
    };
  }
};
</script>
<style scoped>
.edit_q h6{
    font-size:36px;
    color:#fff;
}
.edit_q i{
    font-size:40px;
    color:#000;
}
.top_bar {
    padding: 25px 20px;
}
.register_fullheight{
    position: relative;
    height: 89vh;
    background: #fff;
    padding: 0px 8px;
}
.regis_client_footer{
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
}
.border-left-set{
    border-left:4px solid #672178;
}
label {
    font-size: 18px;
    font-weight: 500;
}
</style>