<template lang="">
  <div class="col-md-12">
    <div class="home_box1">
      <h3>View User List</h3>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: 10,
        }"
        styleClass="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <span class="edit_delete" v-if="props.column.field == 'action'">
            <router-link
              v-b-tooltip.hover
              title="Edit"
              class="btn btn-sm btn-outline-primary"
              :to="{ name: 'edit-subcontractor', params: { id: props.row.id } }"
            >
              <i class="fas fa-marker"></i>
            </router-link>
            <button
              @click="deleteSubcontractor(props.row.id)"
              class="btn btn-sm btn-outline-danger"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
Vue.use(VueGoodTable);
export default {
name:"user-list",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      rows: [],
      set: true,
      user_columns: [
        {
          label: "SR.",
          field: "id",
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Action",
          field: "action",
          type: "action",
        },
      ],
    };
  },
  methods: {
      init() {
        this.$store.dispatch("usersList").then((success) => { 
          console.log(success);
            this.rows = success[0].data;
        });
    },
    // deleteSubcontractor(id) {
    //   this.$confirm("Are you sure?").then(() => {
    //     this.$store.dispatch("deleteSubcontractor", id).then((succes) => {
    //       if (succes.status == 200) {
    //          this.$fire({
    //           title: "Subcontractor Successfully Deleted!",
    //           text: "",
    //           type: "success",
    //           timer: 4000
    //         });
    //       } else {
    //         this.$fire({
    //           title: "Something Missing!",
    //           text: "",
    //           type: "error",
    //           timer: 3000
    //         });
    //       }
    //       console.log(succes);
    //       this.$store.dispatch("initSubcontractors").then((succes) => {
    //       this.init();
    //       });
    //     });
    //   });
    // }, 
  }, 
  created() {
    this.$store.dispatch("usersList").then((success) => { 
        // this.rows = success[0].data;
        console.log(success);
    });
  }, 
};
</script>
