 <template>
  <v-app>
    <v-app-bar app color="white" height="100" class="elevation-1">
      <div class="logo-home">
        <img src="../../assets/img/logo11.png" />
      </div>
    </v-app-bar>
    <section
      id="home"
      class="section bg-voilet bg-overlay overflow-hidden d-flex align-items-center"
    >
      <div class="container">
        <div class="row align-items-center">
          <!-- home Intro Start -->
          <div class="col-12 col-md-7 col-lg-6">
            <div class="home-intro">
              <h1 class="font-weight-bold text-white">Conservative Admin Vue 2 Responsive Template</h1>
              <p class="text-white font-weight-light font-italic my-4 subtitle-1">
                Conservative is feature rich, simplistic, highly performance centric
                ashboard Template.
              </p>
              <!-- Store Buttons -->
              <div class="button-group store-buttons d-flex">
                <a href="#">
                  <img src="http://theme-land.com/sApp/demo/assets/img/icon/google-play.png" alt />
                </a>
                <a href="#">
                  <img src="http://theme-land.com/sApp/demo/assets/img/icon/app-store.png" alt />
                </a>
              </div>
              <span
                class="d-inline-block text-white fw-3 font-italic mt-3"
              >* Available on iPhone, iPad and all Android devices</span>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-6">
            <!-- home Thumb -->
            <div class="home-thumb mx-auto mt-20">
              <img src="../../assets/img/home/home.png" alt />
            </div>
          </div>
        </div>
      </div>
      <!-- Shape Bottom -->
      <div class="shape-bottom">
        <svg
          viewBox="0 0 1920 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="svg replaced-svg"
        >
          <title>Shape</title>
          <desc>Created with shape</desc>
          <defs />
          <g id="Landing-Page" stroke="none" stroke-width="12" fill="none" fill-rule="evenodd">
            <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#ffffff">
              <path
                d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                id="v1.0"
              />
            </g>
          </g>
        </svg>
      </div>
    </section>

    <section id="stats" class="py-3">
      <div class="container-home">
        <div class="row justify-content-center">
          <div class="col-5 col-sm-3 text-center">
            <h1 class="text-voilet">
              <b>5M</b>
            </h1>
            <div class="mb-3"></div>
            <h5 class="text-voilet">Responsive</h5>
          </div>
          <div class="col-5 col-sm-3 text-center">
            <h1 class="text-voilet">
              <b>+50</b>
            </h1>
            <div class="mb-3"></div>
            <h5 class="text-voilet">Components</h5>
          </div>
          <div class="col-5 col-sm-3 text-center">
            <h1 class="text-voilet">
              <b>2.5</b>
            </h1>
            <div class="mb-3"></div>
            <h5 class="text-voilet">Vuetify</h5>
          </div>
          <div class="col-5 col-sm-3 text-center">
            <h1 class="text-voilet">
              <b>2</b>
            </h1>
            <div class="mb-3"></div>
            <h5 class="text-voilet">Layout</h5>
          </div>
        </div>
      </div>
    </section>

    <section id="about-me">
      <section class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-5 mx-auto text-center mb-5">
              <h2 class="headline font-weight-bold mb-3">Demos</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="demo-box">
                <a class="img-fluid" @click="goDashboard1()" target="_blank">
                  <img src="../../assets/img/home/1.png" alt="demo-img" />
                </a>

                <h4>Dashboard 1</h4>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="demo-box">
                <a class="img-fluid" @click="goDashboard2()" target="_blank">
                  <img src="../../assets/img/home/2.png" alt="demo-img" />
                </a>

                <h4>Dashboard 2</h4>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="demo-box">
                <a class="img-fluid" @click="goDashboard3()" target="_blank">
                  <img src="../../assets/img/home/3.png" alt="demo-img" />
                </a>

                <h4>Dashboard 3</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="../../assets/img/bg/4.jpg"
      >
        <v-card class="transparent elevation-0" fill-height>
          <v-row class="mx-auto">
            <v-col v-for="[value, title] of stats" :key="title" cols="12" md="3">
              <div class="text-center">
                <div class="display-3 font-weight-black white--text mb-4" v-text="value"></div>

                <div class="title font-weight-regular white--text text-uppercase" v-text="title"></div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-parallax>
    </section>

    <base-footer></base-footer>
  </v-app>
</template>
<script>
import BaseFooter from "../../pages/layout/Base/BaseFooter.vue";
export default {
  components: { BaseFooter },
  data() {
    return {
      features: [
        {
          icon: "mdi-account-group-outline",
          title: "Fully Responsive",
          text:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam"
        },
        {
          icon: "mdi-update",
          title: "Rich Components",
          text:
            "Sed ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit. Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit."
        },
        {
          icon: "mdi-shield-outline",
          title: "Well Documentation",
          text:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam"
        }
      ],
      stats: [
        ["24k", "Github Stars"],
        ["330+", "Releases"],
        ["1m", "Downloads/mo"],
        ["5m", "Total Downloads"]
      ]
    };
  },
  methods: {
    goDashboard1() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard1");
    },
    goDashboard2() {
      let themevertical = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard2");
    },
    goDashboard3() {
      let themevertical = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard3");
    },
    goHDashboard1() {
      let themevertical = false;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard1");
    },
    goHDashboard2() {
      let themevertical = false;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard2");
    },
    goHDashboard3() {
      let themevertical = false;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      return this.$router.push("/dashboard/dashboard3");
    }
  }
};
</script>

<style scoped>
.text-voilet {
  color: #482554;
}
.home-intro {
  padding-left: 50px;
}
.home-area {
  height: 800px;
  z-index: 1;
}
.bg-voilet {
  background: #482554;
}
.home-thumb {
  max-width: 500px;
  padding-top: 70px;
  margin-top: 30px;
}
.bg-overlay,
.overlay-dark {
  position: relative;
  z-index: 0;
}
.logo-home img {
  width: 200px;
}
.fw-3 {
  font-weight: 300;
}
.home-area .shape-bottom {
  z-index: -1;
}
.shape-bottom {
  position: absolute;
  top: auto;
  bottom: -7px;
  left: 0;
  right: 0;
}
.button-group {
  margin-top: 30px;
}
.store-buttons img {
  max-width: 190px;
}

.store-buttons a {
  text-align: left;
}
.button-group a {
  margin-right: 10px;
}
.bg-home {
  background-color: #482554;
}
.home-text {
  padding: 100px 0;
}

.home-text h1 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 30px;
}
.home-text p {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  padding: 0 20px;
  margin-bottom: 30px;
}

.banner {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 100px;
  border: 5px double #dee6f1;
  box-shadow: 0px 0px 0px 0.25px #dee6f1;
  margin-top: -50px;
}
.demo-layout img {
  width: 300px;
  height: 100%;
  text-align: center !important;
}
.banner img {
  height: 46px;
}

.demo-box {
  padding: 20px 0 30px 0;
  text-align: center;
  position: relative;
}
.demo-box a {
  border: 8px solid #ffffff;
  display: block;
  transition: all 400ms ease-in-out;
  box-shadow: 0 1px 50px rgba(153, 153, 153, 0.35);
}

.demo-box img {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}
.demo-box h4 {
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 24px;
  font-size: 16px;
}
.features-content i {
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 50%;
  display: inline-block;
  margin: 12px 0;
  font-size: 24px;
}

.features-content i.one {
  background-color: rgba(255, 103, 155, 0.1);
  color: #ff679b;
}
.features-content i.two {
  background-color: rgba(249, 188, 11, 0.1);
  color: #f9bc0b;
}
.features-content i.three {
  background-color: rgba(10, 207, 151, 0.1);
  color: #0acf97;
}

.features-content h5 {
  font-weight: 600;
}

.img-fluid img {
  height: 280px;
}
/*Ribbon*/

.ribbon {
  position: absolute;
  right: -5px;
  top: 16px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #ff5da0;
  background: linear-gradient(#ff5da0 0%, #ff5da0 100%);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #ff5da0;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff5da0;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #ff5da0;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff5da0;
}

@media (max-width: 1024px) {
  .banner img {
    height: 20px;
    width: 500px;
  }
}

@media (max-width: 767px) {
  .banner img {
    height: 20px;
    width: 335px;
  }
}

@media (max-width: 414px) {
  .banner img {
    height: 20px;
    width: 310px;
  }
}
</style>