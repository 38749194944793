<template>
  <div>
    <v-row>
      <div class="col-md-5 px-0">
        <div class="category_item">
        <b-button class="import_cate" @click="modal_allowace = !modal_allowace" v-b-modal.modal-xl variant="light">Import Category</b-button>
          <h4>Category pointers:</h4>
          <ul>
            <li>
              Setting order for a category will help arrange and display it in a
              meaningful way.
            </li>
            <li>
              Assign suppliers and subcontractors for categories that involve
              allowances.
            </li>
          </ul>
          <br /><br />
          <h4>Items pointers:</h4>
          <ul>
            <li>Add units and category for items from an existing set.</li>
            <li>
              Setting order for an items will help arrange and display it in a
              meaningful way.
            </li>
            <li>
              Providing descriptions helps other users understand the item with
              better clarity.
            </li>
            <li>Provide default values will speed up the quoting process.</li>
          </ul>
        </div>
        <!-- User Config Modal -->
              <categoryImport :is_open="modal_allowace"></categoryImport>
        <!-- User Config Modal End-->
      </div>
      <div class="col-md-7 category_configuration">
        <category></category>
      </div>
    </v-row>
  </div>
</template>

<script>

import category from './Categories/Category.vue'
import categoryImport from './Categories/CategoryImport.vue'
export default {
  name: "categorycomponent",
  components: {
    category,
    categoryImport
  },
  data(){
    return{
      modal_allowace: false,
    }
  },
};
</script>
<style scoped>
  .import_cate{
    border: 3px solid rgb(103, 33, 120);
    color: rgb(103, 33, 120);
    font-weight: 500;
    padding: 6px 12px;
    letter-spacing: 0.5px;
    font-size: 13px;
    text-align: left;
    vertical-align: baseline;
    font-size: 14px !important;
  }
</style>

