<!--===========================
=            LOGIN            =
============================-->

<template>
  <v-app light>
    <section id="welcome">
      <div class="container">
        <form action="" method="post" @submit.prevent="login">
          <v-row class="main_login">
            <div class="col-md-6 col-12 m-auto">
              <img
                width="100%"
                src="../../assets/img/bg-login.png"
                alt="Vuetify.js "
              />
            </div>
            <div class="col-md-5 col-12 m-auto">
              <!-- Log In page -->
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <!--end auth-logo-box-->
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">LOGIN</h4>
                        <p class="text-muted mb-0">
                          Sign in to continue to Saka
                        </p>
                      </div>
                      <!--end auth-logo-text-->
                      <v-card-text>
                          <p class="login_error" >{{ error }} </p>
                       <v-form>
                          <v-text-field
                            label="Email"
                            name="email"
                            prepend-icon="person"
                            type="text"
                            v-model="formData.email"
                          />
                           <div v-if="submitted && $v.formData.email.$error" class="invalid-feedback">
                              <span v-if="!$v.formData.email.required">Email is required</span>
                              <span v-if="!$v.formData.email.email">Email is invalid</span>
                          </div>
                          <v-text-field
                            id="password"
                            label="Password"
                            name="password"
                            v-model="formData.password"
                            prepend-icon="lock"
                            type="password"
                          />
                        </v-form>
                        <div v-if="submitted && $v.formData.password.$error" class="invalid-feedback">
                            <span v-if="!$v.formData.password.required">Password is required</span>
                            <span v-if="!$v.formData.password.minLength">Password must be at least 8 characters</span>
                        </div>

                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn type="submit" color="primary">Login</v-btn>
                      </v-card-actions>
                      <v-card-actions class="login_footer">
                        <v-spacer />
                        <h6>Not a member yet ?</h6>
                        <v-btn @click="register()" class="ml-3" color="info"
                          >Register</v-btn>
                          <v-btn @click="forgot()" class="ml-3" color="info"
                          >Forgot Password</v-btn>
                      </v-card-actions>
                      <!--end form-->
                    </div>
                    <!--end /div-->
                  </div>
                  <!--end card-body-->
                </div>
                <!--end card-->
              </div>
              <!--end auth-page-->
            </div>
          </v-row>
        </form>
      </div>
    </section>
  </v-app>
</template>
<script>
// import library
// import axios from 'axios'
// import { API_BASE_URL } from '@/config'
import Vue from "vue";
import { mapGetters, mapActions, mapState } from 'vuex'
import VueSimpleAlert from "vue-simple-alert";
import { required, email, minLength } from "vuelidate/lib/validators";

Vue.use(VueSimpleAlert);
export default {
  props: {
    source: String,
  }, 
  data(){
    return{
      loading: false,
      drawer: null,
      formData: {
        email: "",
        password: "",
      },
      submitted: false,
      error:null
    }
  },
   validations: {
        formData: {
            email: { required, email },
            password: { required, minLength: minLength(8) },
        }
    },
  methods: {
    // ...mapActions(['signIn','countCategories']),
    login(event) {
       this.submitted = true;
          // stop here if form is invalid
          this.$v.$touch();
          if (this.$v.$invalid) {
              return;
          }
        // this.signIn(this.formData); 
        this.$store.dispatch("signIn",this.formData).then((response) => {
        console.log(response);
        if(response.data.status == true){
          this.$router.push("/dashboard");
            // this.$fire({
            //   title: "Login Successfully.",
            //   type: "success",
            //   timer: 4000
            // });
          }
          else if(response.data.status == false){ 
            this.$fire({
              title: response.data.message,
              type: "warning",
              timer: 4000
            });
          }
      });
    },
    register() {
      setTimeout(() => {
        this.$router.push("/session/register");
      }, 500);
    },
    forgot() {
      setTimeout(() => {
        this.$router.push("/session/forgot");
      }, 500);
    }
  },
  // computed: {
  //   ...mapGetters(['error']),
  // },
  created(){
    console.log(this.$store.getters.error);
     this.$store.dispatch("getProfile").then((success) => {
          this.userDetail = success; 
          if(this.userDetail){
              this.$router.push("/dashboard");
          }
      });
  }
};

</script>
<style>
.select_cate_css .col-form-label {
  font-size:26px;
  font-weight: 500;
  border-bottom: 1px solid #3333;
}
.select_cate_css .custom-control-label span{
  font-size:18px;
}
.select_cate_css{
  padding:20px;
  background: aliceblue;
}
.col-form-label ~ div{
  max-height: 360px;
  overflow: auto;
  border-bottom: 1px solid #3333;
}
.login_error{
    color: red;
    text-align: center;
    margin-bottom: 0px!important;
    font-weight: 500;
    font-style: italic;
}
.invalid-feedback {
    margin-top: 0rem;
    font-size: 100%;
    text-align: end;
    font-style: italic;
}
@media (max-width: 576px) {
  .main_login img {
    display: none;
  }
}
.login_footer {
  border-radius: 0 0 5px 5px;
  background: rgb(225, 234, 235);
  background: -moz-repeating-linear-gradient(
    -45deg,
    rgb(247, 247, 247),
    rgb(247, 247, 247) 15px,
    rgb(225, 234, 235) 15px,
    rgb(225, 234, 235) 30px,
    rgb(247, 247, 247) 30px
  );
  background: -webkit-repeating-linear-gradient(
    -45deg,
    rgb(247, 247, 247),
    rgb(247, 247, 247) 15px,
    rgb(225, 234, 235) 15px,
    rgb(225, 234, 235) 30px,
    rgb(247, 247, 247) 30px
  );
  background: -o-repeating-linear-gradient(
    -45deg,
    rgb(247, 247, 247),
    rgb(247, 247, 247) 15px,
    rgb(225, 234, 235) 15px,
    rgb(225, 234, 235) 30px,
    rgb(247, 247, 247) 30px
  );
  background: repeating-linear-gradient(
    -45deg,
    rgb(247, 247, 247),
    rgb(247, 247, 247) 15px,
    rgb(225, 234, 235) 15px,
    rgb(225, 234, 235) 30px,
    rgb(247, 247, 247) 30px
  );
}
</style>
<!--====  End of LOGIN  ====-->
