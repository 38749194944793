<template>
<div>
    <b-modal v-model="is_open" hide-footer hide-header ok-only scrollable id="vb-close" class="p-0" size="lg">
        <form method="post" @submit.prevent="submitImportCategory()">
        <v-container>
            <v-row>
                <div class="col-md-12 p-0">
                    <div class="madal_dead text-center">
                        <h2>Import Category</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="select_cate_css border">
                        <b-form-checkbox v-for="item in globalCategories" :key="item.id"
                            v-model="formData.category"
                            color="primary"
                            :value="item.id"
                            switch> {{ item.name }}
                        </b-form-checkbox>
                        <!-- <b-form-checkbox size="lg" v-model="selected" :options="options" switch>Select All Category</b-form-checkbox> -->
                    </div>
                </div>
                <div class="col-md-12 p-0">
                    <div class="madal_dead text-center">
                    </div>
                </div>
                <div class="col-md-12 m-auto p-0">
                    <div class="madal_dead mt-3 text-center">
                        <b-button type="submit" variant="success">SET CATEGORY</b-button>
                        <b-button class="w-25 float-right model_remore" variant="danger" @click="$bvModal.hide('vb-close')">CLOSE</b-button>
                    </div>
                </div>
            </v-row>
        </v-container>
        </form>
    </b-modal>
</div>
</template>
<script>
import { mapGetters , mapActions } from "vuex";
export default {
    name:"categoryimport",
    props:['is_open'],
    data() {
       return{
           formData:{
               category: [], // Must be an array reference!
           },
           is_opened:this.is_open,
       };
    },
    methods:{
        ...mapActions(['getGlobalCategories']),

        submitImportCategory(){
            if(this.formData.category.length==0){
            this.$fire({
                title: "Please Select Category.",
                type: "warning",
                timer: 4000
            });
            return;
            }
            else{
            this.$store.dispatch("importedCategory", this.formData)
                .then((data) => {
                     this.$fire({
                        title: "Category Imported Successfully!",
                        type: "success",
                        timer: 4000
                    });
                    $(".model_remore").click();
                    this.$store.dispatch('initCategories');
            });
            }
        }
    },
    computed:{
        ...mapGetters(["globalCategories"]),
    },
    created(){
        // this.cate_options = this.globalCategories;
        this.getGlobalCategories();
        // console.log(this.cate_options[1].name)
        // this.options = this.globalCategories
        // this.$store.dispatch("globalCate").then((success) => {
        //     this.options = this.$store.getters.globalCategories;
        // });
    },
}
</script>
<style>
    .v-input--selection-controls.v-input .v-label{
        margin: 0px!important;
    }
    
</style>