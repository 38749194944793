<template lang="">
  <v-row>
    <div class="col-md-12">
      <div class="status_table">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="UnitData"
            :headers="headers"
            :items="itemsWithSno"
            :search="search"
            :loading = "loading"
            loading-text="Loading... Please wait"
            :items-per-page="15"
            hide-default-footer>
            <template v-slot:item.appsec="{ item }">
              <button v-if = (item.approved)>  Yes </button>
              <button v-else>No </button> 
            </template>
            <template v-slot:item.actions="{ item }">
              <button
                v-b-modal.modal-unit
                v-b-tooltip.hover
                title="Edit"
                @click="editRow(item.id)">
                <v-icon small>mdi-pencil</v-icon>
              </button>
              <button 
                v-b-tooltip.hover
                title="Delete"
                @click="deleteRow(item.id)" class="text-danger">
                <v-icon small> mdi-delete</v-icon>
              </button>
            </template>
          </v-data-table>
          <v-pagination
            v-model="currentPage"
            :length="lastPage"
            total-visible="10">
          </v-pagination>
        </v-card>
      </div>
    </div>
    <div class="col-md-12">
      <div class="configuration_footer">
        <v-row>
          <div class="col-md-5">
            <b-btn
            @mouseover="updateToSaveBtn"
            
              class="btn-info category_select"
              v-b-modal.modal-unit
              ><i class="fa fa-plus-circle"></i> Add Unit</b-btn
            >
            <b-modal
              v-model="is_open"
              id="modal-unit"
              hide-footer
              size="lg"
              centered
              :title="modalHeaderName"
            >
              <v-row>
                <div class="col-md-4">
                  <label for="">Create a new Unit</label>
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    v-model="formData.name"
                    value=""
                    placeholder="Enter unit name..."
                  />
                  <div v-if="submitted && !$v.formData.name.required" class="invalid-feedback">Name is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">Unit Type</label>
                </div>
                <div class="col-md-8">
                  <multiselect
                    v-model="formData.type"
                    :options="unit_type"
                    placeholder="Select Unit"
                    :multiple="false"
                  ></multiselect>
                  <div v-if="submitted && !$v.formData.type.required" class="invalid-feedback">Unit type is required</div>
                </div>
              </v-row>
              <v-row>
                <div class="col-md-4">
                  <label for="">Check/Uncheck</label>
                </div>
                <div class="col-md-8">
                    <v-checkbox
                      v-model="formData.global"
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                      :label="`Global`"
                    ></v-checkbox>
                  <!-- <b-form-checkbox
                    v-model="formData.global"
                    :options="options"
                    class="mb-3"
                    :value="formData.global"
                    :checked="formData.global"
                  >
                  Global {{ formData.global }}
                  </b-form-checkbox> -->
                </div>
              </v-row>
              <div class="cancel_save_btn text-right">
                <v-btn @click="saveForm()" rounded color="primary" v-if="isedit">Save</v-btn>
                <v-btn @click="updateForm()" rounded color="primary" v-else>Update</v-btn>
              </div>
            </b-modal>
          </div>
          <div class="col-md-2"></div>
        </v-row>
      </div>
    </div>
  </v-row>
</template>
<script>
import Vue from "vue";
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators';
import Multiselect from "vue-multiselect";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
Vue.use(VueGoodTable);
export default {
  name: "unit",
  components: {
    Multiselect,
    VueGoodTable,
  },
  data() {
    return {
      selected: false,
      options: [{ item: "global", name: "global" }],
      isedit: true,
      modalHeaderName:'Update Unit',
      submitted: false,
      formData: {
        name: "",
        type: "",
        global:"",
      },
      unit_type: ["None", "Length", "Area", "Volume"],
      loading:false,
      is_open:false,
      search:"",
      headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Unit",
          value: "name",
        },
        {
          text: "Unit Type",
          value: "type",
        },
        {
          text: "Approved",
          value: "appsec",
        },
        {
          text: "Action",
          value: "actions",
        },
      ],
      UnitData: [],
    };
  },
    validations: {
     formData: {
        name: { required },
        type:{ required },
      }
    },
    watch: {
    currentPage( newVal, oldVal){
      this.paginatePage(newVal);
    },
     deep: true
  },

  computed:{
    itemsWithSno() {
      return this.UnitData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        return this.$store.getters.unit.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
        }
    },
    lastPage:{
      get(){
          return this.$store.getters.unit.last_page; // get last page
      }
    }
  },
  methods: {
    updateToSaveBtn(){
      this.isedit = true;
      this.formData = {};
      this.submitted = false;
      this.modalHeaderName = 'Add Unit';
    },
    paginatePage(pageNumber){
      this.loading = true;
        this.$store.dispatch("initUnits", pageNumber).then((response) => {
          console.log(response);
            this.UnitData = response.data;
            if(response){
              this.loading = false;
            }
        });
    },
    init() {
      this.$store.dispatch("initUnits").then((response) => { 
        // this.UnitData = this.$store.state.units.units;
        this.UnitData = response.data;
      });
    },
    saveForm: function() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
        this.$store.dispatch("addUnit", this.formData).then((response) => {
          console.log(response);
          if(response.status == 422){
            this.$fire({
             title: "The name has already been taken.",
             type: "warning",
             timer: 4000
           });
          }
          if(response.data.status == true){
            this.$fire({
             title: "Unit Added Successfully!",
             type: "success",
             timer: 4000
           });
          this.init();
          this.is_open = false;
          }
        });
     },
    updateForm: function() {
        this.$store.dispatch("updateUnit", this.formData).then((data) => {
          console.log(data);
          this.$fire({
              title: "Unit Updated Successfully!",
              type: "success",
              timer: 4000
            });
          this.init();
          this.is_open = false;
        });
    },
    isedit: function(){
      this.init();
    },
    editRow: function(id) {
      this.isedit = false;
      this.modalHeaderName = 'Update Unit';
      this.$store.dispatch("editUnit", id).then((data) => {
        this.formData = data;
      });
    },
    deleteRow: function(id) {
      this.$confirm("","Are you sure?","question").then(() => {
        this.$store.dispatch("deleteUnit", id).then((success) => {
          if (success.status == 200) {
           this.$fire({
            title: "Unit Deleted Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
        }
          console.log(success);
          this.init();
        }); 
      });
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("initUnits").then((response) => { 
      console.log(response);
      this.UnitData = response.data;
      if(response){
        this.loading = false;
      }
    });
  },
  // watch: {
    // "$store.state.state.units.units"() {
    //   this.unit_rows = this.$store.state.units.units;
    // },
  // },
};
</script>
<style scoped>
  .invalid-feedback {
    text-align: start;
}
</style>