<!--================================
=            BASEHEADER            =
=================================-->
<template>
  <div class="transparent base-header">
    <v-list-item three-lin>
      <v-list-item-avatar class="elevation-2" tile size="80" color="primary">
        <i style="color:white" class="fa fa-home fa-2x"></i>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="overline mb-4">
          <slot name="page-header"></slot>
        </div>
        <v-list-item-title class="title font-weight-bold mb-1">
          <slot name="page-header"></slot>
        </v-list-item-title>
        <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: "base-header",
  data() {
    return {
      title: "Dashboard",
      breadcrumbs: []
    };
  },
  watch: {},
  methods: {}
};
</script>
<style scoped>
</style>
<!--====  End of BASEHEADER  ====-->