<template lang="">
    <div class="container-fluid my-2">
    <!-- HEADER -->
    <v-row no-gutters>
      <div class="col-md-4">
          <div class="home_box">
              <h3>QUICK ACTIONS</h3>
              <div class="d-flex justify-content-between">
                  <router-link v-bind:class="{user:true}" to="/quotes/view"><b-button class="btn btn-light">View Quotes</b-button></router-link>
                  <router-link v-bind:class="{user:true}" to="/quotes/add"><b-button class="btn btn-light">New Quotes</b-button></router-link>
              </div>
              <h3>STATISTICS</h3>
            <!-- <b-button class="import_cate" @click="modal_allowace = !modal_allowace" v-b-modal.modal-xl variant="light">Import Category</b-button> -->
          </div>
      </div>
    </v-row>
    <!-- HEADER END-->
     <!-- User Config Modal -->
              <categoryImport :is_open="modal_allowace"></categoryImport>
        <!-- User Config Modal End-->
    </div>
</template>
<script>
import categoryImport from '../../components/settings/Categories/CategoryImport.vue'
export default {
    name:'main',
    components:{
        categoryImport
    },
    data(){
            return {
                set:true,
                modal_allowace: false,
            }
        },
        created(){
            console.log('auth data i dashboard');
            console.log('localstorage');
            console.log(JSON.parse(localStorage.getItem('auth')));
            const count_categories = this.$store.getters.auth.user.count_categories;
            const workspace_role = this.$store.getters.auth.user.workspace_role;
            // console.log(this.$store.getters.auth.user);
            // console.log(count_categories);
            // console.log(workspace_role);
            if(count_categories == 0  && (workspace_role == 'Workspace Owner' || workspace_role == 'Workspace Admin')){
                this.modal_allowace = true;
            }
            else{
                this.modal_allowace = false;
            }
            
        }
}
</script>
<style>
    .v-content__wrap{
        width: 100%;
        height: 100%;
        z-index: 2;
        background-image: url(../../assets/img/home_banner.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
    }
    .v-content.v-content__wrap{
        background-image: none;
    }
    .home_box{
        width: 560px;
        height: 432px;
        z-index: 2;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 32px;
        left: 48px;
        padding: 30px;
    }
    .home_box button{
        z-index: 2;
        border-left: 4px solid rgb(0, 176, 190);
        border-radius: 2px;
        padding: 20px 40px;
        -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 0 1px rgb(0 0 0 / 40%);
        box-shadow: 0 0 1px rgb(0 0 0 / 40%);
        font-size: 18px;
        color: #333;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        font-weight: 500;
        margin: 30px 0px;
    }
     .home_box button:hover{
          background-color: #d4d4d4!important;
           border-color: rgb(0, 176, 190)!important;
     }
     .home_box button:active{
          background-color: #d4d4d4!important;
           border-color: rgb(0, 176, 190)!important;
     }
     .home_box h3{
        font-size: 20px;
        font-weight: 600;
        color: #343434;
        position:relative;
     }
     .home_box h3:before{
        position: absolute;
        content:"";
        background: #00b0be;
        height: 3px;
        width: 14%;
        left: 0px;
        bottom: -7px;
     }
</style>