<!--=================================
=            BASE FOOTER            =
==================================-->
<template>
  <!-- base-footer -->
  <v-footer dark  :color="appConfig.color" :inset="appConfig.footer.inset" app>
    <span class="px-4 font-weight-bold">&copy; {{ new Date().getFullYear() }} Pollysys</span>
  </v-footer>
</template>
<script>
export default {
  name: "base-footer",
  data: () => ({
    icons: [
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-google-plus",
      "fab fa-linkedin",
      "fab fa-instagram"
    ]
  })
};
</script>
<style scoped>
.v-footer {
  z-index: inherit;
}
</style>
<!--====  End of BASE FOOTER  ====-->