<template>
<div>
            <div class="col-md-12">
                <div class="top_bar">
                    <h2><i class="fas fa-users"></i> PROFILE</h2>
                </div>
                <div class="home_box1">
                    <v-row>
                        <div class="col-md-4">
                            <div class="profile_page select_cate_css">
                                <div class="d-flex justify-content-center">
                                    <div class="card col-11 p-4">
                                        <div class="image d-flex flex-column justify-content-center align-items-center">
                                            <div>
                                              <img v-if="userDetail.userprofile.profile_image" :src="userDetail.userprofile.profile_image"/>
                                              <img v-else src="../../assets/img/default.png" />
                                            </div>
                                            <span class="name mt-3">{{ userDetail.name }}</span>
                                            <span class="email">{{ userDetail.email }}</span>
                                            <div class="d-flex  mt-3">
                                                <span class="number font-weight-bold">Mobile:</span>
                                                <span class="number">{{ userDetail.userprofile.mobile }}</span>
                                            </div>
                                            <div class="d-flex mt-3">
                                                <span class="number font-weight-bold">D.O.B:</span>
                                                <span class="number">{{ userDetail.userprofile.dob }}</span>
                                            </div>
                                            <div class="d-flex mt-3">
                                                <span class="number font-weight-bold">Gender:</span>
                                                <span class="number">{{ userDetail.userprofile.gender }}</span>
                                            </div>
                                        </div>
                                        <v-row>
                                            <div class="mt-3">
                                                <span class="number font-weight-bold mt-2">Social Links:</span>
                                                <span class="number mt-2" v-for="(social,k) in userDetail.userprofile.social_links" :key="k">
                                                  <a :href="social.link" target="blank">{{ social.name }}</a>
                                                  </span>
                                            </div>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                          <v-form enctype="multipart/form-data" @submit.prevent = "profileUpdate">
                            <div class="profile_update select_cate_css">
                              <h5>Update Profile</h5>
                                <v-row>
                                    <div class="col-md-12">
                                        <v-text-field 
                                        v-model="userDetail.name" 
                                        label="Name" 
                                        :rules="rules" 
                                        hide-details="auto"></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field 
                                        v-model="userDetail.userprofile.mobile" 
                                        label="Mobile" 
                                        hide-details="auto"></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="file" class="inputFile form-control" @change="onFileChange" accept="image/*,.doc, .docx,.txt,.pdf,.ppt,.xls,.xlsx" id="file-input">
                                    </div>
                                    <div class="col-md-12">
                                        <v-select 
                                          v-model="userDetail.userprofile.gender" 
                                          :items="gender_items" 
                                          label="Select"
                                          chips
                                          hint="Pick Gender"
                                          persistent-hint></v-select>
                                    </div>
                                    <div class="col-md-12">
                                        <v-menu>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field 
                                                v-model="userDetail.userprofile.dob" 
                                                label="D.O.B"  readonly 
                                                v-bind="attrs" 
                                                v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                            v-model="userDetail.userprofile.dob" 
                                            @input="menu = false"></v-date-picker>
                                        </v-menu>
                                    </div>
                                    <div class="col-md-12">
                                      <h6 class="border-bottom pb-2">Add/Update Social Link</h6>
                                      <div v-for="(input,k) in userDetail.userprofile.social_links" :key="k">
                                              <v-text-field 
                                                v-model="input.name" 
                                                value="input"
                                                label="Name" 
                                                hide-details="auto">
                                              </v-text-field>
                                              <v-text-field 
                                                v-model="input.link" 
                                                value="input"
                                                label="URL" 
                                                hide-details="auto">
                                              </v-text-field>
                                          <div class="text-right mt-2">
                                            <i class="fas fa-minus-circle text-danger" @click="remove(k)" v-show="k || ( !k && userDetail.userprofile.social_links.length > 1)">Remove</i>
                                            <i class="fas fa-plus-circle text-success ml-2" @click="add(k)" v-show="k == userDetail.userprofile.social_links.length-1">Add fields</i>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                        <v-btn rounded type="submit" color="primary">Update Profile <i v-if="loading.profile" class="fas fa-sync fa-spin"></i><i v-else class="far fa-edit"></i></v-btn>
                                        <!-- <v-progress-circular v-if="loading.profile" class="ml-2" indeterminate color="primary"></v-progress-circular> -->
                                    </div>
                                </v-row>
                            </div>
                            </v-form>
                        </div>
                        
                        <div class="col-md-4">
                            <div class="profile_update select_cate_css" v-if="userDetail.userprofile.mobile">
                              <h5>Verify Phone</h5>
                              <v-form>
                                    <v-row>
                                        <div class="col-md-12">
                                            <v-text-field v-if="issetOpt && userDetail.userprofile.mobile_verified == 0"
                                              v-model="userDetail.mobile_verification_code" 
                                              label="Enter OTP" 
                                              hide-details="auto"></v-text-field>
                                        </div>
                                        <div class="col-md-12">
                                          <v-btn rounded @click="send_verification" color="primary" v-if="userDetail.userprofile.mobile_verified == 0">Send Verification code <i v-if="loading.phone" class="fas fa-sync fa-spin"></i> <i v-else class="fas fa-paper-plane"></i></v-btn>
                                          <v-btn rounded @click="verifyOTP" color="primary" class="ml-1" v-if="issetOpt && userDetail.userprofile.mobile_verified == 0">Verify Phone <i v-if="loading.verifyPhone" class="fas fa-sync fa-spin"></i> <i v-else class="fas fa-check"></i></v-btn>
                                          <v-btn rounded color="success" class="ml-1" v-if="userDetail.userprofile.mobile_verified == 1">Mobile verified <i class="fas fa-check"></i></v-btn>
                                        </div>
                                    </v-row>
                                </v-form>
                            </div>

                            <div class="profile_update select_cate_css mt-4">
                              <h5>Update Email</h5>
                              <v-btn rounded color="success" class="ml-1" v-if="userDetail.email_verified_at">Email verified <i class="fas fa-check"></i></v-btn>
                              <v-form @submit.prevent = "updateEmail">
                                  <v-row>
                                    <div class="col-md-12">
                                        <v-text-field 
                                          v-model="userDetail.email" 
                                          label="Email"
                                          type="email" 
                                          prepend-icon="person"
                                          hide-details="auto"></v-text-field>
                                       <div class="error_sms invalid-feedback">{{ email_error }}</div>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                          v-model="userDetail.password" 
                                          type="password"
                                          label="Enter Password"
                                          prepend-icon="lock" 
                                          hide-details="auto"></v-text-field>
                                        <div class="error_sms invalid-feedback">{{ pass_error }}</div>
                                    </div>
                                    <div class="col-md-12">
                                        <v-btn rounded type="submit" color="primary">Update Email <i v-if="loading.email" class="fas fa-sync fa-spin"></i> <i v-else class="far fa-envelope"></i></v-btn>
                                        <!-- <b-button type="submit" variant="info">Update Email <i class="far fa-envelope"></i></b-button> -->
                                    </div>
                                  </v-row>
                                </v-form>
                            </div>

                            <div class="profile_update select_cate_css mt-4">
                              <h5>Change Password</h5>
                              <v-form @submit.prevent = "changePassword">
                                  <v-row>
                                      <div class="col-md-12">
                                          <v-text-field 
                                            v-model="userDetail.old_password" 
                                            label="Old Password" 
                                            :rules="rules" 
                                            type="password"
                                            prepend-icon="lock"
                                            hide-details="auto"></v-text-field>
                                          <div v-if="submitted && !$v.userDetail.old_password.required" class="invalid-feedback">Old Password is required</div>

                                      </div>
                                      <div class="col-md-12">
                                          <v-text-field 
                                            id="password"
                                            v-model="userDetail.password" 
                                            label="New Password" 
                                            :rules="rules" 
                                            type="password"
                                            prepend-icon="lock"
                                            hide-details="auto"></v-text-field>
                                          <div v-if="submitted && $v.userDetail.password.$error" class="invalid-feedback">
                                              <span v-if="!$v.userDetail.password.required">New password is required</span>
                                              <span v-if="!$v.userDetail.password.minLength">Password must be at least 8 characters</span>
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          <v-text-field 
                                            id="confirm_password"
                                            v-model="userDetail.confirm_password" 
                                            label="Confirm Password" 
                                            :rules="rules" 
                                            prepend-icon="lock"
                                            type="password"
                                            hide-details="auto"></v-text-field>
                                          <div v-if="submitted && $v.userDetail.confirm_password.$error" class="invalid-feedback">
                                              <span v-if="!$v.userDetail.confirm_password.required">Confirm new password is required</span>
                                              <span v-else-if="!$v.userDetail.confirm_password.sameAsPassword">Passwords must match</span>
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                        <v-btn rounded type="submit" color="primary">Change Password <i v-if="loading.password" class="fas fa-sync fa-spin"></i><i v-else class="fas fa-key"></i></v-btn>
                                        <!-- <b-button type="submit" variant="info">Change Password <i class="fas fa-key"></i></b-button> -->
                                      </div>
                                  </v-row>
                                </v-form>
                              </div>
                        </div>
                    </v-row>
                </div>
            </div>
       
  </div>
</template>

<script>
import { required, email, numeric, minValue, minLength, sameAs, requiredUnless } from 'vuelidate/lib/validators'
export default {
    name: "profile",
    data() {
        return {
          loading:{
            verifyPhone:false,
            phone:false,
            profile:false,
            email:false,
            phone:false,
            password:false,
          },
          email_error:'',
          pass_error:'',
          submitted:false,
          issetOpt:false,
          imagePreviewURL: null,
          gender_items: ['Male', 'Female'],
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          menu: false,
          userDetail: {
            name: "",
            email: "",
            old_password:"",
            password:"",
            confirm_password:"",
            mobile_verification_code:"",
            profile_image1:null,
              userprofile:{
                mobile: "",
                gender: "",
                social_links: [],
                dob: "",
                profile_image:null,
                mobile_verified:"",
              },
          },
      }
  },
  validations: {
    userDetail: {
        email:{ required },
        old_password:{ required },
        password: { required, minLength: minLength(8) },
        confirm_password: { required, sameAsPassword: sameAs('password') },
      }
  },
  methods: {
    onFileChange(e) {
      // const file = e; // in case vuetify file input
      // if (file) {
      //   this.imagePreviewURL = URL.createObjectURL(file);
      //   URL.revokeObjectURL(file); // free memory
      // } else {
      //   this.imagePreviewURL =  null
      // }
      let file = e.target.files[0];
          //this.formData.imageFile = files[0];
          let reader = new FileReader();
          reader.onloadend = (file) => {
              this.userDetail.profile_image1 = reader.result;
          }
          reader.readAsDataURL(file);
    },

    add () {
    this.userDetail.userprofile.social_links.push({
      name:'',
      link: '',
    })
      console.log(this.userDetail.userprofile.social_links)
    },

    remove (index) {
      this.userDetail.userprofile.social_links.splice(index, 1)
    },

    profileUpdate(e) {
          this.$confirm("","Are you sure?","question").then(() => {
            this.loading.profile = true;
            this.$store.dispatch("profileUpdate",this.userDetail).then((response) => {
            console.log(response);
            if(response.data.status == true){
              this.$fire({
                  title: "Profile updated successfully.",
                  type: "success",
                  timer: 4000
                });
                this.userDetail.userprofile.profile_image = response.data.user.userprofile.profile_image;
                this.userDetail.userprofile.mobile_verified = 0;
                this.loading.profile = false;
                window.location.reload();
            }
            else if(response.data.status == false){
              this.$fire({
                    title: "Something Missing!.",
                    type: "warning",
                    timer: 4000
                });
            }
        });
      });
    },

    changePassword(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      };
      this.loading.password = true;
      console.log(this.userDetail);
        this.$store.dispatch("changePassword", this.userDetail).then((response) => {
          if(response.status == true){
            this.$fire({
                  title: "Password updated successfully.",
                  type: "success",
                  timer: 4000
              });
              this.loading.password = false;
          }
          else if(response.status == false){
            this.$fire({
                  title: "Check your old password.",
                  type: "warning",
                  timer: 4000
              });
              this.loading.password = false;
          }
      });
    },
    send_verification(e){
      e.preventDefault();
       this.loading.phone = true;
        this.$store.dispatch("verifyMobile").then((response) => {
          console.log(response);
          if(response.status == true){
            this.$fire({
              title: "Verification code sent on your phone.",
                  type: "success",
                  timer: 4000
              });
            this.issetOpt = true;
            this.loading.phone = false;
          }
          else if(response.status == false){
            this.$fire({
                  title: "Something Missing.",
                  type: "warning",
                  timer: 4000
              });
            this.loading.phone = false;
          }
      });
    },
    verifyOTP(e) {
      e.preventDefault()
      this.loading.verifyPhone = true;
      this.$store.dispatch("verifyOtp",this.userDetail).then((response) => {
        console.log(response);
        if(response.status == true){
          this.$fire({
            title: "Thank you! Your phone number has been verified.",
                type: "success",
                timer: 4000
            });
          this.loading.verifyPhone = false;
          this.issetOpt = true;
          this.userDetail.userprofile.mobile_verified = 1;
        }
        else if(response.status == 422){
          this.$fire({
                title: "The mobile verification code field is required.",
                type: "warning",
                timer: 4000
            });
          this.loading.verifyPhone = false;
        }
        else if(response.status == false){
        this.$fire({
              title: "Please Check OTP.",
              type: "warning",
              timer: 4000
          });
        this.loading.verifyPhone = false;
        }
      });
    },

    updateEmail(e) {
        this.loading.email = true;
        this.$store.dispatch("updateEmail", this.userDetail).then((response) => {
          console.log(response);
          if(response.status == true){
            this.$fire({
                  title: "Email updated successfully.",
                  text: "Check your email for verification",
                  type: "success",
                  timer: 10000
              });
              this.loading.email = false;
          }
          else if(response.status == false){
            this.$fire({
                  title: response.message,
                  type: "warning",
                  timer: 4000
              });
            this.loading.email = false;
          }
          else if(response.status == 422){
            this.loading.email = false;
            this.pass_error = response.data.errors.password[0];
            this.email_error = response.data.errors.email[0];
          }
      });
    },
  },
  created() {
      this.$store.dispatch("getProfile").then((success) => {
          this.userDetail = success;
          this.userDetail.userprofile.social_links =  JSON.parse(success.userprofile.social_links);
          console.log((this.userDetail));
      });
  }
};
</script>
<style scoped>
  .number{
    font-size:14px;
  }
  i:hover{
    cursor: pointer;
  }
  .avatar-img img {
    width: 32px;
    border-radius: 50%;
  }
  .profile_page img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: auto;
  }
  .profile_page .name{ 
    font-size:14px;
  }
  .profile_page .email{ 
    font-size:14px;
    margin: 14px 0px;
  }
  .inputFile{
    background-color: transparent;
    padding: 0.375rem 0rem;
    line-height: 1.2;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid rgb(0 0 0 / 44%);
  }
</style>