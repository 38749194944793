// @ts-nocheck
import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'
//DASHBOARD
import Dashboard from "../pages/dashboard/Home.vue";
// construction
import baseSupplier from "../pages/suppliers/Base.vue";
import addSupplier from "../pages/suppliers/Add.vue";
import listSuppliers from "../pages/suppliers/List.vue";
import baseSubcontractor from "../pages/sub-contractors/Base.vue";
import addSubcontractor from "../pages/sub-contractors/Add.vue";
import listSubcontractors from "../pages/sub-contractors/List.vue";
import settings from "../pages/settings/Base.vue";
import homeSettings from "../pages/settings/Home.vue";
import CategoryCommon from "../pages/settings/Categories/CategoryCommon.vue";
import listQuotes from "../pages/quotes/List.vue";
import addQuote from "../pages/quotes/Add.vue"
import summary from "../pages/dash-branding/Summary.vue";
import baseQuotes from "../pages/quotes/Base.vue";
import addQuoteStep2 from "../pages/quotes/Add2.vue"
import EditQuote from "../pages/quotes/EditQuote.vue"
import ViewQuote from "../pages/quotes/ViewQuote.vue"
import RegisterClients from "../pages/quotes/RegisterClients.vue"
//import test from "../pages/quotes/Test.vue"

//SESSION
import Login from "../pages/session/Login.vue";
import Register from "../pages/session/Register.vue";
import Home from "../pages/session/Home.vue";
import Blank from "../pages/session/Blank.vue";
import Error505 from "../pages/session/Error505.vue";
import Error403 from "../pages/session/Error403.vue";
import Error404 from "../pages/session/Error404.vue";
import LockScreen from "../pages/session/LockScreen.vue";
import Profile from "../pages/session/Profile.vue";
import ForgotPassword from "../pages/session/ForgotPassword.vue";
import ResetPassword from "../pages/session/ResetPassword.vue";
import UserList from "../pages/session/UserList.vue";
import VerifyEmail from "../pages/session/VerifyEmail.vue";
import WorkSpaceList from "../pages/session/WorkSpaceList"

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "login",
        component: Login,
        meta: {
            public: true,
            transition: "zoom",
            Auth: true,
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
        /* beforeEnter(to, from, next) {
            console.log('store');
            console.log(store.state.user.auth.token);
            console.log(JSON.parse(localStorage.getItem('auth')))
            console.log('storeCall');
            //console.log(store.dispatch('autoLogin'));
            if (store.dispatch('autoLogin')) {
                console.log('why are you here');
                // next()
            } else {
                next({ name: 'login' })
            }
        } */
    },
    {
        path: "/workspace",
        name: "workspace-list",
        component: WorkSpaceList,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
    },
    {
        path: "/verifyemail",
        name: "verifyemail",
        component: VerifyEmail,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
    },

    {
        path: "/user-list",
        name: "user-list",
        component: UserList,
        meta: {
            breadcrumb: true,
            Auth: true,
        }
    },
    {
        path: "/summary",
        name: "summary",
        component: summary,
        meta: {
            breadcrumb: true,
            Auth: true,
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: {
            breadcrumb: true,
            Auth: true,
        }
    },
    {
        path: "/suppliers",
        name: "suppliers",
        redirect: { name: "list-suppliers" },
        component: baseSupplier,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
        children: [{
                path: "home",
                name: "list-suppliers",
                component: listSuppliers,
                meta: { breadcrumb: true },
            },
            {
                path: "add",
                name: "add-supplier",
                component: addSupplier,
                meta: { breadcrumb: true },
            },
            {
                path: "edit/:id",
                name: "edit-supplier",
                component: addSupplier,
                meta: { breadcrumb: true },
            },
        ],
    },
    {
        path: "/subcontractors",
        name: "subcontractors",
        redirect: { name: "list-subcontractors" },
        component: baseSubcontractor,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
        children: [{
                path: "home",
                name: "list-subcontractors",
                component: listSubcontractors,
                meta: { breadcrumb: true },
            },
            {
                path: "add",
                name: "add-subcontractor",
                component: addSubcontractor,
                meta: {
                    breadcrumb: true,
                },
            },
            {
                path: "edit/:id",
                name: "edit-subcontractor",
                component: addSubcontractor,
                meta: {
                    breadcrumb: true,
                },
            },
        ],
    },
    {
        path: "/settings",
        name: "settings",
        redirect: { name: "home-settings" },
        component: settings,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
        children: [{
                path: "home",
                name: "home-settings",
                component: homeSettings,
            },
            /* {
                path: "units",
                name: "units",
                component: Category,
            }, */
            /* {
                path: "status",
                name: "status",
                component: Category,
            }, */
            {
                path: "cat/:catslug",
                name: "catcommon",
                component: CategoryCommon,
            },
            /* {
                path: "cat/add",
                name: "add-category",
                component: Category,
            },
            {
                path: "cat/:catslug/edit",
                name: "edit-category",
                component: Category,
            },
            {
                path: "cat/:catslug/add",
                name: "add-item-category",
                component: Category,
            },
            {
                path: "cat/:catslug/:item",
                name: "item-category",
                component: Category,
            }, */
        ],
    },
    {
        path: "/session/register",
        name: "register",
        component: Register,
        meta: {
            public: true,
        },
    },
    {
        path: "/session/forgot",
        name: "forgot",
        component: ForgotPassword,
        meta: {
            public: true,
        },
    },
    {
        path: "/session/reset",
        name: "forgot",
        component: ResetPassword,
        meta: {
            public: true,
        },
    },
    {
        path: "/quotes",
        name: "quotes-base",
        redirect: { name: 'quotes-view' },
        component: baseQuotes,
        meta: {
            breadcrumb: true,
            Auth: true,
        },
        children: [{
                path: "view",
                name: "quotes-view",
                component: listQuotes,
                meta: { breadcrumb: true },
            },
            {
                path: "add",
                name: "quotes-add",
                component: addQuote,
                meta: { breadcrumb: true },
            },
            {
                path: "edit/:id",
                name: "quotes-edit",
                component: addQuote
            },
            {
                path: "edit/:id/step2",
                name: "quotes-edit-2",
                component: addQuoteStep2
            },
            {
                path: "/editquote",
                name: "edit-quote",
                component: EditQuote,
                meta: { breadcrumb: true },
            },
            {
                path: "/viewquote",
                name: "view-quote",
                component: ViewQuote,
                meta: { breadcrumb: true },
            },
            {
                path: "/registerclients",
                name: "register-clients",
                component: RegisterClients,
                meta: { breadcrumb: true },
            }
        ]
    },

    //     /* {
    //         path: "/quotes/view",
    //         name: "view",
    //         component: viewquote,
    //         meta: {
    //             breadcrumb: true
    //         }
    //     },
    //     {
    //         path: "/newquote/step1",
    //         name: "step1",
    //         component: newquote,
    //         meta: {
    //             breadcrumb: true
    //         }
    //     },
    //     {
    //         path: "/newquote/step2",
    //         name: "step2",
    //         component: newquotestep2,
    //         meta: {
    //             breadcrumb: true
    //         }
    //     },
    //     {
    //         path: "/firebase/test",
    //         name: "test",
    //         component: test,
    //         meta: {
    //             breadcrumb: true
    //         }
    //     },
    //     {
    //         path: "/session/error404",
    //         name: "error404",
    //         component: Error404,
    //         meta: {
    //             public: true
    //         }
    //     },
    //     // session routes
    //     {
    //         path: "/session/home",
    //         name: "home",
    //         component: Home,
    //         meta: {
    //             public: true,
    //             transition: 'zoom'
    //         }
    //     },
    //     {
    //         path: "/session/error505",
    //         name: "error505",
    //         component: Error505,
    //         meta: {
    //             public: true
    //         }
    //     }, {
    //         path: "/session/error403",
    //         name: "error403",
    //         component: Error403,
    //         meta: {
    //             public: true
    //         }
    //     }, {
    //         path: "/session/forgot-password",
    //         name: "forgot-password",
    //         component: ForgotPassword,
    //         meta: {
    //             public: true
    //         }
    //     }, {
    //         path: "/session/lock-screen",
    //         name: "lock-screen",
    //         component: LockScreen,
    //         meta: {
    //             public: true
    //         }
    //     // },
    //      {
    //         path: "/session/register",
    //         name: "register",
    //         component: Register,
    //         meta: {
    //             public: true
    //         }
    //     },
    //      {
    //         path: "/session/blank",
    //         name: "blank",
    //         component: Blank,
    //         meta: {
    //             public: true
    //         }
    //     }
    // */
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
/*code to remove navigation duplication error start*/
/* const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalPush.call(this, location, onResolve, onReject)
        }

        return originalPush.call(this, location).catch((err) => {
            if (VueRouter.isNavigationFailure(err)) {
                return err
            }

            return Promise.reject(err)
        })
    } */
/*code to remove navigation duplication error ends*/
router.beforeEach(function(to, _, next) {
    /* console.log('router index 278');
    console.log(store.getters.auth)
        to.matched.some(m => {
            console.log
            m.meta.auth
        }) */
    if (to.matched.some(m => m.meta.Auth) && !store.getters.auth.token) {
        store.dispatch('autoLogin').then(res => {
            if (res)
                next();
            else
                next({ name: 'login' });
        }).catch((error) => {
            console.log(error);
        });

    } else if (to.meta.Auth && store.getters.auth.token) {
        next();
    } else {
        // next({ name: 'dashboard' });
    }
    next();
});

export default router;