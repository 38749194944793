import { render, staticRenderFns } from "./EditQuote.vue?vue&type=template&id=f4703190&scoped=true&"
import script from "./EditQuote.vue?vue&type=script&lang=js&"
export * from "./EditQuote.vue?vue&type=script&lang=js&"
import style0 from "./EditQuote.vue?vue&type=style&index=0&id=f4703190&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4703190",
  null
  
)

export default component.exports