<template>
  <div class="container-fluid mt-2">
    <!-- HEADER -->
    <v-row no-gutters class="home_box1 confi">
  <div class="col-md-12" v-if="$route.name=='home-settings'">
      <div class="tabs-css" id="tiles-1">
          <!-- Nav tabs -->
          <ul class="nav">
              <li class="nav-item">
                  <a class="nav-link" :class="{active:selectedComponent=='category'}" @click.prevent="selectedComponent='category'">Category</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" :class="{active:selectedComponent=='item'}" @click="selectedComponent='item'">Item</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" :class="{active:selectedComponent=='unit'}" @click="selectedComponent='unit'">Units</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" :class="{active:selectedComponent=='status'}" @click="selectedComponent='status'">Status</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" :class="{active:selectedComponent=='quoteSetting'}" @click="selectedComponent='quoteSetting'">Quote Settings</a>
              </li>
          </ul>
      </div>
</div>

<router-view :selectedComponent="myProps"></router-view>
 </v-row>
</div>
   
    <!-- HEADER END-->
  
</template>
<script>
export default{
  data(){
    return{
      selectedComponent: 'category'
    }
  },
  computed:{
    myProps() {
    return this.selectedComponent
  }
  }
}
</script>
