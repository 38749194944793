<template>
    <div class="container-fluid my-2">
    <!-- HEADER -->
    <v-row no-gutters>
      <div class="col-md-4">
          <div class="home_box">
              <h4>Verify Email</h4>
              <img src="../../assets/img/tick-email-verify.png" />
              <h2>Your email address has been verifield.</h2>
          </div>
      </div>
    </v-row>
    <!-- HEADER END-->
    </div>
</template>
<script>
export default {
    name:'main',
    components:{
        
    },
    data(){
        return {
            emailData:{
                id:"",
                hash:"",
                expires:"",
                signature:""
            },
        }
    },
    created(){
        this.emailData = this.$route.query
        console.log(this.emailData);
        this.$store.dispatch("emailVerify", this.emailData).then((response) => {
            console.log(response);
            if(response.status == true){
            this.$fire({
                  title: response.message,
                  type: "success",
                  timer: 4000
              });
          }
        });
    }
}
</script>
<style>

    .home_box{
        width: 560px;
        height: 377px;
        z-index: 2;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        top: 32px;
        left: 48px;
        padding: 30px;
    }
    .home_box img{
        width: 100px;
        height: auto;
        margin: 25px 0px;
    }

     .home_box h4{
        font-size: 25px;
        font-weight: 600;
        color: #343434;
        position:relative;
     }
     .home_box h4:before{
        position: absolute;
        content:"";
        background: #00b0be;
        height: 3px;
        width: 14%;
        left: 0px;
        bottom: -14px;
        
     }
</style>