<template lang="">
  <div class="col-md-12">
    <div class="home_box1">
      <h3>View Supplier</h3>
       <v-card>
           <v-card-title>
            <v-text-field 
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="searchIt"
            ></v-text-field>
          </v-card-title> 
          <v-data-table
            v-model="supplierData"
            :headers="headers"
            :items="itemsWithSno"
            :search="search"
            :loading = "loading"
            loading-text="Loading... Please wait"
            :items-per-page="15"
            hide-default-footer>

            <template v-slot:item.actions="{ item }">
               <router-link 
                  v-b-tooltip.hover
                  title="Edit" 
                  :to="{ name: 'edit-supplier', params: { id: item.id } }">
                  <v-icon small>mdi-pencil</v-icon>
                </router-link>
              <button 
                v-b-tooltip.hover
                title="Delete"
                @click="deleteItem(item.id)" class="text-danger">
                <v-icon small> mdi-delete</v-icon>
              </button>
            </template>
          </v-data-table>
        </v-card>
        <v-pagination
          v-model="currentPage"
          :length="lastPage"
          total-visible="6">
        </v-pagination>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
Vue.use(VueGoodTable);
export default {
  name: "supplier",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      loading:false,
      search: '',
        headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Company",
          value: "company",
        },

        {
          text: "Phone",
          value: "phone",
          text: "Address",
          value: "address",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Local Council",
          value: "council",
        },
        {
          text: "Action",
          value: "actions",
        },
        ],
        supplierData: [],
    };
  },
  watch:{
      currentPage( newValu, oldVal){
          this.paginatePage(newValu);
      }
    },
  computed:{
    itemsWithSno() {
      return this.supplierData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
          return this.$store.getters.suppliers.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
      }
    },
    lastPage:{
      get(){
          return this.$store.getters.suppliers.last_page; // get last page
      }
    }
  },
  methods: {
    paginatePage(pageNumber){
        this.loading = true; 
        let srch = this.search
        let payload = {'pageNumber': pageNumber, 'srch': srch}

        this.$store.dispatch("initSuppliers2", payload).then((succes) => {
            this.supplierData = succes.data.data;
            if(succes){
              this.loading = false;
            }
        });
    },
    init() {
      this.$store.dispatch("initSuppliers2").then((success) => {
        this.supplierData = succes.data.data;
      });
    },searchIt(e){
        if(e.length > 0){
            this.$store.dispatch("initSuppliersSearch", e).then((succes) => {
                this.supplierData = succes.data.data;
                if(succes){
                  this.loading = false;
                }
            });
        }else{
            this.$store.dispatch("initSuppliers2").then((succes) => {
              this.supplierData = succes.data.data; 
            });
        }
    },
    deleteItem(id) {
      this.$confirm("","Are you sure?","question").then(() => {
        this.$store.dispatch("deleteSupplier", id).then((succes) => {
          console.log(succes);
          if (succes.status == 200) {
             this.$fire({
              title: "Supplier Successfully Deleted!",
              text: "",
              type: "success",
              timer: 4000
            });
            this.init();
          } else {
            this.$fire({
              title: "Something Missing!",
              text: "",
              type: "error",
              timer: 3000
            });
          }
        });
      });
    },
  },
  created() {
    this.$store.dispatch("initSuppliers2").then((succes) => {
      this.supplierData = succes.data.data; 
    });
  },
  
};
</script>
