<template lang="">
<div class="top_bar">
          <div>
            <h6>Create/Edit quote</h6>
            <slot name="h2"></slot>
            <!--h2>Step 1: Client & Additional Details</h2-->
          </div>
          <div class="buttons">
          <router-link to="/quotes/view" v-if=""  class="btn btn-light">Spreadsheet List</router-link>
        <b-button v-if="step==2" @click="$router.back()"class="btn btn-light text-dark">Back</b-button>
         <router-link to="/quotes/view" v-if="this.$route.params.id && step==1"  class="btn btn-light"
              >Cancel</router-link>
              <router-link to="/dashboard" v-if="!this.$route.params.id && step==1"  class="btn btn-light"
              >Cancel</router-link>
            <b-button class="btn btn-success" v-if="this.$route.params.id" @click="updateQuoteForm"  :disabled="v.quote.$invalid || v.quote.$error">Update</b-button>
            <b-button class="btn btn-success" v-else @click="quoteForm" :disabled="v.quote.$invalid || v.quote.$error">Save</b-button>
            <b-button class="btn btn-success" :disabled="v.quote.$invalid || v.quote.$error" v-if=false>Save as new version</b-button>
            <b-button class="btn btn-success"   v-if="!isQuoteExercisesEmpty" @click="createPdf()">Download PDF</b-button>
            <router-link 
              :is="quote.id > 0 && step==1 ? 'router-link' : ''"  
              :to="`/quotes/edit/${quote.id}/step2`"
              class="btn btn-danger text-white"
              >Proceed to Step 2</router-link>
              <router-link v-if="step==2 && !spreadsheet && false"
              :is="quote.id > 0 ? 'router-link' : ''"  
              :to="`/quotes/edit/${quote.id}/step2/spreadsheet`"
              class="text-white"
              >Spreadsheet List</router-link>
        <router-link v-if="step==2 && spreadsheet && false"
              :is="quote.id > 0 ? 'router-link' : ''"  
              :to="`/quotes/edit/${quote.id}/step2/spreadsheet`"
              class="text-white"
              >Normal List</router-link>
          </div>
          <quote-pdf v-show=false></quote-pdf>
        </div>

</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import { mapGetters, mapActions } from "vuex";
import quotePdf from "./quotePdf.vue";
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    spreadsheet: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: {},
    },
    v: {
      type: Object,
      default: {},
    },
    /* updateQuoteForm:{
     type: Function,
     default:null
    },
    quoteForm:{
      type: Function,
      default:null
    } */
  },
  components: {
    quotePdf,
  },
  computed: {
    ...mapGetters(["isQuoteExercisesEmpty", "categories"]),
  },
  methods: {
    ...mapGetters(["getCategoryName"]),
    quoteForm() {
      const formData = Object.assign({}, this.quote);
      this.$store.dispatch("addQuote", formData).then((success) => {
        if (success.status == 200) {
          this.$fire({
            title: "New Quote Successfully Created!",
            text: "",
            type: "success",
            timer: 4000
          });
          //  this.$alert("New Quote Successfully Created!");
          // this.$store.commit('SET_QUOTE',success.data);
        } else {
          this.$fire({
            title: "Something Missing!",
            text: "",
            type: "error",
            timer: 3000
          });
        }
      });
    },
    updateQuoteForm() {
      const formData = this.quote;
      this.$store.dispatch("updateQuoteNew").then((success) => {
        if (success.status == 200) {
           this.$fire({
            title: "Quote Updated Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
        } else {
           this.$fire({
            title: "Something Missing!",
            text: "",
            type: "error",
            timer: 3000
          });
        }
      });
    },
    getCategoryName(id) {
      //console.log(this.$store.getters.categories);
      for (var i = 0; i <= this.categories.length; i++) {
        if (this.categories[i].id == id)
          return this.categories[i].category_label;
      }
    },
    createPdf() {
      let quote = this.$store.getters.quote;
      var doc = new jsPDF();
      var finalY = doc.lastAutoTable.finalY || 10;
      doc.autoTable({ html: ".quoteTable" });
      finalY = doc.lastAutoTable.finalY || 20;
      if (Object.keys(quote.quote_exercises).length > 0) {
        let quoteCats = Object.keys(quote.quote_exercises);
        for (let j = 0; j < quoteCats.length; j++) {
          let i = quoteCats[j];
          /*Category Name and Category Total Cost table or block*/
          let catName = this.getCategoryName(i);
          finalY = doc.lastAutoTable.finalY;
          /*Item heads array */
          let head = ["Item", "Qty", "Price", "Labour", "Material", "Amt"];

          /* if object is empty continue*/
          if (
            typeof quote.quote_exercises[i] === "undefined" ||
            typeof quote.quote_exercises[i] === "null" ||
            Object.keys(quote.quote_exercises[i]).length == 0
          ) {
            continue;
          }
          let catObj = quote.quote_exercises[i];
          console.log(catObj);
          let body = [];
          let catCost = 0;
          /*Loop Through Category items */
          for (const itemIndex in catObj) {
            let item = quote.quote_exercises[i][itemIndex];
            if (item.enabled == 0) continue;
            body.push([
              item.name,
              item.quantity,
              "£" + item.price,
              "£" + item.labour,
              "£" + item.material,
              "£" + item.amount,
            ]);
            catCost += Number(item.amount);
          }
          doc.setFontSize(10);
          doc.text(catName, 15, finalY + 10);
          doc.text("£" + catCost, 170, finalY + 10);
          doc.autoTable({
            startY: finalY + 15,
            head: [head],
            body: body,
          });
        } //End of Catgory Array
      }

      doc.save(quote.name + ".pdf");
    },
  },
};
</script>
