<!--===========================
=            BLANK            =
============================-->

<template>
  <!-- page blank -->
  <v-container>
    <v-row no-gutters>
      <base-header>
        <div slot="page-header">Page Blank</div>
      </base-header>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <div style="height:500px"></div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseHeader from "../../pages/layout/Base/BaseHeader.vue";
export default {
  components: {
    BaseHeader
  }
};
</script>

<!--====  End of BLANK  ====-->
