<!--==============================
=            LISTLOVE            =
===============================-->
<template>
  <v-list two-line>
    <v-list-item-group  multiple active-class="pink--text">
      <template v-for="item in items2">
        <v-list-item class="mt-1" :key="item.title">
          <template >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="item.headline"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-text="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                v-text="item.action"
              ></v-list-item-action-text>
              <v-icon :color="item.active ? 'red' : 'grey'"
                >favorite_border</v-icon
              >
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  data() {
    return {
      items2: [
        {
          active: true,
          title: "Jason Oner",
          avatar: "../../../static/img/users/10.jpg"
        },
        {
          active: true,
          title: "Ranee Carlson",
          avatar: "../../../static/img/users/4.jpg"
        },
        {
          title: "Cindy Baker",
          avatar: "../../../static/img/users/3.jpg"
        },
        {
          title: "Ali Connors",
          avatar: "../../../static/img/users/2.jpg"
        },
        {
          title: "David Connors",
          avatar: "../../../static/img/users/11.jpg"
        }
      ]
    };
  }
};
</script>
<style scoped>
.avatar-img img {
  width: 30px;
  border-radius: 50%;
}
</style>

<!--====  End of LISTLOVE  ====-->
