<template lang="">
    <!-- <v-container> -->
    <!-- HEADER -->
    <!-- <v-row no-gutters> -->
      <div class="col-md-12">
          <div class="home_box1">
              <h3>View Quote</h3>
              <v-card>
                <v-card-title>
                  <v-text-field 
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @input="searchIt"
                  ></v-text-field>
                </v-card-title>
                
                <v-data-table
                  v-model="QuoteData"
                  :headers="headers"
                  :items="itemsWithSno"
                  :search="search"
                  :loading = "loading"
                  loading-text="Loading... Please wait"
                  :items-per-page="15"
                  hide-default-footer>

                  <template v-slot:item.status="{ item }">
                    <button v-if = (item.active)>  Active </button>
                    <button v-else> </button> 
                  </template>
                  <template v-slot:item.actions="{ item }"> 
                    <router-link 
                        v-b-tooltip.hover
                        title="Edit" 
                        :to="{ name: 'quotes-edit', params: { id: item.id } }">
                        <v-icon small>mdi-pencil</v-icon>
                      </router-link>
                    <button 
                      v-b-tooltip.hover
                      title="Delete"
                      @click="deleteQuote(item.id)" class="text-danger">
                      <v-icon small> mdi-delete</v-icon>
                    </button>
                  </template>
                </v-data-table>
                <v-pagination
                  v-model="currentPage"
                  :length="lastPage"
                  total-visible="10">
                </v-pagination>
              </v-card>
          </div>
        </div>
    <!-- </v-row> -->
    <!-- HEADER END-->
    <!-- </v-container> -->
</template>
<script>
export default {
  name: "quotes-view",
  components: {},
  data() {
    return {
      loading:false,
      search:"",
      headers: [
        {
          text: 'Serial #',
          value: 'sno'
        },
        {
          text: "Client Name",
          value: "name",
        },
        {
          text: "Client Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          value: "actions",
        },
        ],
        QuoteData: [],
    };
  },
  watch: {
    // "$store.state.quotes.quotes"() {  
    // },
    currentPage( newVal, oldVal){
      this.paginatePage(newVal);
    },
     deep: true
  },

  computed:{
    itemsWithSno() {
      return this.QuoteData.map((d, index) => ({ ...d, sno: index + 1 })); // for serial number in table
    },
    currentPage:{
      get(){
        return this.$store.getters.quotes.current_page; // get current page
      },
      set(value){
          this.$store.commit('setCurrentPage', value); // set page value
        }
    },
    lastPage:{
      get(){
          return this.$store.getters.quotes.last_page; // get last page
      }
    }
  },
  methods: {
    init() {
      this.$store.dispatch("initQuotes").then((response) => {
        this.QuoteData = this.$store.getters.quotes.data;
      });
    },
    paginatePage(pageNumber){
        this.loading = true;
        let srch = this.search
        let payload = {'pageNumber': pageNumber, 'srch': srch}
        this.$store.dispatch("initQuotes2", payload).then((response) => {
          console.log(response);
            this.QuoteData = response.data;
            if(response){
              this.loading = false;
            }
        });
    },searchIt(e){
        if(e.length > 0){
            this.$store.dispatch("initQuotesSearch", e).then((succes) => {
                this.supplierData = succes.data.data;
                if(succes){
                  this.loading = false;
                }
            });
        }else{
            this.$store.dispatch("initQuotes2").then((succes) => {
              this.supplierData = succes.data.data; 
            });
        }
    },
    filterFn: function(data, filterString) {
        console.log(data);
    },
    deleteQuote(id) {
      this.$confirm("","Are you sure?","question").then(() => {
        this.$store.dispatch("deleteQuote", id).then((data) => {
          if (data.status == 200) {
             this.$fire({
              title: "Quote Successfully Deleted!",
              text: "",
              type: "success",
              timer: 4000
            });
            this.init();
          } else {
            this.$fire({
              title: "Something Missing!",
              text: "",
              type: "error",
              timer: 3000
            });
          }
        });
      });
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("initQuotes").then((response) => { 
      console.log(response.data)
      this.QuoteData = response.data;
      if(response){
        this.loading = false;
      }
    });
  },
 
};
</script>
<style>
.v-content__wrap {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url(../../assets/img/home_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}
.v-content.v-content__wrap {
  background-image: none;
}
.home_box1 {
  width: 100%;
  height: auto;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  /* position: absolute; */
  top: 30px;
  left: 0px;
  padding: 30px 10px;
}
.home_box button {
  z-index: 2;
  border-left: 4px solid rgb(0, 176, 190);
  border-radius: 2px;
  padding: 20px 40px;
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgb(0 0 0 / 40%);
  box-shadow: 0 0 1px rgb(0 0 0 / 40%);
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  margin: 30px 0px;
}
.home_box button:hover {
  background-color: #d4d4d4 !important;
  border-color: rgb(0, 176, 190) !important;
}
.home_box button:active {
  background-color: #d4d4d4 !important;
  border-color: rgb(0, 176, 190) !important;
}
.home_box h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.home_box1 h3 {
  font-weight: 600;
}
.edit_delete .btn-outline-primary {
  border-radius: 4px 0px 0px 4px;
}
.edit_delete .btn-outline-danger {
  border-radius: 0px 4px 4px 0px;
}
</style>