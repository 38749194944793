<template>
  <v-container>
    <v-row >
      <div class="bg-white set_top_margin">
          <div class="col-md-12 row_fixed p-0">
              <div class="top_bar edit_q">
                <div>
                    <router-link to="/editquote">
                      <b-button class="font-weight-bold" variant="light">BACK TO QUOTE</b-button>
                    </router-link>
                </div>
                 <div>
                    <b-button class="font-weight-bold" variant="success">PRINT</b-button>
                </div>
                 <div>
                    <b-button class="font-weight-bold" @click="email_quote_document = !email_quote_document" variant="danger">EMAIL QUOTE DOCUMENT</b-button>
                </div>
                <!-- Email Quote Document MODAL -->
              <b-modal v-model="email_quote_document" hide-footer hide-header ok-only id="vb-close" class="p-0" size="md">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead text-center">
                        <h2>Email Quote</h2>
                      </div>
                    </div>
                    <div class="col-md-12 border">
                      <transition name="fade">
                          <v-row>
                              <div class="col-md-4">
                                <label for="send_to">Send to</label>
                              </div>
                               <div class="col-md-8">
                                <input type="email" class="form-control" name="send_to" id="send_to">
                                <p class="text-info">Use client email</p>
                              </div>
                               <div class="col-md-4">
                                <label for="email_sub">Email Subject</label>
                              </div>
                               <div class="col-md-8">
                                <input type="text" class="form-control" name="email_sub" id="email_sub">
                              </div>
                              <div class="col-md-4">
                                 <label for="message">Message</label>
                              </div>
                               <div class="col-md-8">
                                 <b-form-textarea
                                    id="message"
                                    placeholder="Enter Message ..."
                                    rows="5"
                                    v-model="text">
                                  </b-form-textarea>
                              </div>
                              <div class="col-md-12 text-center">
                                <b-button variant="success">OK</b-button>
                              </div>
                            </v-row>
                       </transition>
                    </div>
                     <div class="col-md-12 m-auto p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="w-25" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal><!-- Email Quote Document MODAL END-->
            </div>
        </div>
    
        <div class="col-md-9 m-auto my-5 p-4 border">
            <div>
                <b-img src="../../assets/img/qs-quote.png"></b-img>
                <h2>QUOTE PRESENTATION</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna, mollis id pellentesque ac, imperdiet non enim. Phasellus porta diam varius diam scelerisque suscipit. Phasellus non lorem et eros ultricies laoreet. Quisque ornare orci ac fringilla ullamcorper. Etiam placerat dolor lorem, ut sollicitudin risus feugiat vitae. Aenean maximus risus nisl, ac porta quam rhoncus porttitor. Pellentesque tempor commodo dolor. Quisque at aliquam turpis, vel gravida magna. Etiam ullamcorper consequat metus.</p>
            </div><!-- end div -->

            <div>
                <b-img src="../../assets/img/qs-quote.png"></b-img>
                <h2>COMPANY PROFILE</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna, mollis id pellentesque ac, imperdiet non enim. Phasellus porta diam varius diam scelerisque suscipit. Phasellus non lorem et eros ultricies laoreet. Quisque ornare orci ac fringilla ullamcorper. Etiam placerat dolor lorem, ut sollicitudin risus feugiat vitae. Aenean maximus risus nisl, ac porta quam rhoncus porttitor. Pellentesque tempor commodo dolor. Quisque at aliquam turpis, vel gravida magna. Etiam ullamcorper consequat metus.</p>
            </div><!-- end div -->

            <div>
                <h2>QUALITY WORKMANSHIP GUARANTEED</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna, mollis id pellentesque ac, imperdiet non enim. Phasellus porta diam varius diam scelerisque suscipit. Phasellus non lorem et eros ultricies laoreet. Quisque ornare orci ac fringilla ullamcorper. Etiam placerat dolor lorem, ut sollicitudin risus feugiat vitae. Aenean maximus risus nisl, ac porta quam rhoncus porttitor. Pellentesque tempor commodo dolor. Quisque at aliquam turpis, vel gravida magna. Etiam ullamcorper consequat metus.</p>
            </div><!-- end div -->

            <div>
                <h2>WHAT HAPPENS NEXT</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna, mollis id pellentesque ac, imperdiet non enim. Phasellus porta diam varius diam scelerisque suscipit. Phasellus non lorem et eros ultricies laoreet. Quisque ornare orci ac fringilla ullamcorper. Etiam placerat dolor lorem, ut sollicitudin risus feugiat vitae. Aenean maximus risus nisl, ac porta quam rhoncus porttitor. Pellentesque tempor commodo dolor. Quisque at aliquam turpis, vel gravida magna. Etiam ullamcorper consequat metus.</p>
            </div><!-- end div -->

            <div>
                <h2>CLIENTS DETAILS</h2>
                 <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Client Name</td>
                        <td>Site Address</td>
                        <td>Local Council</td>
                        <td>Job Reference Number</td>
                      </tr>
                      <tr>
                        <td>Mary</td>
                        <td>Moe</td>
                        <td>Derbyshire</td>
                        <td>1xxx01</td>
                      </tr>
                    </tbody>
                  </table>
            </div><!-- end div -->

            <div>
                <h2>COMPANY DETAILS</h2>
                 <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Company</td>
                        <td>ABN</td>
                        <td>ACN</td>
                        <td>Licence Number</td>
                        <td>Address</td>
                        <td>Phone</td>
                        <td>Email</td>
                      </tr>
                      <tr>
                        <td>telroofing</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
            </div><!-- end div -->

            <div>
                <h2>DESCRIPTION</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna,</p>
            </div><!-- end div -->

            <div>
               <div class="checklist">
                  <h2>CHECKLIST</h2>
                  <h3>PRELIMINARIES</h3>
                  <ul>
                    <li>
                      <span>Archectect/Designer Fees</span>
                      <span>$2,500.00</span>
                    </li>
                    <li>
                      <span>Quantity</span>
                      <span>1 Fee</span>
                      <span>Unit Price: $2,500.00</span>
                    </li>
                    <li>
                      Included tree diffrent type of designs and corresponding renders.
                    </li>
                    <li>
                      This item is an allowance
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <span>Site Identification,Surveying</span>
                      <span>$800.00</span>
                    </li>
                    <li>
                      <span>Quantity</span>
                      <span>1 Fee</span>
                      <span>Unit Price: $800.00</span>
                    </li>
                    <li>
                      This item is an allowance
                    </li>
                  </ul><!-- checklist -->
               
                  <h3>SITE ESTABLISHMENT</h3>
                  <ul>
                    <li>
                      <span>Surveying</span>
                      <span>$800.00</span>
                    </li>
                    <li>
                      <span>Quantity</span>
                      <span>1 Fee</span>
                      <span>Unit Price: $800.00</span>
                    </li>
                    <li>
                      This item is an allowance
                    </li>
                  </ul><!-- checklist -->
               </div>
            </div><!-- end div -->
             <div>
                <h2>PAYMENT TERMS AND CONDITIONS</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam diam magna,</p>
            </div><!-- end div -->
             <div>
                <div class="summary">
                  <h2>SUMMARY</h2>
                  <ul>
                    <li>
                      <span>Allowance: <strong>$4,000.00</strong></span>
                    </li>
                    <li>
                      <span>Total: <span>$5,060.00</span></span>
                    </li>
                    <li>
                      <span>Price Included GST</span>
                    </li>
                  </ul>
                </div>
            </div><!-- end div -->
             <div class=" border-top">
                <h2>EXCLUSIONS</h2>
                <p>Items not included in quote:</p>
            </div><!-- end div -->
            <div class=" text-right">
                <h4 class="text-info">CREATED ON: <span>27July 2021</span></h4>
            </div><!-- end div -->
        </div>
        </div>
    </v-row><!-- end row -->
  </v-container><!-- end container -->
</template>

<script>
  // import the styles
  import 'vue-good-table/dist/vue-good-table.css'
  export default {
    name: "view-quote",
      components: {
        
    },
    data() {
      return {  
          email_quote_document: false, 
      };
    },
    methods:{

    }
  };
</script>
<style scoped>
.checklist ul{
  list-style-type: none;
}
.checklist ul li{
  font-size:18px;
  font-weight:500;
}
.checklist ul li:first-child{
    font-size: 20px;
    font-weight: 600;
    display: block;
    background-color: #3333333d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}
.checklist ul li:nth-child(2){
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px
}
.checklist ul li:nth-child(2) span:nth-child(2){
    border-left: 1px solid #dee2e6;
}
.checklist ul li:nth-child(2) span{
    padding: 15px;
}
.checklist ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}
.summary ul li span{
  font-size:22px;
  font-weight:500;
}
.summary ul li span strong{
  font-weight:600;
}
.summary ul li:nth-child(2) span{
  font-size:28px;
  font-weight:600;
}
.summary ul li:nth-child(2) span strong{
  font-size:25px;
  font-weight:600;
}
.edit_q h6{
font-size:36px;
color:#fff;
}
.edit_q i{
    font-size:40px;
    color:#000;
}
h2{
  font-size:25px;
  color: #00b0be;
}
p{
  font-size:18px;
}
tr{
  display: flex;
  flex-direction: column;
  width: 50%;
  float: left;
}
td{
   font-size:18px;
    color:#000;
    height: 45px;
    font-weight: 500;
}
.checklist h3{
  background-color: #00b0be;
  font-size:24px;
  padding:20px;
  display: block;
  color:#fff
}
/* .row_fixed{
  position: fixed;
  top: 66px;
  left: 272px;
  right: 16px;
  z-index: 9;
} */
/* .set_top_margin{
  margin-top:70px;
} */
</style>