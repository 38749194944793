<template>
<div class="row">
    <div class="col-lg-3 col-md-4">
        <div class="sub_category">
            <div class="d-flex justify-content-between align-items-center">
                <h6 v-if="cat_name">{{ cat_name ? cat_name : 'Home' }}</h6>
                <a v-if="cat_name" href="#" @click="editCategory(cat_id)">Edit</a> 
                <a v-if="cat_name" href="#" @click="deleteCategory(cat_id)">Delete</a> 
            </div>
            <ul class="pl-0 subcategory"> 
                <li v-for="(item,index) in items" :key="index" @click="editItem(item.id)">
                    <h5 class="name">{{item.name}}</h5>
                    <h5 class="unit">{{item.unit_type}}</h5>
                </li>  
            </ul>
        </div>
    </div>
    <div class="col-lg-9 col-md-8">
        <category-form v-if="is_category"  :_category="category" title="Update Category"></category-form>
        <item-form v-if="is_item"  :_item="categoryItem" :title="'Update'" :edit=true v-on:updateItemList="init()"></item-form>
    </div>
</div>
</template>

<script>
import CategoryComponent from "../CategoryComponent";  
import CategoryForm from './CategoryForm.vue';
import ItemForm from "../Items/ItemForm.vue";  
export default {
    name: "categorycommon", 
    components: {
        CategoryComponent, 
        CategoryForm,
        ItemForm
    },
    data(){
        return {
            category:{},
            categoryItem:{},
            is_category:false,
            is_item:false,
            items:[],
            cat_name:null, 
            cat_id:null, 
        }
    },
    methods:{
        init(){
            this.items = this.$store.getters.menuItems;
            console.log(this.items);
        },
        editCategory(id){  
              this.is_category = false;
               this.is_item = false;
            this.$store.dispatch("editCategory", id).then((data) => { 
                this.is_category = true;
                 this.category = data;  
            });
        },
        deleteCategory(id){  
            this.$confirm("Are you sure?").then(() => {
                this.$store.dispatch("deleteCategory", id).then((data) => { 
                    console.log(data);
                    if(data.status == true){
                    this.$fire({
                            title: "Category Deleted Successfully!",
                            type: "success",
                            timer: 4000
                        });
                    }
                });
            });
        },
        editItem(id){  
            this.is_item = false;
             this.is_category = false;
            this.$store.dispatch("editItem", id).then((data) => { 
                console.log(data);
                this.is_item = true;
                this.categoryItem = data;  
            });
        },
          
    },
    created(){  
        this.items = this.$store.getters.menuItems;
        const data = this.$store.getters.category;  
        this.cat_name = data.name;  
        this.cat_id = data.id;  
  },
  watch:{
   '$store.getters.menuItems'(){  
        this.items = this.$store.getters.menuItems;
        /* if(this.items){
            this.is_category = false;
        }  */ 
   } ,
   '$store.getters.category'(){   
        const data = this.$store.getters.category;   
        this.cat_name = data.name;  
        this.cat_id = data.id;  
   },
        $route(to, from) {
        if(to.params.catslug !== from.params.catslug){
         this.is_category = false;
         this.is_item = false;
        }
    }
  }
    
};
</script>
