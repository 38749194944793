<template lang="">
  <!-- <v-container> -->
    <!-- HEADER -->
    <!-- <v-row no-gutters> -->
      <div>
      <div class="col-md-12 p-0">
        <quote-top-bar :quote="quote" :v="$v"></quote-top-bar>
        </div>
        <div class="home_box1">
          <v-row>
            <div class="col-md-12">
              <h3>New Quote</h3>
              <v-row>
                <div class="col-md-4">
                  <label class="form-control-label" :class="{ invalid: $v.quote.name.$error }">Name*</label>
                  <input
                    type="text"
                    :value="quote.name"
                    @input="SYNC_QUOTE({name:$event.target.value})"
                    @blur="$v.quote.name.$touch()"           
                    class="form-control"
                    name="name"
                    placeholder=""
                    :class="{ invalid: $v.quote.name.$error }"
                  />
                             <div
                      v-if="$v.quote.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.name.required"
                        >Name is Required</span
                      >
                      <span v-if="!$v.quote.name.minLength"
                        >Minimum 5 Characters Required</span
                      >
                    </div> 
                </div>
                <div class="col-md-4">
                  <label class="form-control-label" :class="{ invalid: $v.quote.email.$error }">Email*</label>
                  <input
                    type="email"
                    id="email"
                    :value="quote.email"
                    @input="SYNC_QUOTE({email:$event.target.value})"
                    @blur="$v.quote.email.$touch()"                 
                    class="form-control"
                    name="email"
                    placeholder=""
                    :class="{ invalid: $v.quote.email.$error }"
                  />
                  <div
                      v-if="$v.quote.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.email.required"
                        >Email is Required</span
                      >
                      <span v-if="!$v.quote.email.email"
                        >Email is Invalid</span
                      >
                    </div>
                </div>
                <div class="col-md-4">
                  <label class="form-control-label">Phone*</label>
                  <input
                    type="number"
                    :value="quote.phone"
                    @input="SYNC_QUOTE({phone:$event.target.value})"
                    @blur="$v.quote.phone.$touch()" 
                    :class="{ invalid: $v.quote.phone.$error }"       
                    class="form-control w-100"
                    name="phone"
                    placeholder=""
                  />
                  <div
                      v-if="$v.quote.phone.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.phone.required"
                        >Phone is Required</span
                      >
                      <span v-if="!$v.quote.phone.minLength"
                        >Minimum 8 Numbers Required</span
                      >
                    </div>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Local council*</label>
                  <input
                    type="text"
                    :value="quote.local_council"
                    @input="SYNC_QUOTE({local_council:$event.target.value})"
                    @blur="$v.quote.local_council.$touch()"
                    class="form-control"
                    name="local_council"
                    placeholder=""
                    :class="{ invalid: $v.quote.local_council.$error }"
                  />
                  <div
                      v-if="$v.quote.local_council.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.local_council.required"
                        >Local Council is Required</span
                      >
                      <span v-if="!$v.quote.local_council.minLength"
                        >Minimum 5 Characters Required</span>
                    </div>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Reference Number*</label>
                  <input
                    type="text"
                    :value="quote.reference_no"
                    @input="SYNC_QUOTE({reference_no:$event.target.value})"
                    @blur="$v.quote.reference_no.$touch()"
                    class="form-control"
                    name="reference_no"
                    placeholder=""
                    :class="{ invalid: $v.quote.reference_no.$error }"
                  />
                  <div
                      v-if="$v.quote.reference_no.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.reference_no.required"
                        >Reference Number is Required</span
                      >
                      <span v-if="!$v.quote.reference_no.minLength"
                        >Minimum 2 Characters Required</span>
                    </div>
                </div>
               
                <div class="col-md-6">
                  <label class="form-control-label">Description</label>
                  <b-form-textarea
                    id="textarea"
                    name="description"
                    :value="quote.description"
                     @input.native="SYNC_QUOTE({description:$event.target.value})"
                    placeholder=""
                    rows="5"
                  ></b-form-textarea>
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Address</label>
                  <b-form-textarea
                    id="textarea"
                    name="address"
                    :value="quote.address"
                    @input.native="SYNC_QUOTE({address:$event.target.value})"
                    placeholder=""
                    rows="5"
                  ></b-form-textarea>
                </div>
              </v-row>
              <h3>Quote Details</h3>
          <v-row>
            <div class="col-md-9">
              <v-row>
                <div class="col-md-4">
                  <label class="form-control-label">Quote Expiration</label>
                  <date-picker
                    :value="quote.expiration"
                    @input="(value)=>{SYNC_QUOTE({expiration:value})}"
                    name="expiration"
                    valueType="format"
                    style="width:100%;"
                  ></date-picker>
                </div>
                <div class="col-md-4">
                  <label class="form-control-label"
                    >Payment made within (days)</label
                  >
                  <input
                    type="text"
                    :value="quote.pay_within_days"
                    @input="SYNC_QUOTE({pay_within_days:$event.target.value})"
                    class="form-control"
                    name="pay_within_days"
                    placeholder=""
                  />
                </div> 
              </v-row>
            </div>
            <div class="col-md-3"></div>
          </v-row>
            </div>
            <div class="col-md-3 ">
              <v-row>
                <div class="col-md-12">
                <h3>Finance Details</h3><br>
                <h5 class="font-weight-bold">Total Amount : {{total_exercise_amount()}} </h5>
                </div>
                <!-- <div class="col-md-12">
                  <label class="form-control-label">Setting Name</label>
                  <input
                    type="name"
                    v-model="quote_settings.setting_name"
                    class="form-control w-100"
                    name="overhead"
                  />
                </div> -->
                <div class="col-md-12">
                  <label class="form-control-label">Overhead (%)</label>
                  <input
                    type="number"
                    :value="quote.overhead"
                    @input="SYNC_QUOTE({overhead:$event.target.value})"
                     @blur="$v.quote.overhead.$touch()"
                     :class="{ invalid: $v.quote.overhead.$error }"
                    class="form-control w-100"
                    name="overhead"
                  />
                  <div
                      v-if="$v.quote.overhead.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.overhead.required"
                        >Overhead % is Required</span
                      >
                      <span v-if="!$v.quote.overhead.numeric"
                        >Not a Valid Numeric Value</span
                      >
                    </div>
                </div>
                <div class="col-md-12">
                  <label class="form-control-label">Profit (%)</label>
                  <input
                    type="number"
                    :value="quote.profit"
                    @input="SYNC_QUOTE({profit:$event.target.value})"
                     @blur="$v.quote.profit.$touch()"
                    class="form-control w-100"
                    :class="{ invalid: $v.quote.profit.$error }"
                    name="profit" 
                  />
                  <div
                      v-if="$v.quote.profit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.profit.required"
                        >Profit % is Required</span
                      >
                      <span v-if="!$v.quote.profit.numeric"
                        >Not a Valid Numeric Value</span
                      >
                    </div>
                </div>
                <div class="col-md-12">
                  <label class="form-control-label">GST (%)</label>
                  <input
                    type="number"
                    :value="quote.tax"
                    @input="SYNC_QUOTE({tax:$event.target.value})"
                     @blur="$v.quote.tax.$touch()"
                    class="form-control w-100"
                    name="gst"
                    placeholder=""
                    :class="{ invalid: $v.quote.tax.$error }"
                  />
                  <div
                      v-if="$v.quote.tax.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.tax.required"
                        >Tax % is Required</span
                      >
                      <span v-if="!$v.quote.tax.numeric"
                        >Not a Valid Numeric Value</span
                      >
                    </div>
                </div>
                <div class="col-md-12">
                  <v-radio-group :value="quote.tax_type"                     @input="SYNC_QUOTE({tax_type:$event.target.value})"  @blur="$v.quote.tax_type.$touch()" :class="{ invalid: $v.quote.tax_type.$error }" row>
                    <v-radio label="VAT" color="indigo" v-bind:checked="quote.tax_type == 'vat'" value="vat"></v-radio>
                    <v-radio label="GST" color="indigo" v-bind:checked="quote.tax_type == 'gst'" value="gst" ></v-radio>
                  </v-radio-group>
                  <div
                      v-if="$v.quote.tax_type.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.quote.tax_type.required"
                        >Tax Type is Required</span
                      >
                      <span v-if="!$v.quote.tax_type.minLength"
                        >Not a Valid Tax Type</span
                      >
                    </div>
                </div>
                
              </v-row>
            </div>
          </v-row> 
        </div>
        </div>
    <!-- </v-row> -->
    <!-- HEADER END-->
  <!-- </v-container> -->
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {required,email,minLength,numeric} from 'vuelidate/lib/validators'
import quoteTopBar from '../../components/quotes/TopBar.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: "quotes-add",
  components: {
    DatePicker,
    quoteTopBar
  },
  data() {
    return {
     /* quote_settings: {},
      quote: {},*/      
      submitted:false,
      set: true,
      address: null,
      app_date: new Date().toISOString().slice(0, 10),
    };
  },
  validations:{
quote:{
  email:{
  required,
  email
  },
  name:{
    required,
    minLength:minLength(5)
  },
  phone:{
    required,
    numeric,
    minLength:minLength(8)
  },
  local_council:{
    required,
    minLength:minLength(5)
  },
  reference_no:{
    required,
    minLength:minLength(2)
  },
  profit:{
    required,
    numeric
  },
  overhead:{
    required,
    numeric
  },
  tax:{
    required,
    numeric
  },
  tax_type:{
    required,
    minLength:minLength(2)
  },


}
  },
  computed:{
...mapGetters(['quote']),
/* quote:{
get(){
return this.$store.getters.quote;
},
set(payload){
  console.log('set called');
  this.$store.dispatch('syncQuote',payload);
}
 
} */
  },
  methods: {
    ...mapMutations(['SYNC_QUOTE']),
    /** TOTAL COST  */
    total_exercise_amount() {
      return 0;
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(
        this.quote.tamount + this.quote.overhead + this.quote.profit
      );
    },
    quoteResetForm(){
return {
name:'',
email:'',
phone:'',
local_council:'',
reference_no:'',
description:'',
address:'',
expiration:'',
pay_within_days:'',
overhead:0,
profit:0,
tax:0,
tax_type:'vat',
calc_tax:0,
calc_overhead:0,
calc_profit:0,
calc_items_cost:0

      }
    },
    testFn(e){
console.log('testFn');
console.log(e);
    },
    quoteSettingForm() { 
      this.quote_settings = {
        name:this.quote.name,
        overhead:this.quote.overhead,
        profit:this.quote.profit,
        tax:this.quote.tax,
        tax_type:this.quote.tax_type
      };
      this.$store
        .dispatch("addQuoteSetting", this.quote_settings)
        .then((succes) => {
          if (succes.status == 200) {
            console.log(succes)
            alert("add setting");
          } else {
            console.log(succes);
            alert("Something missing!");
          }
        });
    },
    fetchQuote(id) { 
      this.$store.dispatch("fetchQuoteExercise", id).then((data) => {
          // console.log( `${id}quote exercise`); 
          // console.log(data)
        //this.quote = data;
        /*this.quote_settings = data.quote_settings_data;*/
      });
    },
 
  },
  created() { 
    if (this.$route.params.id) {
      this.fetchQuote(this.$route.params.id);
    }else{
      this.$store.commit('SET_QUOTE',this.quoteResetForm());
       this.$store.dispatch("initQuoteSettings",this.quoteResetForm()).then((data) => { 
         console.log(' Add.vue line 453');
         console.log(data); 
        this.quote =  Object.assign(this.quoteResetForm(),data);
        
       /*  console.log(this.quote);
          console.log(`JSON.parse(data.data)`)  
          console.log(data); */
    });
    }
  },
   watch: {
    $route(to, from) {
      if (to.params.id) {
        this.fetchQuote(to.params.id);
      }
    },
  },
};
</script>
<style>
.form-control-label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(36, 48, 56);
}
input {
  height: 40px !important;
}
/* input:focus{
        outline: none;
        border: 1px solid #ccc;
        box-shadow:none;
        -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        box-shadow: 0 0px 6px rgb(0 0 0 / 40%);
    } */
.top_bar {
  display: flex;
  justify-content: space-between;
  background: rgb(0, 176, 190);
  padding: 25px 20px;
  align-items: center;
}
.top_bar .buttons .btn {
  margin-left: 20px;
}
.top_bar h2 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}
.form-control {
  border-radius: 0% !important;
}
input.invalid {
  border: 1px solid red;
  background-color: #ffc9aa;
}
.v-input--selection-controls__ripple{
  z-index: 9;
}
</style>
