<template>
  <div>
    <form @submit.prevent="submitForm()" enctype="multipart/form-data">
      <v-row>
        <div class="col-12 item_configure">
          <h5>{{title}} {{edit?item.name:'Item'}}</h5>
        </div>
      </v-row>
      <v-row>
        <div class="col-md-6">
          <label for>Name*</label>
          <input type="text" class="form-control" name="name"                  
          v-model.trim="$v.item.name.$model"
                      @blur="$v.item.name.$touch()"  
                      :class="{invalid: $v.item.name.$error}"/>
           <div
                      v-if="$v.item.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.item.name.required"
                        >Name is Required</span
                      >
                      <span v-if="!$v.item.name.minLength"
                        >Minimum 3 Characters Required</span
                      >
                    </div>           
        </div>
        <div class="col-md-6">
          <label for>Category*</label>
          <multiselect
                      :id="'Item Category'"
                      v-model.trim="category"
                      :options="categories"  
                      placeholder="Select Category"
                      :multiple="false"
                      :taggable="true"
                      label="name"
                      track-by="name"
                      @input="categoryChange"
                      @open="$v.category.$touch()"
                      :class="{invalid: $v.category.$error}"
                    />
             <div
                      v-if="$v.category.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.category.required"
                        >Category is Required</span
                      >
                      <span v-if="!$v.category.minLength"
                        >Select Category</span
                      >
                    </div>       
        </div>
                <div class="col-md-6">
          <label for>Unit*</label>
          <multiselect
                      :id="'Item Unit'"
                      v-model.trim="unit"
                      :options="units"  
                      placeholder="Select Unit"
                      :multiple="false"
                      :taggable="true"
                      label="name"
                      track-by="name"
                      @input="unitChange"
                      @open="$v.unit.$touch()"
                      :class="{invalid: $v.unit.$error}"
                    />
             <div
                      v-if="$v.unit.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.unit.required"
                        >Unity is Required</span
                      >
                      <span v-if="!$v.unit.minLength"
                        >Select Unit</span
                      >
                    </div>       
        </div>
        <div class="col-md-6">
          <label for>Order</label>
          <input type="number" class="form-control" name="order" v-model="item.order" />
          <span class="font-italic">Used for sorting. The lowest orders displays on top</span>
        </div>
        <div class="col-md-12">
          <label for>Description</label>
          <b-form-textarea id="description" v-model="item.description" placeholder rows="3"></b-form-textarea>
        </div>
        <div class="col-md-6">
          <label for>Labour(&#163;)*</label>
          <input type="number" class="form-control" name="labour" v-model.trim="item.labour" @blur="$v.price.$touch()"  
          :class="{invalid: $v.item.labour.$error}" />
          <div
                      v-if="$v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.item.labour.required"
                        >Labour is Required</span
                      >
                      <span v-if="!$v.item.labour.numeric"
                        >Not a valid Numeric Value</span
                      >
                    </div>
        </div>
        <div class="col-md-6">
          <label>Material(&#163;)*</label>
          <input type="number" class="form-control" name="material" v-model.trim="item.material" @blur="$v.price.$touch()"  
          :class="{invalid: $v.item.material.$error}"/>
          <div
                      v-if="$v.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.item.material.required"
                        >Material is Required</span
                      >
                      <span v-if="!$v.item.material.numeric"
                        >Not a valid Numeric Value</span
                      >
                    </div>
        </div>
        <div class="col-md-6">
          <label for>Quantity*</label>
          <input type="number" class="form-control" name="quantity" v-model.trim="item.quantity" @blur="$v.item.quantity.$touch()"  
          :class="{invalid: $v.item.quantity.$error}"/>
          <div
                      v-if="$v.item.quantity.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.item.quantity.required"
                        >Quantity is Required</span
                      >
                      <span v-if="!$v.item.quantity.minValue"
                        >Quantity Cannot Be 0</span
                      >
                    </div>
        </div>
        <div class="col-md-6">
          <label for>Price(&#163;)*</label>
        <input type="number" class="form-control" name="price" v-model.trim="price"           :class="{invalid: $v.price.$error}" readonly/>
                  <div v-if="$v.price.$error" class="invalid-feedback">
                      <span v-if="!$v.price.minValue">Price Cannot be &#163;0, Fill Labour or Material Cost</span>
                    </div>
        </div>
        <div class="col-md-12">
          <label for>Comment</label>
          <b-form-textarea id="comment" v-model="item.comment" placeholder rows="3"></b-form-textarea>
        </div>
      </v-row>
      <v-row>
        <div class="col-md-12">
          <input
            type="file"
            class="form-control p-1"
            name="image"
            multipleaccept="image/jpeg"
            @change="uploadImage"
          />
        </div>
        <div class="col-md-12">
          <div class="multiple_img">
            <v-row>
              <div class="col-md-1" v-for="(image, key) in images" :key="key">
                <div class="text-center">
                  <img class="preview" :ref="'image'" />
                  <!-- {{ image.name }} -->
                  <span class="remove_img">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>
      <v-row></v-row>
      <div class="cancel_save_btn text-right">
                
        <b-btn class="btn-dark category_select" v-if=false>Cancel</b-btn>
        <b-btn class="btn-danger category_select ml-2" @click="deleteItem(item.id)" v-if="item.id" >Delete</b-btn>
<b-btn type="submit" class="btn-info item_select ml-2" :disabled="$v.item.$invalid || $v.item.$error || $v.price.$error || $v.category.$error || $v.unit.$error">{{item.id !== 0?'Update':'Save'}}</b-btn>
      </div>
    </form>
  </div>
</template>
 <script>
 import Multiselect from "vue-multiselect";
 import { mapGetters, mapMutations, mapActions } from "vuex";
 import {
  required,
  numeric,
  minValue,
  minLength,
  sameAs,
  requiredUnless
} from "vuelidate/lib/validators";
export default {
  name: "itemform",
  components:{
    Multiselect
  },
  props:{
    title:{
     type: String,
     default:'Add'
    },
    edit:{
      type:Boolean,
      default:false
    },
    _item:{
      type:Object,
      default:()=>({
        id:0,
        name: "",
        category_id: 0,
        unit_id: 0,
        description: "",
        order: "",
        labour:0,
        material:0,
        quantity: 0,
        price: 0,
        comment: "",
        
      })
    }
  },
  data() {
    return {
      images: [],
     // categories: [],
      category:{},
      unit:{},
      item:this._item,
      
    };
  },
  computed:{
      ...mapGetters([
        'categories','units'
      ]),
      price(){
        return ((Number(this.item.labour) + Number(this.item.material))* Number(this.item.quantity)).toFixed(2);
      }, 
  },
  validations: {
      item: {
        name: { required,minLength: minLength(3) },
        labour: {required,numeric},
        material: { required, numeric },
        quantity: { required, minValue:minValue(1) }
      }, 
      category:{required,minLength: minLength(1)},
      unit:{required,minLength: minLength(1)},
      price:{required,minValue:minValue(1)}
  },
 methods:{
   categoryChange(v,id){
    this.category = v;
    this.item.category_id=this.category.id;
   },
   categorySelectById(){
       if(!this.edit)
            return;
        this.categories.map((category)=>{
            if(category.id==this.item.category_id)
            this.category = category;
            console.log(this.category)
        });
   },
  unitChange(v){
    if(!v)
    return;
    this.unit = v;
    this.item.unit_id=this.unit.id;
   },
    unitSelectById(){
     if(!this.edit)
       return;
        this.units.map((unit)=>{
        if(unit.id==this.item.unit_id)
          this.unit = unit;
        });
    },
    submitForm(){ 
      if(!this.edit && this.item.id==0)
      this.addItem();
      else if(this.edit && typeof this.item.id!='undefined')
      this.updateItem();
    },
    addItem(){
    this.item.price = this.price;
      this.$store.dispatch("addItem", this.item).then((data) => {
          if(data){
          this.item = Object.assign({},this._item);
          this.$fire({
            title: "Item Added Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
          }
          
      });
    },
    updateItem(){
      this.item.price = this.price;
      this.$store.dispatch("updateItem",this.item).then((success) => { 
          if(success.status == 200){
            this.$fire({
            title: "Item Updated Successfully!",
            text: "",
            type: "success",
            timer: 4000
          });
          }
      });
    },
    deleteItem(id){
      this.$confirm("","Are you sure?","question").then(() => {
          this.$store.dispatch("deleteItem",id).then((data) => {
              if (data.status == 200) {
                this.$fire({
                  title: "Item Successfully Deleted!",
                  text: "",
                  type: "success",
                  timer: 4000
                });
                this.$emit('updateItemList');
              } else {
                this.$fire({
                  title: "Something Missing!",
                  text: "",
                  type: "error",
                  timer: 3000
                });
              }
           });
       });
    },

      uploadImage(e) {
      let vm = this;
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        console.log(selectedFiles[i]);
        this.images.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;

          console.log(this.$refs.image[i].src);
        };

        reader.readAsDataURL(this.images[i]);
      }
    },

  },
created() { 
    console.log('itemform 215');
    if(this.categories.length==0){
      console.log('itemform 217');
    this.$store.dispatch("initCategories").then((data) => {
   this.categorySelectById()      
    });
    }
    else{
      this.categorySelectById()
      
    }
    if(this.units.length==0){
        this.$store.dispatch('initUnits').then((data)=>{
        this.unitSelectById()
        })
    }
    else{
      this.unitSelectById()
    }
  },
   watch:{
    /* _item:function(n,o){
console.log('item Watch Old');
console.log(o);
console.log('item Watch New');
console.log(n);
     },
    '$store.getters.menuItems'(){  
        // this.items = this.$store.getters.menuItems;
        // if(this.items){
        //     this.is_category = false;
        // }  
   } ,
   '$store.getters.category'(){   
        // const data = this.$store.getters.category;   
        // this.cat_name = data.name;  
        // this.cat_id = data.id;  
   }  */
  }
};
</script>