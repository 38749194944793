<template>
  <v-container>
      <v-row class="no-gutters">
          <div class="col-md-12">
              <div class="top_bar edit_q">
                <div>
                    <h6><i class="fas fa-clipboard-list"></i> Quote</h6>
                </div>
            </div>
        </div>
    </v-row>
    <v-row class="home_box1 bg-white">
      <div class="col-md-2">
        <div class="left_sec">
          <div class="form-group">
            <label for="">Client</label>
            <input type="text" name="" id="" class="form-control" />
          </div>
           <div class="form-group">
            <label for="">Address</label>
            <input type="text" name="" id="" class="form-control" />
          </div>
           <div class="form-group">
            <label for="">Local Council</label>
            <input type="text" name="" id="" class="form-control" />
          </div>
           <div class="form-group">
            <label for="">Phone</label>
            <input type="number" name="" id="" class="form-control" />
          </div>
           <div class="form-group">
            <label for="">Probability</label>
            <multiselect
                v-model="probability"
                :options="probability_opt"
                :multiple="false"
                placeholder="Select Probability">
            </multiselect>
          </div>
           <div class="form-group">
            <label for="">Status</label>
            <multiselect
                v-model="status"
                :options="status_opt"
                :multiple="false"
                placeholder="Select Status">
            </multiselect>
          </div>
        <div class="form-group">
            <div class="d-flex justify-content-between">
                <b-button variant="danger">CANCEL</b-button>
                <b-button variant="success">SAVE</b-button>
            </div>
            </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="tiles" id="tiles-1">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nav-justified pl-0" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="quote_info-tab" data-toggle="tab" href="#quote_info" role="tab" aria-controls="quote_info" aria-selected="true">Quote Information</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="version_his-tab" data-toggle="tab" href="#version_his" role="tab" aria-controls="version_his" aria-selected="false">Version History</a>
                </li>
            </ul>
        </div>
         <div class="tiles" id="tiles-1">
        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane fade show active quote_info" id="quote_info" role="tabpanel" aria-labelledby="quote_info-tab">
                <v-row>
                    <div class="col-md-12">
                        <h4>QUICK ACTIONS</h4>
                    </div>
                    <div class="col-md-6">
                        <b-button variant="light">EDIT QUOTE</b-button>
                    </div>
                    <div class="col-md-6">
                        <b-button @click="modal_allowace = !modal_allowace" v-b-modal.modal-xl variant="light">SET ALLOWANCES</b-button>
                    </div>
                    <div class="col-md-6">
                        <b-button @click="modal_supplier = !modal_supplier" v-b-modal.modal-xl variant="light">EMAIL SUPPLIERS</b-button>
                    </div>
                    <div class="col-md-6">
                        <b-button @click="modal_subcontractor = !modal_subcontractor" v-b-modal.modal-xl variant="light">EMAIL SUBCONTRACTORS</b-button>
                    </div>
                    <div class="col-md-6">
                        <b-button @click="modal_upload = !modal_upload" v-b-modal.modal-xl variant="light">UPLOAD ATTACHMENTS</b-button>
                    </div>
                    <div class="col-md-6">
                      <router-link to="/registerclients">
                        <b-button variant="light">REGISTER CLIENTS</b-button>
                      </router-link>
                    </div>
                    <div class="col-md-6">
                      <router-link to="/viewquote">
                        <b-button variant="light">VIEW QUOTE</b-button>
                      </router-link>
                    </div>
                    <div class="col-md-6">
                        <b-button @click="modal_message_client = !modal_message_client" v-b-modal.modal-xl variant="light">MESSAGE CLIENTS</b-button>
                    </div>
                    <div class="col-md-6">
                        <b-button variant="light">EXPORT TO CSV</b-button>
                    </div>
                </v-row>
            </div>
            <!-- SET-ALLOWANCE MODAL -->
              <b-modal v-model="modal_allowace" hide-footer hide-header ok-only id="vb-close" class="p-0" size="xl">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead border-bottom text-center">
                        <h2>Allowances</h2>
                        <h6>Select Items From categories and set allowances</h6>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="allowance_left">
                        <b-list-group>
                          <b-list-group-item class="d-flex text-center font-weight-bold justify-content-center bg-dark  text-white align-items-center">
                            PRELIMINARIES
                          </b-list-group-item>

                          <b-list-group-item v-on:click="show_form" class="d-flex justify-content-between align-items-center">
                            Archectect/Designer Fees
                            <b-badge variant="info" pill>$2,500.00</b-badge>
                          </b-list-group-item>

                          <b-list-group-item v-on:click="show_form" class="d-flex justify-content-between align-items-center">
                            Site Identification, Surveying
                            <b-badge variant="info" pill>$850.00</b-badge>
                          </b-list-group-item>
                        </b-list-group>
                      </div>

                       <div class="allowance_left">
                        <b-list-group>
                          <b-list-group-item v-on:click="show_form" class="d-flex justify-content-center font-weight-bold bg-dark  text-white align-items-center">
                            SITE ESTABLISHMENT
                          </b-list-group-item>
                          <b-list-group-item v-on:click="show_form" class="d-flex justify-content-between align-items-center">
                            Surveying
                            <b-badge variant="info" pill>$750.00</b-badge>
                          </b-list-group-item>
                        </b-list-group>
                      </div>
                    </div>
                    <div class="col-md-6 border-left hide_show" v-bind:class="{ active: isActive }">
                      <transition name="fade">
                      <div class="allowance_right">
                        <div class="form-group">
                          <label for="brand">Brand</label>
                          <input type="text" class="form-control" name="brand" id="brand">
                        </div>
                        <div class="form-group">
                          <label for="modal_number">Modal Number</label>
                          <input type="text" class="form-control" name="modal_number" id="modal_number">
                        </div>
                        <div class="form-group">
                          <label for="description">Description</label>
                          <input type="text" class="form-control" name="description" id="description">
                        </div>
                        <div class="form-group">
                          <label for="cost">Cost</label>
                          <input type="number" class="form-control" name="cost" id="cost">
                        </div>
                        <div class="form-group">
                          <label for="cost">Allowance extra</label>
                          <input type="number" class="form-control" name="cost" id="cost">
                        </div>
                        <b-button variant="success" class="float-right">SET ALLOWANCE</b-button>
                      </div>
                       </transition>
                    </div>
                     <div class="col-md-12 m-auto border-top p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="w-25" variant="danger" @click="$bvModal.hide('vb-close')">CLOSE</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal>
              <!-- SET-ALLOWANCE MODAL END-->
              <!-- EMAIL-SUPPLIER MODAL -->
              <b-modal v-model="modal_supplier" hide-footer hide-header ok-only id="vb-close" class="p-0" size="xl">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead border-bottom text-center">
                        <h2>Email Supplier</h2>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <b-row>
                         <b-col sm="3">
                              <label for="plane">Select Supplier</label>
                          </b-col>
                          <b-col sm="9">
                           <multiselect
                            v-model="select_supplier"
                            :options="select_supplier_opt"
                            :searchable="true"
                            :multiple="true"
                            placeholder="Select Supplier">
                        </multiselect>
                          </b-col>
                         <b-col sm="3">
                              <label for="plane">Include Plane</label>
                          </b-col>
                          <b-col sm="9">
                           <b-form-checkbox
                            id="plane"
                            size="lg"
                            v-model="status"
                            name="plane"
                            value="accepted"
                            unchecked-value="not_accepted"></b-form-checkbox>
                          </b-col>

                          <b-col sm="3">
                            <label for="textarea-message">Message</label>
                          </b-col>
                          <b-col sm="9" class="text-right">
                            <b-form-textarea
                              id="textarea-message"
                              placeholder="Enter Message For Email..."
                              rows="5"
                              :maxlength="max" 
                              v-model="text">
                            </b-form-textarea>
                            <label for="" class="mt-1">Word Limit: <span v-text="(max - text.length)"></span></label>
                          </b-col>
                        </b-row>
                    </div>
                     <div class="col-md-12 m-auto border-top p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="mr-2" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                        <b-button class="w-25 ml-2" variant="success" @click="$bvModal.hide('vb-close')">OK</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal><!-- EMAIL-SUPPLIER MODAL END-->

              <!-- EMAIL-SUBCONTRACTOR MODAL -->
              <b-modal v-model="modal_subcontractor" hide-footer hide-header ok-only id="vb-close" class="p-0" size="xl">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead border-bottom text-center">
                        <h2>Email Subcontractor</h2>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <b-row>
                        <b-col sm="3">
                              <label for="plane">Select Subcontractor</label>
                          </b-col>
                          <b-col sm="9">
                           <multiselect
                            v-model="select_subcontrac"
                            :options="select_subcontrac_opt"
                            :searchable="true"
                            :multiple="true"
                            placeholder="Select Subcontractor">
                        </multiselect>
                          </b-col>
                         <b-col sm="3">
                              <label for="plane">Include Plane</label>
                          </b-col>
                          <b-col sm="9">
                           <b-form-checkbox
                            id="plane"
                            size="lg"
                            v-model="status"
                            name="plane"
                            value="accepted"
                            unchecked-value="not_accepted"></b-form-checkbox>
                          </b-col>
                          <b-col sm="3">
                            <label for="textarea-message">Message</label>
                          </b-col>
                          <b-col sm="9" class="text-right">
                            <b-form-textarea
                              id="textarea-message"
                              placeholder="Enter Message For Email..."
                              rows="5"
                              :maxlength="max" 
                              v-model="text">
                            </b-form-textarea>
                            <label for="" class="mt-1">Word Limit: <span v-text="(max - text.length)"></span></label>
                          </b-col>
                        </b-row>
                    </div>
                     <div class="col-md-12 m-auto border-top p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="mr-2" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                        <b-button class="w-25 ml-2" variant="success" @click="$bvModal.hide('vb-close')">OK</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal><!-- EMAIL-SUBCONTRACTOR MODAL END-->

              <!-- Upload Atachment MODAL -->
              <b-modal v-model="modal_upload" hide-footer hide-header ok-only id="vb-close" class="p-0" size="xl">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead border-bottom text-center">
                        <h2>Upload Attachment</h2>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <b-row>
                          <b-col sm="6">
                              <v-file-input
                                v-model="file1"
                                accept="image/*"
                                label="File input"
                                id="uploaded_plane">
                              </v-file-input>
                          </b-col>
                          <b-col sm="2">
                              <label for="uploaded_plane">Uploaded Plane</label>
                          </b-col>
                      </b-row>
                    </div>
                     <div class="col-md-12 m-auto border-top p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="mr-2" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                        <b-button class="w-25 ml-2" variant="success" @click="$bvModal.hide('vb-close')">OK</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal><!-- Upload Atachment MODAL END-->

              <!-- Message client MODAL -->
              <b-modal v-model="modal_message_client" hide-footer hide-header ok-only id="vb-close" class="p-0" size="lg">
                <v-container>
                  <v-row>
                    <div class="col-md-12 p-0">
                      <div class="madal_dead border-bottom text-center">
                        <h2>Message Clients</h2>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <b-row>
                         <b-col sm="4">
                              <label for="uploaded_plane">Select Client</label>
                        </b-col>
                        <b-col sm="8">
                         <multiselect
                            v-model="select_client"
                            :options="select_client_opt"
                            :multiple="false"
                            placeholder="Select Client">
                        </multiselect>
                        </b-col>
                        <b-col sm="4">
                              <label for="uploaded_plane">Message Clients</label>
                        </b-col>
                        <b-col sm="8">
                          <b-form-textarea
                            id="textarea-message"
                            placeholder="Enter Message For Email..."
                            rows="5"
                            :maxlength="max" 
                            v-model="text">
                          </b-form-textarea>
                        </b-col>
                      </b-row>
                    </div>
                     <div class="col-md-12 m-auto border-top p-0">
                      <div class="madal_dead mt-3 text-center">
                        <b-button class="mr-2" variant="danger" @click="$bvModal.hide('vb-close')">CANCEL</b-button>
                        <b-button class="w-25 ml-2" variant="success" @click="$bvModal.hide('vb-close')">OK</b-button>
                      </div>
                    </div>
                  </v-row>
                </v-container>
              </b-modal><!-- Message client MODAL END-->
            <div class="tab-pane fade" id="version_his" role="tabpanel" aria-labelledby="version_his-tab">
                <v-row>
                   <div class="col-md-12">
                        <vue-good-table
                        :columns="columns"
                        :search-options="{enabled: true }"
                        :rows="rows" styleClass="vgt-table striped">
                        </vue-good-table>
                    </div>
                </v-row>
            </div>
        </div>
      </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2">
          <div class="quote_right_sec">
              <ul>
                  <li>
                    <div class="show_data">
                        <span>QUOTE SUM</span>
                        <span>$5,060.00</span>
                    </div>
                  </li>
                  <li>
                    <div class="show_data">
                        <span>CURRENT VERSION</span>
                        <span>2</span>
                    </div>
                  </li>
                  <li>
                    <div class="show_data">
                        <span>ALLOWANCE</span>
                        <span>3</span>
                    </div>
                  </li>
              </ul>
          </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Multiselect from "vue-multiselect";
export default {
  name: "edit-quote",
    components: {
    Multiselect,
    VueGoodTable
  },
  data() {
    return {  
        max:2000,
        text: '',
        isActive:true,
        modal_allowace: false,
        modal_supplier: false,
        modal_upload: false,
        modal_message_client: false,
        file1: null,
        modal_subcontractor: false,

        select_supplier: false,
        select_supplier_opt: ['Select Supplier', 'Supplier 1', 'Supplier 2'],

        select_subcontrac: false,
        select_subcontrac_opt: ['Select Subcontractor', 'Subcontractor 1', 'Subcontractor 2'],

        select_client:[], 
        select_client_opt: ['Select Client', 'Client 1', 'Client 2'],

        probability:[],
        probability_opt: ['Select option', 'options 1', 'option 2'],

        status:'', 
        status_opt: ['Select option', 'options 1', 'option 2'],
        columns: [
        {
          label: 'Vesion',
          field: 'Vesion',
           type: 'Vesion', 
        },
        {
          label: 'Created',
          field: 'Created',
          type: 'Created',
        },
        {
          label: 'Comment',
          field: 'Comment',
          type: 'Comment',
        },
      ],
      rows: [
        { id:1, Vesion:"2(Current)", Created: 2011-10-31, Comment: 'First Save default Comment' },
        { id:2, Vesion:"1", Created: 2011-10-31, Comment: 'First Save default Comment' },
      ], 
    };
  },
  methods:{
     show_form(){
      this.isActive = !this.isActive;
    }
  }
};
</script>
<style scoped>
.quote_info button{
    width: 100%;
    display: block;
    font-weight: 600;
    padding: 12px;
    font-size: 16px;
    border-left: 8px solid rgb(103, 33, 120);
}
.quote_right_sec ul{
    list-style-type: none;
}
.quote_right_sec ul li{
    margin:30px 0px;
    text-align: center;
}
.quote_right_sec ul li .show_data{
    background-color: rgb(103, 33, 120);
    padding: 25px;
    border-radius: 15px;
}
.quote_right_sec ul li .show_data span{
    color:#fff;
    font-size:18px;
    display: block;
}
.quote_right_sec ul li .show_data span:last-child{
    font-size:26px;
}
.edit_q h6{
font-size:36px;
color:#fff;
}
.edit_q i{
    font-size:40px;
    color:#000;
}
.madal_dead h2{
  font-size:32px;
  color:#333
}
.madal_dead h6{
  font-size:20PX;
  color:#333
}
.allowance_left{
  margin-top:20px;
}
.allowance_left .list-group .list-group-item:first-child{
  font-size:20px;
}
.allowance_left .list-group .list-group-item{
  font-size:20px;
}
.allowance_left .list-group .list-group-item:not(:first-child){
  padding: 20px 16px;
  font-weight: 500;
}
.allowance_left .list-group .list-group-item:not(:first-child):hover{
  cursor: pointer;
}
.allowance_right label{
  font-size:18px;
  font-weight:500;
  color:#000
}
.hide_show.active{
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
label {
    font-size: 18px;
    font-weight: 500;
}
::placeholder{
  font-size: 16px;
  font-weight: 500;
}
</style>